import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ThemeProvider } from './components/ThemeContext';

import Navbar from './components/navbar';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Events from './pages/Events';
import Footer from './components/Footer';
import Login from './pages/Login';
import Signup from './pages/Signup';
import DonateButton from './pages/DonateButton';
import SundaySchool from './pages/SundaySchool';
import NewPost from './components/NewPost'
import Forms from './pages/Forms';
import NewMember from './pages/NewMember';
import MembersTable from './pages/MembersTable';

import Tselot from './components/Tselot';
import Mezmur from './components/Mezmur';
import Kids from './components/Kids';
import Yawakiwoch from './components/Yawakiwoch';
import Bahirehasab from './components/Bahirehasab';
import Mereja from './components/Mereja';
import BaptismalCertificationForm from './components/BaptismalCertificatioForm';
import WakeServiceRequestForm from './components/WakeServiceRequestForm';
import WeddingCeremonyRequestForm from './components/WeddingCeremonyRequestForm';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import Breaking from './components/Breaking';
import Education from './pages/Education';
import AllEducation from './pages/AllEducation';
import MainMessageModal from './components/MainMessageModal';

const httpLink = createHttpLink({
  uri: '/graphql',
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('id_token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider>
        <Router>
          <div className='flex flex-col min-h-screen'>
            <Navbar />
            <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/services' element={<Services />} />
            <Route path='/events' element={<Events />} />
            <Route path='/login' element={<Login />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/DonateButton' element={<DonateButton />} />
            <Route path='/SundaySchool' element={<SundaySchool />} />
            <Route path='/Tselot' element={<Tselot />} />
            <Route path='/Mezmur' element={<Mezmur />} />
            <Route path='/Kids' element={<Kids />} />
            <Route path='/Yawakiwoch' element={<Yawakiwoch />} />
            <Route path='/Bahirehasab' element={<Bahirehasab />} />
            <Route path='/Mereja' element={<Mereja />} />
            <Route path='/NewPost' element={<NewPost />} />
            <Route path='/Forms' element={<Forms />} />
            <Route path='/NewMember' element={<NewMember />} />
            <Route path='/MembersTable' element={<MembersTable />} />
            <Route path='/BaptismalCertificationForm' element={<BaptismalCertificationForm />} />
            <Route path='/WakeServiceRequestForm' element={<WakeServiceRequestForm />} />
            <Route path='/WeddingCeremonyRequestForm' element={<WeddingCeremonyRequestForm />} />
            <Route path='/PrivacyPolicy' element={<PrivacyPolicy />} />
            <Route path='/TermsAndConditions' element={<TermsAndConditions />} />
            <Route path='/Breaking' element={<Breaking />} />
            <Route path='/Education' element={<Education />} />
            <Route path='/AllEducation' element={<AllEducation />} />
            <Route path='/MainMessageModal' element={<MainMessageModal />} />
            </Routes>
            <Footer />
          </div>
        </Router>      
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;