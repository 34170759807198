import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useTheme } from '../components/ThemeContext';

import '../App.css';
import sikilet from '../images/sikilet.png';
import BreakingComp from '../components/BreakingComp';

function Events() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { theme } = useTheme();

  const ethiopianEvents = [
    
    { title: 'Ethiopian New Year / አዲስ አመት', date: new Date(2024, 8, 11), description: 'Ethiopian New Year, also known as Enkutatash / የኢትዮጵያ አዲስ አመት' },
    { title: 'Meskel / የመስቀል በአል', date: new Date(2024, 8, 27), description: 'Meskel, commemorating the discovery of the True Cross by Queen Helena / የመስቀል በዓል' },
    { title: 'Enkutatash / የገና በአል', date: new Date(2024, 12, 7), description: 'Ethiopian Christmas' },
    { title: 'Ethiopian Epiphany / ጥምቀት', date: new Date(2024, 12, 19), description: 'Timket, celebrating the baptism of Jesus Christ in the River Jordan' },
    { title: 'Fast of the Prophets / ፆመ ነብያት', date: new Date(2024, 10, 24), description: 'Fast of the Prophets, preparing for the Nativity of Jesus' },
    { title: 'Fast of Nineveh / ፆመ ነነዌ', date: new Date(2024, 1, 26), description: 'Fast of Nineveh, repentance and fasting / የነነዌ ፆም መጀመሪያ ቀን፣ ፆም ነነዌ ከሰባቱ የአዋጅ አፅዋማት አንዷ ስትሆን፣ ለ3 ተከታታይ ቀናት ትፆማለች' },
    { title: 'Adwa / አድዋ', date: new Date(2024, 2, 2), description: 'Adwa The national Victory Day / አድዋ፣ ብሄራዊ በዓል' },
    { title: 'The Great Lent Fast / አብይ ፆም', date: new Date(2024, 2, 11), description: 'Start date of Hudadi fasting or Abiye Tsome (Lent), 56 days of fasting starts. / የሁዳዴ ፆም (ዓብይ ፆም) መጀመሪያ ቀን፣ የሁዳዴ ፆም (የፋሲካ ፆም) መጀመሪያ ቀን' },
    { title: 'Mount of Olives / ደብረ ዘይት', date: new Date(2024, 3, 7), description: 'Debra zeit, It is held that the Second Advent will take place on the Mount of Olives. / ደብረ ዘይት (እኩለ ፆም)፣ የዓቢይ ፆም እኩሌታ ላይ ያለው ሰንበት' },
    { title: 'Palm Sunday / ሆሳእና', date: new Date(2024, 3, 20), description: 'Palm Sunday or Hosanna, Starting from this date, the week is celebrated with proper ceremonies with palm, processions and special services./ ሆሳህና፣ ሆሳህና ' },
    { title: 'Good Friday / ስቅለት', date: new Date(2024, 4, 3), description: 'Good Friday, crucifixion of Jesus Christ / ስቅለት፣ አማኞች በስግደት እና በፀሎት ሀጢያታቸውን ለማንፃት የሚተጉበት ቀን።' },
    { title: 'Easter Sunday / ፋሲካ', date: new Date(2024, 4, 5), description: 'Easter Sunday, resurrection of Jesus Christ / ትንሳዔ፣ ፋሲካ፣ በኦርቶዶክስ ክርስቲያኖች ዘንድ የደስታ እና የፌሽታ ተምሳሌት የሆነ ዕለት' },
    { title: 'The Holy Virgin Mary Birth day / ልደታ', date: new Date(2024, 4, 9), description: 'The BirthDay of the Blessed Virgin Mary' },
    { title: 'Assembly of Priests / ርክበ ካህናት', date: new Date(2024, 4, 29), description: 'Assembly of Priests, gathering of clergy / ረከበ ካህናት፣ ከትንሳዔ በኋላ በ25ኛው ዕለት ወይም የበዓለ ሃምሳ እኩሌታ ላይ የሚውል በዓል' },
    { title: 'Feast of the Ascension / እርገት', date: new Date(2024, 5, 13), description: 'Feast of the Ascension, Jesus\' ascension into heaven / እርገት፣ በኦርቶዶክስ አማኞች ዘንድ፣ እየሱስ ክርስቶስ ሞቶ ከተነሳ በኋላ ሰውነቱ ያረገበት በግንቦት ወር ሐሙስ ቀን የሚከበር በዓል' },
    { title: 'Pentecost / በአለ ሃምሳ', date: new Date(2024, 5, 23), description: 'Pentecost, descent of the Holy Spirit upon the Apostles /  በዓለ ጴራቅሊጦስ' },
    { title: 'The Apostles Fast / ፆመ ሃዋርያት', date: new Date(2024, 5, 24), description: 'Apostles Fast, preparation for the feast of Saints Peter and Paul' },
    { title: 'Tsome Dihnet / ፆመ ድህነት', date: new Date(2024, 5, 26), description: 'Tsome Dihnet, For Orthodox christians, Wednesday and Friday fasting resumed on this day after the easter./ ፆመ ድህነት፣ ከበዓለ ሀምሳ በኋላ ረቡዕ እና አርብ ፆም የሚጀምርበት ዕለት.' },
    { title: 'The Fast of the Holy Virgin Mary / ፆመ ፍልሰታ', date: new Date(2024, 7, 7), description: 'Fast of the Holy Virgin Mary, preparing for the Assumption / የፍልሰታ ፆም መጀመሪያ፣ የኦርቶዶክስ ክርስትና አማኞች የእመቤታችን ፆም (ፍልሰታ ፆም) መጀመሪያ ቀን' },
    { title: 'The Ascension of St. Mary / የእመቤታችን እርገት', date: new Date(2024, 7, 22), description: 'Feast of the Assumption of the Blessed Virgin Mary' },

    { title: 'Ethiopian New Year / አዲስ አመት', date: new Date(2025, 8, 11), description: 'Ethiopian New Year, also known as Enkutatash / የኢትዮጵያ አዲስ አመት' },
    { title: 'Meskel / የመስቀል በአል', date: new Date(2025, 8, 27), description: 'Meskel, commemorating the discovery of the True Cross by Queen Helena / የመስቀል በዓል' },
    { title: 'Enkutatash / የገና በአል', date: new Date(2025, 12, 7), description: 'Ethiopian Christmas / ገና (የጌታችንና የመድሃኒታችን ኢየሱስ ክርስቶስ የልደት በአል)' },
    { title: 'Ethiopian Epiphany / ጥምቀት', date: new Date(2025, 12, 19), description: 'Timket, celebrating the baptism of Jesus Christ in the River Jordan / ጌታችንና የመድሃኒታችን ኢየሱስ ክርስቶስ በዮርዳኖስ የተጠመቀበት እለት'  },
    { title: 'Fast of the Prophets / ፆመ ነብያት', date: new Date(2025, 10, 24), description: 'Fast of the Prophets, preparing for the Nativity of Jesus' },
    { title: 'Fast of Nineveh / ፆመ ነነዌ', date: new Date(2025, 1, 10), description: 'Fast of Nineveh, repentance and fasting / የነነዌ ፆም መጀመሪያ ቀን፣ ፆም ነነዌ ከሰባቱ የአዋጅ አፅዋማት አንዷ ስትሆን፣ ለ3 ተከታታይ ቀናት ትፆማለች' },
    { title: 'Adwa / አድዋ', date: new Date(2025, 2, 2), description: 'Adwa The national Victory Day / አድዋ፣ ብሄራዊ በዓል' },
    { title: 'The Great Lent Fast / አብይ ፆም', date: new Date(2025, 1, 24), description: 'Start date of Hudadi fasting or Abiye Tsome (Lent), 56 days of fasting starts. / የሁዳዴ ፆም (ዓብይ ፆም) መጀመሪያ ቀን፣ የሁዳዴ ፆም (የፋሲካ ፆም) መጀመሪያ ቀን' },
    { title: 'Mount of Olives / ደብረ ዘይት', date: new Date(2025, 2, 23), description: 'Debra zeit, It is held that the Second Advent will take place on the Mount of Olives. / ደብረ ዘይት (እኩለ ፆም)፣ የዓቢይ ፆም እኩሌታ ላይ ያለው ሰንበት' },
    { title: 'Palm Sunday / ሆሳእና', date: new Date(2025, 3, 13), description: 'Palm Sunday or Hosanna, Starting from this date, the week is celebrated with proper ceremonies with palm, processions and special services./ ሆሳህና፣ ሆሳህና ' },
    { title: 'Good Friday / ስቅለት', date: new Date(2025, 3, 18), description: 'Good Friday, crucifixion of Jesus Christ / ስቅለት፣ አማኞች በስግደት እና በፀሎት ሀጢያታቸውን ለማንፃት የሚተጉበት ቀን።' },
    { title: 'Easter Sunday / ፋሲካ', date: new Date(2025, 3, 20), description: 'Easter Sunday, resurrection of Jesus Christ / ትንሳዔ፣ ፋሲካ፣ በኦርቶዶክስ ክርስቲያኖች ዘንድ የደስታ እና የፌሽታ ተምሳሌት የሆነ ዕለት' },
    { title: 'The Holy Virgin Mary Birth day / ልደታ', date: new Date(2025, 4, 9), description: 'The BirthDay of the Blessed Virgin Mary' },
    { title: 'Assembly of Priests / ርክበ ካህናት', date: new Date(2025, 4, 14), description: 'Assembly of Priests, gathering of clergy / ረከበ ካህናት፣ ከትንሳዔ በኋላ በ25ኛው ዕለት ወይም የበዓለ ሃምሳ እኩሌታ ላይ የሚውል በዓል' },
    { title: 'Feast of the Ascension / እርገት', date: new Date(2025, 4, 29), description: 'Feast of the Ascension, Jesus\' ascension into heaven / እርገት፣ በኦርቶዶክስ አማኞች ዘንድ፣ እየሱስ ክርስቶስ ሞቶ ከተነሳ በኋላ ሰውነቱ ያረገበት በግንቦት ወር ሐሙስ ቀን የሚከበር በዓል' },
    { title: 'Pentecost / በአለ ሃምሳ', date: new Date(2025, 5, 8), description: 'Pentecost, descent of the Holy Spirit upon the Apostles /  በዓለ ጴራቅሊጦስ' },
    { title: 'The Apostles Fast / ፆመ ሃዋርያት', date: new Date(2025, 5, 9), description: 'Apostles Fast, preparation for the feast of Saints Peter and Paul' },
    { title: 'Tsome Dihnet / ፆመ ድህነት', date: new Date(2025, 5, 11), description: 'Tsome Dihnet, For Orthodox christians, Wednesday and Friday fasting resumed on this day after the easter./ ፆመ ድህነት፣ ከበዓለ ሀምሳ በኋላ ረቡዕ እና አርብ ፆም የሚጀምርበት ዕለት.' },
    { title: 'The Fast of the Holy Virgin Mary / ፆመ ፍልሰታ', date: new Date(2025, 7, 7), description: 'Fast of the Holy Virgin Mary, preparing for the Assumption / የፍልሰታ ፆም መጀመሪያ፣ የኦርቶዶክስ ክርስትና አማኞች የእመቤታችን ፆም (ፍልሰታ ፆም) መጀመሪያ ቀን' },
    { title: 'The Ascension of St. Mary / የእመቤታችን እርገት', date: new Date(2025, 7, 22), description: 'Feast of the Assumption of the Blessed Virgin Mary' },

    { title: 'Ethiopian New Year / አዲስ አመት', date: new Date(2026, 8, 11), description: 'Ethiopian New Year, also known as Enkutatash / የኢትዮጵያ አዲስ አመት' },
    { title: 'Meskel / የመስቀል በአል', date: new Date(2026, 8, 27), description: 'Meskel, commemorating the discovery of the True Cross by Queen Helena / የመስቀል በዓል' },
    { title: 'Enkutatash / የገና በአል', date: new Date(2026, 12, 7), description: 'Ethiopian Christmas / ገና (የጌታችንና የመድሃኒታችን ኢየሱስ ክርስቶስ የልደት በአል)' },
    { title: 'Ethiopian Epiphany / ጥምቀት', date: new Date(2026, 12, 19), description: 'Timket, celebrating the baptism of Jesus Christ in the River Jordan / ጌታችንና የመድሃኒታችን ኢየሱስ ክርስቶስ በዮርዳኖስ የተጠመቀበት እለት'  },
    { title: 'Fast of the Prophets / ፆመ ነብያት', date: new Date(2026, 10, 24), description: 'Fast of the Prophets, preparing for the Nativity of Jesus' },
    { title: 'Fast of Nineveh / ፆመ ነነዌ', date: new Date(2026, 1, 2), description: 'Fast of Nineveh, repentance and fasting / የነነዌ ፆም መጀመሪያ ቀን፣ ፆም ነነዌ ከሰባቱ የአዋጅ አፅዋማት አንዷ ስትሆን፣ ለ3 ተከታታይ ቀናት ትፆማለች' },
    { title: 'Adwa / አድዋ', date: new Date(2026, 2, 2), description: 'Adwa The national Victory Day / አድዋ፣ ብሄራዊ በዓል' },
    { title: 'The Great Lent Fast / አብይ ፆም', date: new Date(2026, 1, 16), description: 'Start date of Hudadi fasting or Abiye Tsome (Lent), 56 days of fasting starts. / የሁዳዴ ፆም (ዓብይ ፆም) መጀመሪያ ቀን፣ የሁዳዴ ፆም (የፋሲካ ፆም) መጀመሪያ ቀን' },
    { title: 'Mount of Olives / ደብረ ዘይት', date: new Date(2026, 2, 15), description: 'Debra zeit, It is held that the Second Advent will take place on the Mount of Olives. / ደብረ ዘይት (እኩለ ፆም)፣ የዓቢይ ፆም እኩሌታ ላይ ያለው ሰንበት' },
    { title: 'Palm Sunday / ሆሳእና', date: new Date(2026, 3, 5), description: 'Palm Sunday or Hosanna, Starting from this date, the week is celebrated with proper ceremonies with palm, processions and special services./ ሆሳህና፣ ሆሳህና ' },
    { title: 'Good Friday / ስቅለት', date: new Date(2026, 3, 10), description: 'Good Friday, crucifixion of Jesus Christ / ስቅለት፣ አማኞች በስግደት እና በፀሎት ሀጢያታቸውን ለማንፃት የሚተጉበት ቀን።' },
    { title: 'Easter Sunday / ፋሲካ', date: new Date(2026, 3, 12), description: 'Easter Sunday, resurrection of Jesus Christ / ትንሳዔ፣ ፋሲካ፣ በኦርቶዶክስ ክርስቲያኖች ዘንድ የደስታ እና የፌሽታ ተምሳሌት የሆነ ዕለት' },
    { title: 'The Holy Virgin Mary Birth day / ልደታ', date: new Date(2026, 4, 9), description: 'The BirthDay of the Blessed Virgin Mary' },
    { title: 'Assembly of Priests / ርክበ ካህናት', date: new Date(2026, 4, 6), description: 'Assembly of Priests, gathering of clergy / ረከበ ካህናት፣ ከትንሳዔ በኋላ በ25ኛው ዕለት ወይም የበዓለ ሃምሳ እኩሌታ ላይ የሚውል በዓል' },
    { title: 'Feast of the Ascension / እርገት', date: new Date(2026, 4, 21), description: 'Feast of the Ascension, Jesus\' ascension into heaven / እርገት፣ በኦርቶዶክስ አማኞች ዘንድ፣ እየሱስ ክርስቶስ ሞቶ ከተነሳ በኋላ ሰውነቱ ያረገበት በግንቦት ወር ሐሙስ ቀን የሚከበር በዓል' },
    { title: 'Pentecost / በአለ ሃምሳ', date: new Date(2026, 4, 31), description: 'Pentecost, descent of the Holy Spirit upon the Apostles /  በዓለ ጴራቅሊጦስ' },
    { title: 'The Apostles Fast / ፆመ ሃዋርያት', date: new Date(2026, 5, 1), description: 'Apostles Fast, preparation for the feast of Saints Peter and Paul' },
    { title: 'Tsome Dihnet / ፆመ ድህነት', date: new Date(2026, 5, 3), description: 'Tsome Dihnet, For Orthodox christians, Wednesday and Friday fasting resumed on this day after the easter./ ፆመ ድህነት፣ ከበዓለ ሀምሳ በኋላ ረቡዕ እና አርብ ፆም የሚጀምርበት ዕለት.' },
    { title: 'The Fast of the Holy Virgin Mary / ፆመ ፍልሰታ', date: new Date(2026, 7, 7), description: 'Fast of the Holy Virgin Mary, preparing for the Assumption / የፍልሰታ ፆም መጀመሪያ፣ የኦርቶዶክስ ክርስትና አማኞች የእመቤታችን ፆም (ፍልሰታ ፆም) መጀመሪያ ቀን' },
    { title: 'The Ascension of St. Mary / የእመቤታችን እርገት', date: new Date(2026, 7, 22), description: 'Feast of the Assumption of the Blessed Virgin Mary' },

    { title: 'Ethiopian New Year / አዲስ አመት', date: new Date(2027, 8, 11), description: 'Ethiopian New Year, also known as Enkutatash / የኢትዮጵያ አዲስ አመት' },
    { title: 'Meskel / የመስቀል በአል', date: new Date(2027, 8, 27), description: 'Meskel, commemorating the discovery of the True Cross by Queen Helena / የመስቀል በዓል' },
    { title: 'Enkutatash / የገና በአል', date: new Date(2027, 12, 7), description: 'Ethiopian Christmas / ገና (የጌታችንና የመድሃኒታችን ኢየሱስ ክርስቶስ የልደት በአል)' },
    { title: 'Ethiopian Epiphany / ጥምቀት', date: new Date(2027, 12, 19), description: 'Timket, celebrating the baptism of Jesus Christ in the River Jordan / ጌታችንና የመድሃኒታችን ኢየሱስ ክርስቶስ በዮርዳኖስ የተጠመቀበት እለት'  },
    { title: 'Fast of the Prophets / ፆመ ነብያት', date: new Date(2027, 10, 24), description: 'Fast of the Prophets, preparing for the Nativity of Jesus' },
    { title: 'Fast of Nineveh / ፆመ ነነዌ', date: new Date(2027, 1, 22), description: 'Fast of Nineveh, repentance and fasting / የነነዌ ፆም መጀመሪያ ቀን፣ ፆም ነነዌ ከሰባቱ የአዋጅ አፅዋማት አንዷ ስትሆን፣ ለ3 ተከታታይ ቀናት ትፆማለች' },
    { title: 'Adwa / አድዋ', date: new Date(2027, 2, 2), description: 'Adwa The national Victory Day / አድዋ፣ ብሄራዊ በዓል' },
    { title: 'The Great Lent Fast / አብይ ፆም', date: new Date(2027, 2, 8), description: 'Start date of Hudadi fasting or Abiye Tsome (Lent), 56 days of fasting starts. / የሁዳዴ ፆም (ዓብይ ፆም) መጀመሪያ ቀን፣ የሁዳዴ ፆም (የፋሲካ ፆም) መጀመሪያ ቀን' },
    { title: 'Mount of Olives / ደብረ ዘይት', date: new Date(2027, 3, 4), description: 'Debra zeit, It is held that the Second Advent will take place on the Mount of Olives. / ደብረ ዘይት (እኩለ ፆም)፣ የዓቢይ ፆም እኩሌታ ላይ ያለው ሰንበት' },
    { title: 'Palm Sunday / ሆሳእና', date: new Date(2027, 3, 25), description: 'Palm Sunday or Hosanna, Starting from this date, the week is celebrated with proper ceremonies with palm, processions and special services./ ሆሳህና፣ ሆሳህና ' },
    { title: 'Good Friday / ስቅለት', date: new Date(2027, 3, 30), description: 'Good Friday, crucifixion of Jesus Christ / ስቅለት፣ አማኞች በስግደት እና በፀሎት ሀጢያታቸውን ለማንፃት የሚተጉበት ቀን።' },
    { title: 'Easter Sunday / ፋሲካ', date: new Date(2027, 4, 2), description: 'Easter Sunday, resurrection of Jesus Christ / ትንሳዔ፣ ፋሲካ፣ በኦርቶዶክስ ክርስቲያኖች ዘንድ የደስታ እና የፌሽታ ተምሳሌት የሆነ ዕለት' },
    { title: 'The Holy Virgin Mary Birth day / ልደታ', date: new Date(2027, 4, 9), description: 'The BirthDay of the Blessed Virgin Mary' },
    { title: 'Assembly of Priests / ርክበ ካህናት', date: new Date(2027, 4, 26), description: 'Assembly of Priests, gathering of clergy / ረከበ ካህናት፣ ከትንሳዔ በኋላ በ25ኛው ዕለት ወይም የበዓለ ሃምሳ እኩሌታ ላይ የሚውል በዓል' },
    { title: 'Feast of the Ascension / እርገት', date: new Date(2027, 5, 10), description: 'Feast of the Ascension, Jesus\' ascension into heaven / እርገት፣ በኦርቶዶክስ አማኞች ዘንድ፣ እየሱስ ክርስቶስ ሞቶ ከተነሳ በኋላ ሰውነቱ ያረገበት በግንቦት ወር ሐሙስ ቀን የሚከበር በዓል' },
    { title: 'Pentecost / በአለ ሃምሳ', date: new Date(2027, 5, 20), description: 'Pentecost, descent of the Holy Spirit upon the Apostles /  በዓለ ጴራቅሊጦስ' },
    { title: 'The Apostles Fast / ፆመ ሃዋርያት', date: new Date(2027, 5, 21), description: 'Apostles Fast, preparation for the feast of Saints Peter and Paul' },
    { title: 'Tsome Dihnet / ፆመ ድህነት', date: new Date(2027, 5, 23), description: 'Tsome Dihnet, For Orthodox christians, Wednesday and Friday fasting resumed on this day after the easter./ ፆመ ድህነት፣ ከበዓለ ሀምሳ በኋላ ረቡዕ እና አርብ ፆም የሚጀምርበት ዕለት.' },
    { title: 'The Fast of the Holy Virgin Mary / ፆመ ፍልሰታ', date: new Date(2027, 7, 7), description: 'Fast of the Holy Virgin Mary, preparing for the Assumption / የፍልሰታ ፆም መጀመሪያ፣ የኦርቶዶክስ ክርስትና አማኞች የእመቤታችን ፆም (ፍልሰታ ፆም) መጀመሪያ ቀን' },
    { title: 'The Ascension of St. Mary / የእመቤታችን እርገት', date: new Date(2027, 7, 22), description: 'Feast of the Assumption of the Blessed Virgin Mary' },

    { title: 'Ethiopian New Year / አዲስ አመት', date: new Date(2028, 8, 11), description: 'Ethiopian New Year, also known as Enkutatash / የኢትዮጵያ አዲስ አመት' },
    { title: 'Meskel / የመስቀል በአል', date: new Date(2028, 8, 27), description: 'Meskel, commemorating the discovery of the True Cross by Queen Helena / የመስቀል በዓል' },
    { title: 'Enkutatash / የገና በአል', date: new Date(2028, 12, 7), description: 'Ethiopian Christmas / ገና (የጌታችንና የመድሃኒታችን ኢየሱስ ክርስቶስ የልደት በአል)' },
    { title: 'Ethiopian Epiphany / ጥምቀት', date: new Date(2028, 2, 19), description: 'Timket, celebrating the baptism of Jesus Christ in the River Jordan / ጌታችንና የመድሃኒታችን ኢየሱስ ክርስቶስ በዮርዳኖስ የተጠመቀበት እለት'  },
    { title: 'Fast of the Prophets / ፆመ ነብያት', date: new Date(2028, 10, 24), description: 'Fast of the Prophets, preparing for the Nativity of Jesus' },
    { title: 'Fast of Nineveh / ፆመ ነነዌ', date: new Date(2028, 1, 7), description: 'Fast of Nineveh, repentance and fasting / የነነዌ ፆም መጀመሪያ ቀን፣ ፆም ነነዌ ከሰባቱ የአዋጅ አፅዋማት አንዷ ስትሆን፣ ለ3 ተከታታይ ቀናት ትፆማለች' },
    { title: 'Adwa / አድዋ', date: new Date(2028, 2, 2), description: 'Adwa The national Victory Day / አድዋ፣ ብሄራዊ በዓል' },
    { title: 'The Great Lent Fast / አብይ ፆም', date: new Date(2028, 1, 21), description: 'Start date of Hudadi fasting or Abiye Tsome (Lent), 56 days of fasting starts. / የሁዳዴ ፆም (ዓብይ ፆም) መጀመሪያ ቀን፣ የሁዳዴ ፆም (የፋሲካ ፆም) መጀመሪያ ቀን' },
    { title: 'Mount of Olives / ደብረ ዘይት', date: new Date(2028, 2, 19), description: 'Debra zeit, It is held that the Second Advent will take place on the Mount of Olives. / ደብረ ዘይት (እኩለ ፆም)፣ የዓቢይ ፆም እኩሌታ ላይ ያለው ሰንበት' },
    { title: 'Palm Sunday / ሆሳእና', date: new Date(2028, 3, 9), description: 'Palm Sunday or Hosanna, Starting from this date, the week is celebrated with proper ceremonies with palm, processions and special services./ ሆሳህና፣ ሆሳህና ' },
    { title: 'Good Friday / ስቅለት', date: new Date(2028, 3, 14), description: 'Good Friday, crucifixion of Jesus Christ / ስቅለት፣ አማኞች በስግደት እና በፀሎት ሀጢያታቸውን ለማንፃት የሚተጉበት ቀን።' },
    { title: 'Easter Sunday / ፋሲካ', date: new Date(2028, 4, 16), description: 'Easter Sunday, resurrection of Jesus Christ / ትንሳዔ፣ ፋሲካ፣ በኦርቶዶክስ ክርስቲያኖች ዘንድ የደስታ እና የፌሽታ ተምሳሌት የሆነ ዕለት' },
    { title: 'The Holy Virgin Mary Birth day / ልደታ', date: new Date(2028, 4, 9), description: 'The BirthDay of the Blessed Virgin Mary' },
    { title: 'Assembly of Priests / ርክበ ካህናት', date: new Date(2028, 4, 10), description: 'Assembly of Priests, gathering of clergy / ረከበ ካህናት፣ ከትንሳዔ በኋላ በ25ኛው ዕለት ወይም የበዓለ ሃምሳ እኩሌታ ላይ የሚውል በዓል' },
    { title: 'Feast of the Ascension / እርገት', date: new Date(2028, 4, 25), description: 'Feast of the Ascension, Jesus\' ascension into heaven / እርገት፣ በኦርቶዶክስ አማኞች ዘንድ፣ እየሱስ ክርስቶስ ሞቶ ከተነሳ በኋላ ሰውነቱ ያረገበት በግንቦት ወር ሐሙስ ቀን የሚከበር በዓል' },
    { title: 'Pentecost / በአለ ሃምሳ', date: new Date(2028, 5, 4), description: 'Pentecost, descent of the Holy Spirit upon the Apostles /  በዓለ ጴራቅሊጦስ' },
    { title: 'The Apostles Fast / ፆመ ሃዋርያት', date: new Date(2028, 5, 5), description: 'Apostles Fast, preparation for the feast of Saints Peter and Paul' },
    { title: 'Tsome Dihnet / ፆመ ድህነት', date: new Date(2028, 5, 7), description: 'Tsome Dihnet, For Orthodox christians, Wednesday and Friday fasting resumed on this day after the easter./ ፆመ ድህነት፣ ከበዓለ ሀምሳ በኋላ ረቡዕ እና አርብ ፆም የሚጀምርበት ዕለት.' },
    { title: 'The Fast of the Holy Virgin Mary / ፆመ ፍልሰታ', date: new Date(2028, 7, 7), description: 'Fast of the Holy Virgin Mary, preparing for the Assumption / የፍልሰታ ፆም መጀመሪያ፣ የኦርቶዶክስ ክርስትና አማኞች የእመቤታችን ፆም (ፍልሰታ ፆም) መጀመሪያ ቀን' },
    { title: 'The Ascension of St. Mary / የእመቤታችን እርገት', date: new Date(2028, 7, 22), description: 'Feast of the Assumption of the Blessed Virgin Mary' },

    { title: 'Ethiopian New Year / አዲስ አመት', date: new Date(2029, 8, 11), description: 'Ethiopian New Year, also known as Enkutatash / የኢትዮጵያ አዲስ አመት' },
    { title: 'Meskel / የመስቀል በአል', date: new Date(2029, 8, 27), description: 'Meskel, commemorating the discovery of the True Cross by Queen Helena / የመስቀል በዓል' },
    { title: 'Enkutatash / የገና በአል', date: new Date(2029, 12, 7), description: 'Ethiopian Christmas / ገና (የጌታችንና የመድሃኒታችን ኢየሱስ ክርስቶስ የልደት በአል)' },
    { title: 'Ethiopian Epiphany / ጥምቀት', date: new Date(2029, 12, 19), description: 'Timket, celebrating the baptism of Jesus Christ in the River Jordan / ጌታችንና የመድሃኒታችን ኢየሱስ ክርስቶስ በዮርዳኖስ የተጠመቀበት እለት'  },
    { title: 'Fast of the Prophets / ፆመ ነብያት', date: new Date(2029, 10, 24), description: 'Fast of the Prophets, preparing for the Nativity of Jesus' },
    { title: 'Fast of Nineveh / ፆመ ነነዌ', date: new Date(2029, 12, 29), description: 'Fast of Nineveh, repentance and fasting / የነነዌ ፆም መጀመሪያ ቀን፣ ፆም ነነዌ ከሰባቱ የአዋጅ አፅዋማት አንዷ ስትሆን፣ ለ3 ተከታታይ ቀናት ትፆማለች' },
    { title: 'Adwa / አድዋ', date: new Date(2029, 2, 2), description: 'Adwa The national Victory Day / አድዋ፣ ብሄራዊ በዓል' },
    { title: 'The Great Lent Fast / አብይ ፆም', date: new Date(2029, 1, 12), description: 'Start date of Hudadi fasting or Abiye Tsome (Lent), 56 days of fasting starts. / የሁዳዴ ፆም (ዓብይ ፆም) መጀመሪያ ቀን፣ የሁዳዴ ፆም (የፋሲካ ፆም) መጀመሪያ ቀን' },
    { title: 'Mount of Olives / ደብረ ዘይት', date: new Date(2029, 2, 11), description: 'Debra zeit, It is held that the Second Advent will take place on the Mount of Olives. / ደብረ ዘይት (እኩለ ፆም)፣ የዓቢይ ፆም እኩሌታ ላይ ያለው ሰንበት' },
    { title: 'Palm Sunday / ሆሳእና', date: new Date(2029, 3, 1), description: 'Palm Sunday or Hosanna, Starting from this date, the week is celebrated with proper ceremonies with palm, processions and special services./ ሆሳህና፣ ሆሳህና ' },
    { title: 'Good Friday / ስቅለት', date: new Date(2029, 3, 6), description: 'Good Friday, crucifixion of Jesus Christ / ስቅለት፣ አማኞች በስግደት እና በፀሎት ሀጢያታቸውን ለማንፃት የሚተጉበት ቀን።' },
    { title: 'Easter Sunday / ፋሲካ', date: new Date(2029, 3, 8), description: 'Easter Sunday, resurrection of Jesus Christ / ትንሳዔ፣ ፋሲካ፣ በኦርቶዶክስ ክርስቲያኖች ዘንድ የደስታ እና የፌሽታ ተምሳሌት የሆነ ዕለት' },
    { title: 'The Holy Virgin Mary Birth day / ልደታ', date: new Date(2029, 4, 9), description: 'The BirthDay of the Blessed Virgin Mary' },
    { title: 'Assembly of Priests / ርክበ ካህናት', date: new Date(2029, 4, 2), description: 'Assembly of Priests, gathering of clergy / ረከበ ካህናት፣ ከትንሳዔ በኋላ በ25ኛው ዕለት ወይም የበዓለ ሃምሳ እኩሌታ ላይ የሚውል በዓል' },
    { title: 'Feast of the Ascension / እርገት', date: new Date(2029, 4, 17), description: 'Feast of the Ascension, Jesus\' ascension into heaven / እርገት፣ በኦርቶዶክስ አማኞች ዘንድ፣ እየሱስ ክርስቶስ ሞቶ ከተነሳ በኋላ ሰውነቱ ያረገበት በግንቦት ወር ሐሙስ ቀን የሚከበር በዓል' },
    { title: 'Pentecost / በአለ ሃምሳ', date: new Date(2029, 4, 27), description: 'Pentecost, descent of the Holy Spirit upon the Apostles /  በዓለ ጴራቅሊጦስ' },
    { title: 'The Apostles Fast / ፆመ ሃዋርያት', date: new Date(2029, 4, 28), description: 'Apostles Fast, preparation for the feast of Saints Peter and Paul' },
    { title: 'Tsome Dihnet / ፆመ ድህነት', date: new Date(2029, 4, 30), description: 'Tsome Dihnet, For Orthodox christians, Wednesday and Friday fasting resumed on this day after the easter./ ፆመ ድህነት፣ ከበዓለ ሀምሳ በኋላ ረቡዕ እና አርብ ፆም የሚጀምርበት ዕለት.' },
    { title: 'The Fast of the Holy Virgin Mary / ፆመ ፍልሰታ', date: new Date(2029, 7, 7), description: 'Fast of the Holy Virgin Mary, preparing for the Assumption / የፍልሰታ ፆም መጀመሪያ፣ የኦርቶዶክስ ክርስትና አማኞች የእመቤታችን ፆም (ፍልሰታ ፆም) መጀመሪያ ቀን' },
    { title: 'The Ascension of St. Mary / የእመቤታችን እርገት', date: new Date(2029, 7, 22), description: 'Feast of the Assumption of the Blessed Virgin Mary' },

    { title: 'Ethiopian New Year / አዲስ አመት', date: new Date(2030, 8, 11), description: 'Ethiopian New Year, also known as Enkutatash / የኢትዮጵያ አዲስ አመት' },
    { title: 'Meskel / የመስቀል በአል', date: new Date(2030, 8, 27), description: 'Meskel, commemorating the discovery of the True Cross by Queen Helena / የመስቀል በዓል' },
    { title: 'Enkutatash / የገና በአል', date: new Date(2030, 12, 7), description: 'Ethiopian Christmas / ገና (የጌታችንና የመድሃኒታችን ኢየሱስ ክርስቶስ የልደት በአል)' },
    { title: 'Ethiopian Epiphany / ጥምቀት', date: new Date(2030, 12, 19), description: 'Timket, celebrating the baptism of Jesus Christ in the River Jordan / ጌታችንና የመድሃኒታችን ኢየሱስ ክርስቶስ በዮርዳኖስ የተጠመቀበት እለት'  },
    { title: 'Fast of the Prophets / ፆመ ነብያት', date: new Date(2030, 10, 24), description: 'Fast of the Prophets, preparing for the Nativity of Jesus' },
    { title: 'Fast of Nineveh / ፆመ ነነዌ', date: new Date(2030, 1, 18), description: 'Fast of Nineveh, repentance and fasting / የነነዌ ፆም መጀመሪያ ቀን፣ ፆም ነነዌ ከሰባቱ የአዋጅ አፅዋማት አንዷ ስትሆን፣ ለ3 ተከታታይ ቀናት ትፆማለች' },
    { title: 'Adwa / አድዋ', date: new Date(2030, 2, 2), description: 'Adwa The national Victory Day / አድዋ፣ ብሄራዊ በዓል' },
    { title: 'The Great Lent Fast / አብይ ፆም', date: new Date(2030, 2, 4), description: 'Start date of Hudadi fasting or Abiye Tsome (Lent), 56 days of fasting starts. / የሁዳዴ ፆም (ዓብይ ፆም) መጀመሪያ ቀን፣ የሁዳዴ ፆም (የፋሲካ ፆም) መጀመሪያ ቀን' },
    { title: 'Mount of Olives / ደብረ ዘይት', date: new Date(2030, 2, 31), description: 'Debra zeit, It is held that the Second Advent will take place on the Mount of Olives. / ደብረ ዘይት (እኩለ ፆም)፣ የዓቢይ ፆም እኩሌታ ላይ ያለው ሰንበት' },
    { title: 'Palm Sunday / ሆሳእና', date: new Date(2030, 3, 21), description: 'Palm Sunday or Hosanna, Starting from this date, the week is celebrated with proper ceremonies with palm, processions and special services./ ሆሳህና፣ ሆሳህና ' },
    { title: 'Good Friday / ስቅለት', date: new Date(2030, 3, 26), description: 'Good Friday, crucifixion of Jesus Christ / ስቅለት፣ አማኞች በስግደት እና በፀሎት ሀጢያታቸውን ለማንፃት የሚተጉበት ቀን።' },
    { title: 'Easter Sunday / ፋሲካ', date: new Date(2030, 3, 28), description: 'Easter Sunday, resurrection of Jesus Christ / ትንሳዔ፣ ፋሲካ፣ በኦርቶዶክስ ክርስቲያኖች ዘንድ የደስታ እና የፌሽታ ተምሳሌት የሆነ ዕለት' },
    { title: 'The Holy Virgin Mary Birth day / ልደታ', date: new Date(2030, 4, 9), description: 'The BirthDay of the Blessed Virgin Mary' },
    { title: 'Assembly of Priests / ርክበ ካህናት', date: new Date(2030, 4, 22), description: 'Assembly of Priests, gathering of clergy / ረከበ ካህናት፣ ከትንሳዔ በኋላ በ25ኛው ዕለት ወይም የበዓለ ሃምሳ እኩሌታ ላይ የሚውል በዓል' },
    { title: 'Feast of the Ascension / እርገት', date: new Date(2030, 5, 6), description: 'Feast of the Ascension, Jesus\' ascension into heaven / እርገት፣ በኦርቶዶክስ አማኞች ዘንድ፣ እየሱስ ክርስቶስ ሞቶ ከተነሳ በኋላ ሰውነቱ ያረገበት በግንቦት ወር ሐሙስ ቀን የሚከበር በዓል' },
    { title: 'Pentecost / በአለ ሃምሳ', date: new Date(2030, 5, 16), description: 'Pentecost, descent of the Holy Spirit upon the Apostles /  በዓለ ጴራቅሊጦስ' },
    { title: 'The Apostles Fast / ፆመ ሃዋርያት', date: new Date(2030, 5, 17), description: 'Apostles Fast, preparation for the feast of Saints Peter and Paul' },
    { title: 'Tsome Dihnet / ፆመ ድህነት', date: new Date(2030, 5, 19), description: 'Tsome Dihnet, For Orthodox christians, Wednesday and Friday fasting resumed on this day after the easter./ ፆመ ድህነት፣ ከበዓለ ሀምሳ በኋላ ረቡዕ እና አርብ ፆም የሚጀምርበት ዕለት.' },
    { title: 'The Fast of the Holy Virgin Mary / ፆመ ፍልሰታ', date: new Date(2030, 7, 7), description: 'Fast of the Holy Virgin Mary, preparing for the Assumption / የፍልሰታ ፆም መጀመሪያ፣ የኦርቶዶክስ ክርስትና አማኞች የእመቤታችን ፆም (ፍልሰታ ፆም) መጀመሪያ ቀን' },
    { title: 'The Ascension of St. Mary / የእመቤታችን እርገት', date: new Date(2030, 7, 22), description: 'Feast of the Assumption of the Blessed Virgin Mary' },

    { title: 'Ethiopian New Year / አዲስ አመት', date: new Date(2031, 8, 11), description: 'Ethiopian New Year, also known as Enkutatash / የኢትዮጵያ አዲስ አመት' },
    { title: 'Meskel / የመስቀል በአል', date: new Date(2031, 8, 27), description: 'Meskel, commemorating the discovery of the True Cross by Queen Helena / የመስቀል በዓል' },
    { title: 'Enkutatash / የገና በአል', date: new Date(2031, 12, 7), description: 'Ethiopian Christmas / ገና (የጌታችንና የመድሃኒታችን ኢየሱስ ክርስቶስ የልደት በአል)' },
    { title: 'Ethiopian Epiphany / ጥምቀት', date: new Date(2031, 12, 19), description: 'Timket, celebrating the baptism of Jesus Christ in the River Jordan / ጌታችንና የመድሃኒታችን ኢየሱስ ክርስቶስ በዮርዳኖስ የተጠመቀበት እለት'  },
    { title: 'Fast of the Prophets / ፆመ ነብያት', date: new Date(2031, 10, 24), description: 'Fast of the Prophets, preparing for the Nativity of Jesus' },
    { title: 'Fast of Nineveh / ፆመ ነነዌ', date: new Date(2031, 1, 3), description: 'Fast of Nineveh, repentance and fasting / የነነዌ ፆም መጀመሪያ ቀን፣ ፆም ነነዌ ከሰባቱ የአዋጅ አፅዋማት አንዷ ስትሆን፣ ለ3 ተከታታይ ቀናት ትፆማለች' },
    { title: 'Adwa / አድዋ', date: new Date(2031, 2, 2), description: 'Adwa The national Victory Day / አድዋ፣ ብሄራዊ በዓል' },
    { title: 'The Great Lent Fast / አብይ ፆም', date: new Date(2031, 1, 17), description: 'Start date of Hudadi fasting or Abiye Tsome (Lent), 56 days of fasting starts. / የሁዳዴ ፆም (ዓብይ ፆም) መጀመሪያ ቀን፣ የሁዳዴ ፆም (የፋሲካ ፆም) መጀመሪያ ቀን' },
    { title: 'Mount of Olives / ደብረ ዘይት', date: new Date(2031, 2, 16), description: 'Debra zeit, It is held that the Second Advent will take place on the Mount of Olives. / ደብረ ዘይት (እኩለ ፆም)፣ የዓቢይ ፆም እኩሌታ ላይ ያለው ሰንበት' },
    { title: 'Palm Sunday / ሆሳእና', date: new Date(2031, 3, 6), description: 'Palm Sunday or Hosanna, Starting from this date, the week is celebrated with proper ceremonies with palm, processions and special services./ ሆሳህና፣ ሆሳህና ' },
    { title: 'Good Friday / ስቅለት', date: new Date(2031, 3, 11), description: 'Good Friday, crucifixion of Jesus Christ / ስቅለት፣ አማኞች በስግደት እና በፀሎት ሀጢያታቸውን ለማንፃት የሚተጉበት ቀን።' },
    { title: 'Easter Sunday / ፋሲካ', date: new Date(2031, 3, 13), description: 'Easter Sunday, resurrection of Jesus Christ / ትንሳዔ፣ ፋሲካ፣ በኦርቶዶክስ ክርስቲያኖች ዘንድ የደስታ እና የፌሽታ ተምሳሌት የሆነ ዕለት' },
    { title: 'The Holy Virgin Mary Birth day / ልደታ', date: new Date(2031, 4, 9), description: 'The BirthDay of the Blessed Virgin Mary' },
    { title: 'Assembly of Priests / ርክበ ካህናት', date: new Date(2031, 4, 7), description: 'Assembly of Priests, gathering of clergy / ረከበ ካህናት፣ ከትንሳዔ በኋላ በ25ኛው ዕለት ወይም የበዓለ ሃምሳ እኩሌታ ላይ የሚውል በዓል' },
    { title: 'Feast of the Ascension / እርገት', date: new Date(2031, 4, 22), description: 'Feast of the Ascension, Jesus\' ascension into heaven / እርገት፣ በኦርቶዶክስ አማኞች ዘንድ፣ እየሱስ ክርስቶስ ሞቶ ከተነሳ በኋላ ሰውነቱ ያረገበት በግንቦት ወር ሐሙስ ቀን የሚከበር በዓል' },
    { title: 'Pentecost / በአለ ሃምሳ', date: new Date(2031, 5, 1), description: 'Pentecost, descent of the Holy Spirit upon the Apostles /  በዓለ ጴራቅሊጦስ' },
    { title: 'The Apostles Fast / ፆመ ሃዋርያት', date: new Date(2031, 5, 2), description: 'Apostles Fast, preparation for the feast of Saints Peter and Paul' },
    { title: 'Tsome Dihnet / ፆመ ድህነት', date: new Date(2031, 5, 4), description: 'Tsome Dihnet, For Orthodox christians, Wednesday and Friday fasting resumed on this day after the easter./ ፆመ ድህነት፣ ከበዓለ ሀምሳ በኋላ ረቡዕ እና አርብ ፆም የሚጀምርበት ዕለት.' },
    { title: 'The Fast of the Holy Virgin Mary / ፆመ ፍልሰታ', date: new Date(2031, 7, 7), description: 'Fast of the Holy Virgin Mary, preparing for the Assumption / የፍልሰታ ፆም መጀመሪያ፣ የኦርቶዶክስ ክርስትና አማኞች የእመቤታችን ፆም (ፍልሰታ ፆም) መጀመሪያ ቀን' },
    { title: 'The Ascension of St. Mary / የእመቤታችን እርገት', date: new Date(2031, 7, 22), description: 'Feast of the Assumption of the Blessed Virgin Mary' },

  ];
  

  const handleDateChange = date => {
    setSelectedDate(date);
  };

  // Find events for the selected date
  const selectedEvents = ethiopianEvents.filter(event => event.date.toDateString() === selectedDate.toDateString());

  return (
    <div className={`bg-gradient-to-b ${theme === 'light' ? 'from-gray-800 to-gray-900' : 'from-blue-100 to-blue-300'} text-${theme === 'light' ? 'slate-900' : 'gray-600'} w-full overflow-hidden py-8 px-2`}>
      <div className="container mx-auto py-8 px-4">
        <div className=" flex flex-col items-center">
          <div className="max-w-4xl w-full flex flex-col items-center">
            
          <div className="container mx-auto mb-4">
            <BreakingComp />
          </div>
      
            <div className="w-full flex justify-between">
              <h1 className="bg-slate-300 text-xl md:text-2xl lg:text-3xl text-blue-800 font-bold mb-4 pl-5 pr-5 rounded-lg border shadow-lg shadow-slate-100">Events Calendar</h1>
              <h1 className="bg-slate-300 text-xl md:text-2xl lg:text-3xl text-blue-800 font-bold mb-4 pl-5 pr-5 rounded-lg border shadow-lg shadow-slate-100">በዓላትና አፅዋማት</h1>
            </div>
            <div className=" border border-slate-100 p-4 shadow-lg shadow-slate-100 rounded-lg w-full flex justify-between items-start">
              <Calendar
                onChange={handleDateChange}
                value={selectedDate}
                className="border-solid border-2 rounded-lg shadow-md w-3/4"
                tileClassName={({ date }) => {
                  const eventDate = ethiopianEvents.find(event => event.date.toDateString() === date.toDateString());
                  return eventDate ? 'has-event event-date' : '';
                }}
              />
              {/* Conditionally hide image on small screens */}
              <img src={sikilet} alt="Manager" className="w-1/4 md:w-1/2 lg:w-1/2 h-auto rounded-lg hidden md:block" />
            </div>
            <div className="mt-8 w-full bg-slate-300 border border-slate-100 rounded-lg p-4 shadow-lg shadow-slate-100">
              <h2 className="text-2xl font-bold text-blue-800 mb-4 underline">Event Details</h2>
              {selectedEvents.length > 0 ? (
                <div>
                  <h3 className="text-lg text-blue-800 font-semibold mb-2 ">On this day: {selectedDate.toDateString()}</h3>
                  {selectedEvents.map(event => (
                    <div key={event.title} className="bg-gray-400 opacity-85 text-blue-800 p-4 rounded-lg mb-2">
                      <h4 className="text-base font-semibold">It is : <span className='text-red-700'>{event.title}</span></h4>
                      <p className="text-sm">{event.description}</p>
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  <h3 className="text-lg text-blue-800 font-semibold mb-2">No events on this day: {selectedDate.toDateString()}</h3>
                </div>
              )}
            </div>
          </div>    
        </div>
      </div>
    </div>
  );

}

export default Events;