import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_BREAKING_NEWS } from '../utils/mutations';

const Breaking = () => {
  const [body, setBody] = useState('');
  const [createBreakingNews] = useMutation(CREATE_BREAKING_NEWS);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await createBreakingNews({ variables: { body } });
      console.log('New Breaking News created:', data.createBreakingNews);
      // Optionally, you can reset the form after successful submission
      setBody('');
    } catch (error) {
      console.error('Error creating Breaking News:', error);
    }
  };

  return (
    <div className="flex justify-center items-center">
      <div className="w-full max-w-3xl bg-white shadow-md rounded px-8 pt-6 mb-4 ">
        <h2 className="text-2xl font-bold mb-4 text-center">Create Event News</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="body" className="block text-gray-700 font-bold mb-2">Body: አጭር ዜናዎች፣ ለምሳሌ የእንኳን አደረሳችሁ አይነት </label>
            <textarea
              id="body"
              name="body"
              value={body}
              onChange={(e) => setBody(e.target.value)}
              required
              className="w-full h-32 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="flex items-center justify-center">
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Breaking;
