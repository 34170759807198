import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_WEDDING_CEREMONY_REQUEST } from '../utils/mutations';
import DOMPurify from 'dompurify';
import { useTheme } from '../components/ThemeContext';

import crossIcon from '../images/crossIcon.png';

const WeddingCeremonyRequestForm = () => {
  const [formData, setFormData] = useState({
    brideFullName: '',
    groomFullName: '',
    brideBaptismalName: '',
    groomBaptismalName: '',
    brideAddress: '',
    groomAddress: '',
    dateOfWedding: '',
  });

  const [createWeddingCeremonyRequest, { loading, error }] = useMutation(
    CREATE_WEDDING_CEREMONY_REQUEST
  );
  const [successMessage, setSuccessMessage] = useState('');
  const {  theme } = useTheme();
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: sanitizeInput(value) });
  };

  const sanitizeInput = (input) => {
    return DOMPurify.sanitize(input);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await createWeddingCeremonyRequest({
        variables: {
          input: formData,
        },
      });
      console.log('Wedding certification request created:', data);
  
      // Send email
      const emailResponse = await sendEmail(
        formData.SenderEmail,
        'stmaryueotc@gmail.com',
        'Wedding Certification Request',
        formData
      );
      setSuccessMessage('the requested service sent successfully / የጠየቃችሁት አገልግሎት ተልኳል!');

      if (emailResponse.success) {
        console.log('Email sent successfully:', emailResponse.message);
      } else {
        console.error('Failed to send email:', emailResponse.message);
      }
  
      // Optionally, you can reset the form state here
    } catch (error) {
      console.error('Error creating wedding certification request:', error);
    }
  };
  
  // Function to send email
  const sendEmail = async (senderEmail, recipientEmail, subject, formData) => {
    try {
      const body = `
        ${formData.brideFullName} የጋብቻ ምስክር ወረቀት ጠይቋል፡:
        
        የሙሽራው ስም:-- ${formData.brideFullName}
        የሙሽራዋ ስም:-- ${formData.groomFullName}
        
        የሙሽራው ክርስትና ስም:-- ${formData.brideBaptismalName}
        የሙሽራዋ ክርስትና ስም:-- ${formData.groomBaptismalName}

        የሙሽራው አድራሻ:-- ${formData.brideAddress}
        የሙሽራዋ አድራሻ:-- ${formData.groomAddress}
        የጋብቻ ቀን:-- ${formData.dateOfWedding}
        `;
        const response = await fetch('/send-email', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ senderEmail, recipientEmail, subject, body }),
          });
          return await response.json();
        } catch (error) {
          console.error('Error sending email:', error);
          return { success: false, message: 'Failed to send email' };
        }
      };
    

  return (    
    <div className={`bg-gradient-to-b ${theme === 'light' ? 'from-blue-100 to-blue-300' : 'from-gray-800 to-gray-900'} text-${theme === 'light' ? 'slate-900' : 'white'} w-full overflow-hidden py-8 px-2`}>
      <img src = {crossIcon} alt = "crossIcon" className = "w-20 h-20 mx-auto" />
      <div className="max-w-md mx-auto m-8 border b border-slate-200 rounded-lg p-4">
        <h2 className='text-2xl text-center font-semibold mb-4 bg-gray-900 text-slate-300 p-4 rounded-lg'>Wedding Certification Request Form / የጋብቻ ምስክር ወረቀት መጠየቂያ ቅፅ</h2>
        <form onSubmit={handleSubmit}>
          <label className='font-semibold'>
            Bride's Full Name: የሙሽራው ሙሉ ስም
            <input
              type="text"
              name="brideFullName"
              value={formData.brideFullName}
              onChange={handleChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
          </label>
            <label className='font-semibold'>
                Groom's Full Name: የሙሽራዋ ሙሉ ስም
                <input
                type="text"
                name="groomFullName"
                value={formData.groomFullName}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                />
            </label>
            <label className='font-semibold'>
                Bride's Baptismal Name: የሙሸራው ክርስትና ስም
                <input
                type="text"
                name="brideBaptismalName"
                value={formData.brideBaptismalName}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                />
            </label>
            <label  className='font-semibold'>
                Groom's Baptismal Name: የሙሽራዋ ክርስትና ስም
                <input
                type="text"
                name="groomBaptismalName"
                value={formData.groomBaptismalName}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                />
            </label>
            <label className='font-semibold'>
                Bride's Address: የሙሽራው አድራሻ
                <input
                type="text"
                name="brideAddress"
                value={formData.brideAddress}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                />
            </label>
            <label className='font-semibold'>
                Groom's Address: የሙሽራዋ አድራሻ
                <input
                type="text"
                name="groomAddress"
                value={formData.groomAddress}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                />
            </label>
            <label className='font-semibold'>
                Date of Wedding: የጋብቻ ቀን
                <input
                type="date"
                name="dateOfWedding"
                value={formData.dateOfWedding}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border mb-4 border-gray-300 rounded focus:outline-none focus:border-blue-500"
                />
            </label>
            {successMessage && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
              {successMessage}
            </div>
          )}
          <button 
          type="submit" 
          className="w-full py-2 bg-violet-700 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4"
          onClick={(e) => {
            e.preventDefault(); // Prevent the default form submission behavior
            handleSubmit(e); // Pass the event object to handleSubmit
            setFormData({
              brideFullName: '',
              groomFullName: '',
              brideBaptismalName: '',
              groomBaptismalName: '',
              brideAddress: '',
              groomAddress: '',
              dateOfWedding: '',
            });
          }
        }
        >
          Submit
        </button>
        </form>
        {error && <p>Error: {error.message}</p>}
      </div>
    </div>
  );
};

export default WeddingCeremonyRequestForm;
