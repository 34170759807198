import React, { useState } from 'react';
import Modal from '../components/Modal';
import ScrollToTopButton from "../components/ScrollToTopButton";
import { useTheme } from '../components/ThemeContext';

// Import service images
import liturgyImage from '../images/liturgy.jpg';
import baptismImage from '../images/baptism.jpg';
import confessionImage from '../images/confession.jpg';
import weddingImage from '../images/wedding.jpg';
import anointingImage from '../images/anointing.jpg';
import feastsImage from '../images/feasts.jpg';
import educationImage from '../images/education.jpg';

const servicesData = [
  {
    title: 'Mass Service/ የቅዳሴ አገልግሎት',
    description: ' Liturgical worship service, ቤተክርስቲያናችን ሥጋወደሙን የምታከብርበት የተለየ ሥርዓተ ጸሎት አላት፡፡ ይህ ልዩ የምስጋና ጸሎት ሥርዓተ ቅዳሴ /የቅዳሴ ሥርዓት/ ይባላል፡፡ ያለ ጸሎተ ቅዳሴ ሥጋወደሙ አይዘጋጀም (አይፈተትም) ፤ ያለ ሥጋወደሙም ቅዳሴ አይቀደስም፡፡ ሥርዓተ ቅዳሴ የሚለውን ቃል ስንመለከት “ሥርዓት” ማለት አሠራር፣ አፈፃጸም ተብሎ የሚተረጎም ሲሆን “ቅዳሴ” ደግሞ ምሥጋና ማለት ነው፤ ይኸውም ለእግዚአብሔር የሚቀርብ ምሥጋና ማለታችን ነው። የቅዳሴ ሥርዓት ካህናትና ምእመናን በአንድነት በመሆን ለአምላካችን ምስጋና የምናቀርብበት፣ ለበደላችን ይቅርታና ምሕረት የምንጠይቅበት፣ ኅብስቱና ወይኑ የሚለወጥበት የጋራ የጸሎት ክፍለ ጊዜ ነው ፡፡ በቅዳሴ ጸሎት “እግዚኦ ተሣሃለነ” አቤቱ ይቅር በለን ፤ “ተዘከረነ እግዚኦ በውስተ መንግሥትከ…” አቤቱ በመንግሥትህ አስበን ፤ “ንዜኑ ሞተከ እግዚኦ ወትንሣኤከ ቅድስተ…” አቤቱ ሞትህንና ልዩ የሚሆን ትንሣኤህን እንናገራለን በማለት “እኔ” ብለን ሳይሆን “እኛ” እያልን ከፈጣሪያችን ጋር የምንገናኝበት የጸሎት ሰዓት በመሆኑ በቅዳሴ ጊዜ የግል ጸሎት የተከለከለ ነው።  ',
    image: liturgyImage,
  },
  {
    title: 'Baptism / ስርዓተ ጥምቀት',
    description: 'Providing a baptismal service for infants and adults፣ አስቀድመን በሥጋ ከእናትና አባታችን በዘር በሩካቤ እንደተወለድንና የሥጋ ልጅነትን እንዳገኘን ሁሉ በጥምቀት (በማየ ገቦ ስንጠመቅ) ከአብራከ መንፈስ ቅዱስ እንወለዳለን። ይህንን አስመልክቶ ጌታችን “ከሥጋ የሚወለድ ሥጋ ነው፣ ከመንፈስ የሚወለድ መንፈስ ነው” ብሎ ከማስተማሩ በፊት “ሰው ዳግመኛ ካልተወለደ በቀር የእግዚአብሔርን መንግሥት ሊያይ አይችልም” ያለው (ዮሐ. ፫፣ ፫ እና ፮)። በምሥጢረ ጥምቀት ሀብተ ወልድና ስመ ክርስትና ስለመገኘቱ አስተምሯል። ሐዋርያውም በጥምቀት ስላገኘነው የልጅነት ጸጋ አስመልክቶ ሲናገር “አባ አባት ብለን የምንጮኸበትን ወይም የምንጣራበትን የልጅነትን መንፈስ ተቀበላችሁ እንጂ እንደገና ለፍርሃት የባርነትን መንፈስ አልተቀበላችሁምና። የእግዚአብሔር ልጆች መሆናችንን ያ መንፈስ ራሱ ከመንፈስ ጋር ይመሰክራል” ብሏል (ሮሜ.፰፣ ፲፭-፲፮)። ጌታችንንም ለተቀበሉት ሁሉ በስሙም ላመኑበት በጠቅላላው የእግዚአብሔር ልጆች ይሆኑ ዘንድ ሥልጣንን ሰጥቷቸዋል። እነርሱም ከእግዚአብሔር ተወለዱ እንጂ ከደም ወይም ከሥጋ ፈቃድ ወይም ከወንድ ፈቃድ አለመወለዳቸው ተገልጿል (ዮሐ.፩፣ ፩-፲፫)።',
    image: baptismImage,
  },
  {
    title: 'Confession / የንስሓ አገልግሎት',
    description: 'Seeking forgiveness and spiritual guidance፣ ጌታችን መድኃኒታችን ኢየሱስ ክርስቶስ ራሱ መርጦ የመንጋው እረኞች እንዲሆኑ ለሾማቸው ቅዱሳን ሐዋርያት በአንብሮተ እድ የሰጣቸው ስልጣነ ክህነት ከኃጢአት ማሰሪያ የሚፈታ፣ አመፀኞችን የሚያሥሩበት፤ ቦታ እና ጊዜ የማይወስነው ረቂቅ ስልጣን ነው። ስልጣኑን የሰጣቸውም ራሱ ባለቤቱ “የመንግስተ ሰማያትንም መክፈቻዎች እሰጥሃለው በምድር የምታሥረው ሁሉ በሰማያት የታሠረ ይሆናል በምድር የምትፈታው ሁሉ በሰማያት የተፈታ ይሆናል” ማቴ 16:19 “እውነት እላችኋለው በምድር የምታሥሩት ሁሉ በሰማይ የታሰረ ይሆናል፤ በምድር የምትፈቱት በሰማይ የተፈታ ይሆናል” በማለት ለቅዱስ ጴጥሮስ እና ለሁሉም ሐዋርያት አስተምሯቸዋል። የክህነቱ ስልጣን ምድራዊ ሳይሆን ሰማያዊ ነው፤ ከምድራዊ ስልጣን ጋር የማይነፃፀርና ከፈጣሪ በስተቀር ማንም የማይሽረው መሆኑን ጌታችን መድኃኒታችን ኢየሱስ ክርስቶስ ለቅዱሳን ሐዋርያት ገልጾላቸዋል።',
    image: confessionImage,
  },
  {
    title: 'Weddings / መንፈሳዊ የጋብቻ አገልግሎት',
    description: 'Conducting wedding ceremonies according to EOTC፣ ክርስቲያናዊ ጋብቻ፣ በቃል ኪዳንና በቡራኬ፣ በጸሎተ ተክሊልና በቅዱስ ቍርባን መፈጸሙ ምሥጢርነት አለው፡፡ አንድነቱ ወይም ተዋሕዶው የክርስቶስና የቤተ ክርስቲያን ምሳሌ ነውና (ኤፌ. ፭፥፴፪)፡፡ በሥርዓተ ተክሊል ሙሽሮቹ የሚያደርጉት ቀለበት፣ የሚለብሱት ነጭ ልብስ፣ የሚቀቡት ቅብዓ ቅዱስ፣ የሚቀዳጁት አክሊል፣ እንደዚሁም የካህናቱ ጸሎትና ቡራኬ ሙሽሪትና ሙሽራው ከመንፈስ ቅዱስ ለሚቀበሉት በዓይን ለማይታይ ጸጋ ምክንያቶች ናቸው፡፡ ጸሎቱ ከደረሰ በኋላ ሥርዓተ ቅዳሴ ተደርጎ ሙሽሮቹ ሥጋውንና ደሙን ይቀበላሉ፡፡ ሥጋውና ደሙ የምሥጢራት ዂሉ ማኅተም ነውና፡፡ ያለ ሥጋውና ደሙ ሥርዓተ ተክሊልም ሥርዓተ ቍርባንም አይፈጸምም (ፍት. ነገ. ፳፬፣ መጅ ፩)፡፡ ከሥርዓተ ተክሊልና ከሥርዓተ ቍርባን ሥርዓት ውጪ ጋብቻን መመሥረት በፍጹም ሊለመድ አይገባም፡፡',
    image: weddingImage,
  },
  {
    title: 'Anointing / የቅዱስ ቁርባን አገልግሎት',
    description: 'Prayers and anointing for the sick and kids. በሚቀበሉት ቅዱስ ቁርባን ክርስቶስ ከእርሱ ጋር እነርሱ ከክርስቶስ ጋር አንድ ሆነው እንደሚኖሩ ሥጋው ከሥጋቸው ደሙ ከደማቸው ተዋህዶ አምላካቸው ከእነርሱ ጋር በጸጋ ተዋህዷቸው እንደሚኖር ለሚያምኑ ፣ የዘለዓለም ሕይወትን የሚያስገኝ መሆኑን አምነው የዘላለም ሕይወት ያሰጠኛል ብለው ለሚቀርቡ፤ "ሥጋዬን የሚበላ ደሜንም የሚጠጣ የዘላለም ሕይወት አለው እኔም በመጨረሻ ቀን አስነሳዋለ"፡ ዮሐ. 6፥53',
    image: anointingImage,
  },
  {
    title: 'Prayer /የምስጋናና የምሃላ ፀሎት',
    description: 'Prayers of praise and oath to the Lord፥ በቤተክርስቲያናችን ቀኖና መሰረት ካህን የሚጸልየው ጸሎት አለ፤ ምእመናንም የሚጸልዩት የራሱ ስርአተ ጸሎት አለ። በዚህኛው ዘመን በምእመናን ዘንድ የሚታየው ትልቁ የጸሎት ከፍተት እንደ ጥንት ክርስቲያኖች ለግል ጸሎት የሚያበቃ ሃይማኖታዊ እውቀት የለንም። በእርግጥ ወደየቋንቋው የተተረጎሙ ቢሆኑም እንኳን ሰው ግን ሁልጊዜ በጸሎት የመትጋት ፕሮግራም የለውምና ያም ሆኖ የሚጸልየው ሰው ያንሳል። ለመጸለይ አንድ ሰው የዘወትር ጸሎት በሚለው የጸሎት ክፍል በስመ አብ ከሚለው ጀምሮ ከቻለ እስከ ጸሎተ ማርያም ይጸልያል ፤ ከዚያም ከቻለ የየዕለቱን ውዳሴ ማርያም ይጸልያል። አሁንም የሚችል ከሆነ ቅድሚያ በመስጠት የየዕለቱን መዝሙረ ዳዊት በግዕዙ ባይቻልም በአማርኛው ወይም በሌላ በሚያውቀው ቋንቋ መጸለይ ይችላል።',
    image: feastsImage,
  },
  {
    title: 'Teaching and Education / መንፈሳዊ ትምህርት',
    description: 'Providing religious education and instruction. የኢትዮጵያ ኦርቶዶክስ ተዋሕዶ ቤተክርስቲያን የሆኑ የእምነት መሰረቶችን ለምእመኑ ለማደረስ ትምህርተ «ተዋሕዶ» ለምእመናኑ በየጊዜው ማዳረስ በጣም አስፈላጊ ነው። የተዋሕዶ ትምህርት በእለተ ቅዳሴ ብቻ በሚሰጡ ቃለ እግዚያብሄር ተዳሶ የማያልቅ ስለሆነ የእለተ ተእለት መንፈሳዊ ምግባችን የሆነውን ቃለ እግዚብሔርን በትምህርት መልክ መውሰዱ ለመንፈስ ደስታ ለስጋም እረፍት ነው።',
    image: educationImage,
  },
];

const Services = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const { theme } = useTheme();

  const openModal = (service) => {
    setSelectedService(service);
    setIsOpen(true);
  };

  const closeModal = () => {
    setSelectedService(null);
    setIsOpen(false);
  };

  return (
    <div className={`bg-gradient-to-b ${theme === 'light' ? 'from-gray-800 to-gray-900' : 'from-blue-100 to-blue-300'} text-${theme === 'light' ? 'slate-900' : 'white'} w-full overflow-hidden py-8 px-2`}>
      <ScrollToTopButton />
      <div className="container mx-auto">
      <div className={`${theme === 'light' ? 'bg-gray-900 text-gray-200' : 'bg-gray-100 text-gray-800'} shadow-md p-4 rounded-lg mb-4`}>
        <h2 className="text-3xl text-center ">ቅድስት ቤተክርስቲያናችን የምትሰጣቸው አገልግሎቶች</h2>
        <h2 className="text-2xl text-center m-4">Services provided by our Church</h2>
        </div>

        <div className={`text-${theme === 'light' ? 'gray-400' : 'gray-800'} grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4`}>
          {servicesData.map((service, index) => (
            <div key={index} className="border border-slate-100 rounded-lg overflow-hidden shadow-lg shadow-slate-100">
              <img src={service.image} alt={service.title} className="w-full h-56 object-cover shadow-lg shadow-slate-100" />
              <div className="p-4">
                <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
                <p>{isOpen && selectedService === service ? service.description : `${service.description.substring(0, 100)}...`}</p>
                <button className="text-pink-700 font-semibold hover:underline mt-2" onClick={() => openModal(service)}>Read More</button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal isOpen={isOpen} closeModal={closeModal} service={selectedService} />
    </div>
  );
};

export default Services;
