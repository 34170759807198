import React, {useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_RECENT_POST } from '../utils/queries'; // Assuming you have a query to fetch recent posts
import { Link } from 'react-router-dom';
import ScrollToTopButton from "../components/ScrollToTopButton";
import BreakingComp from '../components/BreakingComp';
import BealatModal from '../components/BealatModal';
import Sliding from '../components/Sliding';
import { useTheme } from '../components/ThemeContext';

import flag1 from '../images/flag1.png';
import flag2 from '../images/flag2.png';
import logo1 from '../images/logo1.png';
import Aba from '../images/Aba.jpg';
import sleshi from '../images/sleshi.jpg';
import michael from '../images/michael.jpg';

import Popup from '../components/Popup';

function Home() {
  const { loading, error, data } = useQuery(GET_RECENT_POST);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { theme } = useTheme();
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

    if (loading) {
      return <div>Loading...</div>;
    }

    if (error) {
      return <div>Error fetching recent post: {error.message}</div>;
  }

  const recentPost = data?.recentPost;

  
  return (
    <div className={`bg-gradient-to-b ${theme === 'light' ? 'from-gray-800 to-gray-900' : 'from-blue-100 to-blue-300'} text-${theme === 'light' ? 'slate-900' : 'white'} w-full overflow-hidden py-8`}>

        {/* Hero Box */}
        <div className="container mx-auto py-2">
  <div className="relative">
    {/* Left Flag */}
    <img
      src= {flag2}
      alt="Left Flag"
      className="absolute left-0 top-1/2 transform -translate-y-1/2 w-16 h-16"
    />

    {/* Right Flag */}
    <img
      src= {flag1}
      alt="Right Flag"
      className="absolute right-0 top-1/2 transform -translate-y-1/2 w-16 h-16"
    />

    {/* Text Content */}
    <div className={`flex items-center justify-center ${theme === 'light' ? 'bg-gray-900 text-gray-400' : 'bg-gray-100 text-gray-800'} shadow-md p-4 rounded-lg mb-4`}>
      <div>
      <h1 className="text-xl md:text-2xl lg:text-3xl font-semibold text-center">
          Welcome to St. Mary's Ethiopian Orthodox Tewahedo Church in Utah
        </h1>
        <h1 className="text-xl md:text-2xl lg:text-3xl mt-4  text-center">
          እንኳን ወደ ዩታ ቅድስት ማርያም የኢትዮጵያ ኦርቶዶክስ ተዋህዶ ቤተክርስቲያን በሰላም መጡ
        </h1>
      </div>
    </div>
  </div>
</div>

<div className="container mx-auto">
        <BreakingComp />
      </div>

           {/* Message Boxes */}
<div className="main mx-auto py-2">

<ScrollToTopButton />

  <div className="container mx-auto  grid grid-cols-1 lg:grid-cols-2 gap-8">
    {/* Left Message Box */}
    <div className={`${theme === 'light' ? 'bg-gray-900 text-gray-400' : 'bg-gray-100 text-gray-800'} shadow-md p-4 rounded-lg mb-4`}>
    <div className="grid grid-cols-1 gap-4">
  <div>
    <div className="flex flex-col items-center mb-2">
      <h3 className={`text-xl md:text-2xl m-2 text-center rounderd-lg ${theme === 'light' ? 'bg-gray-900' : 'bg-gray-600'} border-b text-slate-400 rounded-lg p-2`}>በስመ አብ ወወልድ ወመንፈስ ቅዱስ አሀዱ አምላክ፣ አሜን።</h3>
    </div>
    <div className='text-md border-b border-r border-l rounded-lg border-slate-400 p-2 md:text-lg lg:text-xl space-y-4'>
    <p className='md:text-lg lg:text-xl indent-7'>እንኳን ወደ ዩታ ቅድስት ማርያም የኢትዮጵያ ኦርቶዶክስ ተዋህዶ ቤተ ክርስቲያን ድህረ ገጽ በሰላም መጡ። የጌታችንና የመድኃኒታችን የኢየሱስ ክርስቶስ ፍቅርና ሰላም፤ የእናታችን የቅድስት ድንግል ማርያም በረከትና ረድኤት ይደርብዎ። በቆይታዎ ስለ ኢትዮጵያ ኦርቶዶክስ ተዋህዶ ሃይማኖት ሥርዓት እና ታሪክ ፤ እንዲሁም በሰሜን አሜሪካ ዩታ ስቴት ስለምትገኘው ቅድስት ማርያም ቤተ ክርስቲያን አመሰራረት ፡ ታሪክና በአሁኑ ሰአት የምታቀርበውን አገልግሎት ይረዳሉ።</p>
    <p className='md:text-lg lg:text-xl indent-7'>የዚህ ድህረ ገጽ መኖር ለቤተክርስቲያናችን ትልቅ እመርታ ነው። በተለይም ውድ አባታችን ቆሞስ መልከአ ጽዮን አባ ፍስሀ መኮነን የደብሩ አስተዳዳሪ፣ ላዕከነ ምስጢር ዲያቆናት፣ ምዕምናን ፣ የቤተክርስቲያኒቱ የቦርድ ሊቀመንበርና አባላት በዚህ እድገት ልትኮሩ ይገባል።</p>
    <p className='md:text-lg lg:text-xl indent-7'>ይህ ሁሉ ሊሆን የቻለው በስቴቱ ውስጥ በሚኖሩ ምዕምናን ልባዊ ጸሎት፣ ፍቅር፣ መተሳሰብና በተለያዩ መንገዶች በሃሳብ፣ በምክር፣ በጉልበት፣ በዕውቀት፣ በማቴሪያልና በገንዘብ በሚያደርጉት ልገሳ ነው።

     ምዕምናን በየሳምንቱ ዕሁድ ከሰሜናዊ ዩታ ሎጋን ከተማ ከአንድ ሰዓት ያላነሰ መንገድ እንዲሁም ከሶልት ሌክ ሲቲና አካባቢዋ ከተሞች ከ30-40 ደቂቃ ሌይተን ከተማ ድረስ በመንዳት የዕመቤታችን የኪዳነ ምህረት፣ የጌታችንና የመድኃኒታችን የኢየሱስ ክርስቶስ ረድኤትና በረከት ተካፋይ ናቸው። ይህን በረከት የሚያገኙት ምዕመናን ብዙ ድካም እና ፈተና አልፈው ነው በተለይም በዊንተር ወቅት በበረዶ ውስጥ በመንዳት።</p>
    <p className='md:text-lg lg:text-xl indent-7'>ዛሬ ቤተክርስቲያኗ ህዝበ ክርስቲያን፤ ህጻናት፥ወጣቶች እና አዋቂዎች ሞልተውባት መንፈሳዊ አገልግሎት ሲካሄድባት ማዬት እጅግ ያስደስታል። ከጅምሩ ያቋቋሟትንና እንድትስፋፋ የረዷትን የቤተክርስቲያኗ አባላትና አመራር፣ ሊቃነ ጳጳሳት፣ መነኮሳት፣ ቀሳውስት፣ ዲያቆናት እና ምዕመናን በቅርብም በሩቅም ያሉትን፤ በአካል የተለይዋትን፤ በዐጸደ ነፍስ ያሉትን ሳይቀር ታመሰግናቸዋለች።</p>
      <p className='md:text-lg lg:text-xl indent-7'>በመዋቅር ደረጃ ብጹዕ አቡነ ናትናኤል የዴንቨር ኮሎራዶና አካባቢው ሀገረስብከት ሊቀ ጳጳስ አስፈላጊውን ቡራኬና መመሪያ በመስጠት ያስተዳድሯታል።
        ንግባዕኬ ሀበ ጥንቱ ነገር እንዲል መጽሀፍ ከጥንት ይህቺን ቤተክርስቲያን ያቋቋሟትና ያስፋፏት ብጹዕ አቡነ ቴዎፍሎስ የሰሜን ካሊፎርኒያ ነቫዳና አሪዞና ሀገረ ስብከት ሊቀ ጳጳስ ናቸው። ቀደም ባለው መዋቅር ያስተዳድሯትና ካህናትን ለአገልግሎት በመላክ ጭምር የረዷት ብጹዕ አቡነ ዮሴፍ የሲዳማ ጌዲኦ አማሮና ቡርጂ ልዩ ወረዳዎች ሀገረ ስብከት ሊቀ ጳጳስ ናቸው። ቤተክርስቲያኗ እነዚህን ሶስት ታላላቅ ሊቃነ ጳጳሳት የተለየ ምስጋና ትቸራቸዋለች።</p>
        <p className='md:text-lg lg:text-xl indent-7'>በተለያዩ የሃይማኖታችን በዓላት ላይ በልደት፣ በየካቲት ኪዳነ ምህረት፣ በትንሳኤና በነሃሴ ኪዳነ ምህረት በደስታ በአካል ተገኝተው ቅዳሴ በመቀደስ፣ በማህሌትና በስብከተ ወንጌል ያገለገሏትን ከዚህ ቀጥሎ ስማቸው የተዘረዘሩትን አባቶችና ወንድሞች ቤተክርስቲያኗ ከልብ ታመሰግናቸዋለች።</p>
        <Link to="./MainMessageModal" className="text-center text-xl font-bold text-blue-500 hover:text-blue-700">Read More</Link>
    </div>
  </div>
</div>
</div>

    {/* Right Message Box */}
    <div className={`${theme === 'light' ? 'bg-gray-900 text-gray-400' : 'bg-gray-100 text-gray-800'} shadow-md p-4 rounded-lg mb-4`}>
    <h2 className={`text-2xl md:text-3xl lg:text-4xl text-center text-slate-400 mb-4 p-2 ${theme === 'light' ? 'bg-gray-900' : 'bg-gray-600'} rounded-lg`}><Link to='/Mereja' className="hover:text-blue-500">Explore our Gallery</Link></h2>
    <div className="grid grid-cols gap-2">
    <Sliding />
    <p className="border p-1 text-center text-lg rounded-lg">Popular pages / በተደጋጋሚ የተጎበኙ ገፆች</p>
    <Link to="/Tselot" className={`${ theme === 'light' ? 'bg-gray-500' : 'bg-gray-500' } hover:bg-blue-400 text-slate-900 text-center text-xl font-bold py-8 px-16 rounded-lg mb-4 transition duration-300 ease-in-out transform hover:scale-110`}>Prayer Room / የፀሎት ክፍል </Link>
    <Link to="/AllEducation" className={`${ theme === 'light' ? 'bg-gray-500' : 'bg-gray-500' } hover:bg-blue-400 text-slate-900 text-center text-xl font-bold py-8 px-16 rounded-lg mb-4 transition duration-300 ease-in-out transform hover:scale-110`}>Q&A Room / የልጆች ጥያቄና መልስ ክፍል </Link>
    <Link to="/Mezmur" className={`${ theme === 'light' ? 'bg-gray-500' : 'bg-gray-500' } hover:bg-blue-400 text-slate-900 text-center text-xl font-bold py-8 px-16 rounded-lg mb-4 transition duration-300 ease-in-out transform hover:scale-110`}>Hymn Room / የመዝሙር ክፍል </Link>
    <Link to="/DonateButton" className={`${ theme === 'light' ? 'bg-gray-500' : 'bg-gray-500' } hover:bg-blue-400 text-slate-900 text-center text-xl font-bold py-8 px-16 rounded-lg mb-4 transition duration-300 ease-in-out transform hover:scale-110`}>Donation Room / የበረከት ክፍል </Link>

  </div>
  </div>
</div>
</div>

     {/* popup message */}
     <div className="container mx-auto">
        <Popup />
      </div>

   
 {/* Breaking News Box */}
<div className="container mx-auto py-4">
  <div className={`${theme === 'light' ? 'bg-gray-900 text-gray-400' : 'bg-gray-100 text-gray-800'} shadow-md p-4 rounded-lg mb-4`}>
    <h2 className="text-3xl md:text-4xl lg:text-5xl text-center bg-gray-300 p-4 text-slate-900 rounded-lg">ወቅታዊ መረጃ </h2>
    {recentPost && (
      <div>
        <h3 className="text-2xl md:3xl text-center mb-2 p-2 underline">{recentPost.title}</h3>
        <p className="text-md text-red-700 underline md:text-lg lg:text-xl text-center mb-2">በስመአብ ወወልድ ወመንፈስ ቅዱስ አሃዱ አምላክ አሜን!</p>
        <div className="indent-7 text-md md:text-lg lg:text-xl m-2">
          {recentPost.body.split('\n').map((paragraph, index) => (
            <React.Fragment key={index}>       
              <div className="indent-7 text-justify p-2">{paragraph}</div>
            </React.Fragment>
          ))}
        </div>
        <p className='text-slate-500 text-end'>Author[ፀሃፊ]: {recentPost.Author}</p>
        <p className='text-slate-500 text-end'>Date [ቀን]፡ {new Date(parseInt(recentPost.createdAt)).toLocaleDateString()}</p>
      </div>
    )}
  </div>
</div>


      {/* Hero Box */}
      <div className="container mx-auto py-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Landscaped Box 1 */}
          <div className={`${theme === 'light' ? 'bg-gray-900 text-gray-400' : 'bg-gray-100 text-gray-800'} shadow-md p-4 rounded-lg mb-4`}>
            <div className='space-y-4'>
            <h2 className={`text-xl md-text-2xl p-4 mt-2 ${theme === 'light' ? 'bg-gray-900' : 'bg-gray-600'} border-b border-r border-l rounded-lg text-slate-100 text-center`}>A Plea for Support from our Church</h2>
              <p className='indent-7 lg:text-lg border border-gray-500 p-2 rounded-lg'>May peace and blessings be upon all believers of the Ethiopian Orthodox Tewahedo Church and all members of our church, St. Mary's Church of Utah, in the name of Almighty God and the Blessed Virgin Mary.
                As we embark on this journey to serve our community and uphold the values of our faith, we humbly seek your assistance. Whether through your time and talents or through your financial contributions, any gift you provide will play a significant role in furthering the mission of our church.
               </p>
               
              <h2 className={`text-xl md-text-2xl p-4 mt-2 ${theme === 'light' ? 'bg-gray-900' : 'bg-gray-600'} border-b border-r border-l rounded-lg text-slate-100 text-center`}>ቤተክርስትያናችን እንርዳ</h2>
                <p className='lg:text-lg  indent-7 border border-gray-500 p-2 rounded-lg'>የኢትዮጵያ ኦርቶዶክስ ተዋህዶ ቤተክርስቲያን ምእመናን የሆናችሁ በሙሉ እንዲሁም የቤተክርስትያናችን የዩታ ቅድስት ማርያም ቤተክርስቲያን አባላት በሙሉ ሁሉን ቻይ በሆነው አምላክና በቅድስት ድንግል ማርያም ስም ሰላምና በረከት ከናንተ ጋር ይሁን።
                ማህበረሰባችንን ለማገልገል እና የእምነታችንን እሴት ለመጠበቅ ይህን ጉዞ ስንጀምር፣ 
                የእናንተን እርዳታ በትሕትና በመሻት ነው። ቤተክርስቲያናችንን ለመርዳት በጊዜም ሆነ በመክሊት 
                ወይንም በሀብት የሚቻል ሲሆን፣ እናንተ ከወዲሁ የምታደርጉት የትኛውም ተሳትፎ ለቤተ ክርስቲያናችንን ተልዕኮ ትልቅ በረከት ነው፥ ስለሆነም ውድ የቤተክርስትያናችን ምእመናኖች ለልጆቻችን እንዲሁም ለመጭው ትውልድ የተሻለ መንፈሳዊ ህይወትን ለማስረክብ በቅድስት ድንግል ማርያም ስም ትብብራቹህ ኣንዳይለየን ስንል እንጠይቃለን።  </p>

                <button className="hover:bg-orange-800 bg-pink-700 shadow-lg shadow-slate-900 text-white font-bold py-2 px-4 rounded mt-2">
                <a href="/DonateButton">Donate here / እዚህ ይርዱ</a>
              </button>
            </div>
          </div>

          {/* Landscaped Box 2 */}
          <div className={`${theme === 'light' ? 'bg-gray-900 text-gray-400' : 'bg-gray-100 text-gray-800'} shadow-md p-4 rounded-lg mb-4`}>
            <div>
            <p className="text-center text-lg py-2 mt-4 mb-4  bg-green-900 text-slate-400 rounded-lg">
            በቅድስት ቤተ-ክርስቲያናችን በተለየ ከሌሊት ጀምሮ ሰአታትና ማህሌት የሚቆምባቸው ቀናቶች
            </p>
            <p className="text-center text-lg py-2 mb-4 bg-green-900 text-slate-400 rounded-lg">
            Special events and holidays celebrated by St. Mary's Ethiopian Orthodox Church
            </p>
            <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 bg-gray-900 uppercase">
                  Event
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 bg-gray-800 uppercase">
                  Date
                </th>
              </tr>
            </thead>
    <tbody className="bg-white divide-y divide-gray-200">
    <tr>
        <td className="px-6 py-2 whitespace-nowrap bg-gray-400">
          <div className="text-sm text-gray-900">ቅዱስ ዩሃንስ (New Year)</div>
        </td>
        <td className="px-6 py-2 whitespace-nowrap bg-gray-700">
          <div className="text-sm text-slate-100">[መስከረም 1-Sep 11]</div>
        </td>
      </tr>
      <tr>
        <td className="px-6 py-2 whitespace-nowrap bg-gray-400">
          <div className="text-sm text-gray-900">መስቀል (Meskel)</div>
        </td>
        <td className="px-6 py-2 whitespace-nowrap bg-gray-700">
          <div className="text-sm text-slate-100">[መስከረም 17-Sep 27]</div>
        </td>
      </tr>
      <tr>
        <td className="px-6 py-2 whitespace-nowrap bg-gray-400">
          <div className="text-sm text-gray-900">ልደት(Christmas)</div>
        </td>
        <td className="px-6 py-2 whitespace-nowrap bg-gray-700">
          <div className="text-sm text-slate-100">[ታህሳስ 29-Jan 7]</div>
        </td>
      </tr>
      <tr>
        <td className="px-6 py-2 whitespace-nowrap bg-gray-400">
          <div className="text-sm text-gray-900">ጥምቀት(Ephiphany)</div>
        </td>
        <td className="px-6 py-2 whitespace-nowrap bg-gray-700">
          <div className="text-sm text-slate-100">[ጥር 11-Jan 19]</div>
        </td>
      </tr>
      <tr>
        <td className="px-6 py-2 whitespace-nowrap bg-gray-400">
          <div className="text-sm text-gray-900">የካቲት ኪዳነምህረት</div>
        </td>
        <td className="px-6 py-2 whitespace-nowrap bg-gray-700">
          <div className="text-sm text-slate-100">[የካቲት 16-Feb 24]</div>
        </td>
      </tr>
      <tr>
        <td className="px-6 py-2 whitespace-nowrap bg-gray-400">
          <div className="text-sm text-gray-900">ደብረ ዘይት Mount-Olives</div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap bg-gray-700">
          <div className="text-sm text-slate-100">It varies / ይቀያየራል</div>
        </td>
      </tr>
      <tr>
        <td className="px-6 py-2 whitespace-nowrap bg-gray-400">
          <div className="text-sm text-gray-900">ሆሳእና (Palm Sunday)</div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap bg-gray-700">
          <div className="text-sm text-slate-100">It varies / ይቀያየራል</div>
        </td>
      </tr>
      <tr>
        <td className="px-6 py-2 whitespace-nowrap bg-gray-400">
          <div className="text-sm text-gray-900">ስቅለት(good Friday)</div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap bg-gray-700">
          <div className="text-sm text-slate-100">It varies / ይቀያየራል</div>
        </td>
      </tr>
      <tr>
        <td className="px-6 py-4 whitespace-nowrap bg-gray-400">
          <div className="text-sm text-gray-900">ትንሳኤ (Easter)</div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap bg-gray-700">
          <div className="text-sm text-slate-100">It varies / ይቀያየራል</div>
        </td>
      </tr>
      <tr>
        <td className="px-6 py-2 whitespace-nowrap bg-gray-400">
          <div className="text-sm text-gray-900">የእመቤታችን እርገት</div>
        </td>
        <td className="px-6 py-2 whitespace-nowrap bg-gray-700">
          <div className="text-sm text-slate-100"> [ነሃሴ 16-Aug 22]</div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

{/* Modal  */}
<div className='flex justify-between mt-2 p-1 bg-gray-700'>
  <p className='text-center text-slate-100'>ሁሉንም ወርሃዊ የበአል ቀናቶችን ለማወቅ</p>
           <button className="text-slate-100 hover:bg-pink-800 bg-pink-700 shadow-lg shadow-slate-900 p-1 rounded-md" onClick={openModal}>እዚህ ይጫኑ ፡ Click here</button>
            <BealatModal isOpen={isModalOpen} closeModal={closeModal} />
</div>

            </div>
          </div>
        </div>
      </div>


      <div className="container mx-auto py-4">
  <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
    {/* Box 1 */}
    <div className={`${theme === 'light' ? 'bg-gray-900 text-gray-400' : 'bg-gray-100 text-gray-800'} shadow-md p-4 rounded-lg mb-4`}>
      <img src={logo1} alt="Adminstration" className="rounded-full h-20 w-20 md:w-36 md:h-36  mx-auto" />
      <div className={` ${theme === 'light' ? 'bg-gray-900' : 'bg-gray-600'}  text-slate-400 p-1 rounded-lg text-center`}>
      <h3 className="text-2xl text-center">የቤተክርስትያናችን መልእክት</h3>
      <h3 className="text-2xl text-center mb-4">Message from Our Church</h3>
      </div>
      <div className='mt-2 p-2 border border-gray-500 rounded-lg '>
      <p className='indent-7 lg:text-lg p-2 rounded-lg'>
      በጠበበው ደጅ ግቡ፤ ወደ ጥፋት የሚወስደው ደጅ ሰፊ፥ መንገዱም ትልቅ ነውና፥ ወደ እርሱም የሚገቡ ብዙዎች ናቸው፤ ወደ ሕይወት የሚወስደው ደጅ የጠበበ፥ መንገዱም የቀጠነ ነውና፥ የሚያገኙትም ጥቂቶች ናቸው። “የበግ ለምድ ለብሰው ከሚመጡባችሁ በውስጣቸው ግን ነጣቂዎች ተኵላዎች ከሆኑ ከሐሰተኞች ነቢያት ተጠንቀቁ። ከፍሬያቸው ታውቋቸዋላችሁ። ከእሾህ ወይን ከኩርንችትስ በለስ ይለቀማልን? እንዲሁ መልካም ዛፍ ሁሉ መልካም ፍሬ ያደርጋል፤ ክፉም ዛፍ ክፉ ፍሬ ያደርጋል። መልካም ዛፍ ክፉ ፍሬ ማፍራት፥ ወይም ክፉ ዛፍ መልካም ፍሬ ማፍራት አይቻለውም። መልካም ፍሬ የማያፈራ ዛፍ ሁሉ ይቆረጣል፤ ወደ እሳትም ይጣላል። ስለዚህም ከፍሬያቸው ታውቋቸዋላችሁ። በሰማያት ያለውን የአባቴን ፈቃድ የሚያደርግ እንጂ ‘ጌታ ሆይ! ጌታ ሆይ!’ የሚለኝ ሁሉ መንግሥተ ሰማያት የሚገባ አይደለም። በዚያ ቀን ብዙዎች ‘ጌታ ሆይ! ጌታ ሆይ! በስምህ ትንቢት አልተናገርንምን? በስምህስ አጋንንትን አላወጣንምን? በስምህስ ብዙ ተአምራትን አላደረግንምን?’ ይሉኛል። የዚያን ጊዜም ‘ከቶ አላወቅኋችሁም፤ እናንተ አመፀኞች፥ ከእኔ ራቁ’ ብዬ እመሰክርባቸዋለሁ።
      </p>
      <p className='text-gray-700 text-end'>የማቴዎስ ወንጌል 7 ፥ 13-23 </p>
      <p className='text-gray-700 text-end'>
      <span className='text-red-700'>ወስብሐት ለእግዚአብሔር::</span>
      </p>
      </div>
    </div>

    {/* Box 2 */}
    <div className={`${theme === 'light' ? 'bg-gray-900 text-gray-400' : 'bg-gray-100 text-gray-800'} shadow-md p-4 rounded-lg mb-4`}>
    <img src={Aba} alt="Adminstration" className="rounded-full h-20 w-20 md:w-36 md:h-36  mx-auto" />
    <div className={` ${theme === 'light' ? 'bg-gray-900' : 'bg-gray-600'}  text-slate-400 p-1 rounded-lg text-center`}>
      <h3 className="text-2xl text-center">የአባ መልእክት</h3>
      <h3 className="text-2xl text-center mb-4">Message from Aba</h3>
      </div>
      <div className='mt-2 p-2 border border-gray-500 rounded-lg '>
      <p className='font-semibold lg:text-lg'>
         <span className='text-red-700'>በ1ኛ ጴጥሮስ ምዕራፍ 5፥13</span>
        ትኤምኅክሙ ቤተክርስቲያን ኃሪት:
        ከእናንተ ጋር   ተመርጣ  በባቢሎን ያለች  ቤተክርስቲያን ሰላምታ  ታቀርባለች: 
       </p>
      <p className='indent-7 lg:text-lg'>
      ብሎ መልእክቱን የጻፈላቸው ምእመናን በገጠማቸው የክርስትና ሕይወት ምክንያት ወደ ባቢሎን ወርደው ለነበሩ ማህበረ ምእመናን ነው ፣ ስለዚህ የማህበረ ምእመናን አንድነት ቤተክርስቲያን ይባላል። ክርስቲያኖች ከእግዚአብሔር ጋር ጥሩ ግንኙነት ሲኖራቸው ፈተና ይበዛባቸዋል : <span className='text-red-700'>ቅዱስ ጴጥሮስ በምእራፍ 1፥1</span> ላይ ሲገልጻቸው በደመ ክርስቶስ የተቀደሳችሁ ከእኔ ጋር ያሉት ምእመናን ሰላምታ ያቀርባሉ ይላቸዋል : ስለዚህ ዛሬም ነገም ምእመናንን ንስሐ ገብታችሁ መንግሥተ ሰማያትን ትወርሱ ዘንድ ወደኔ ኑ እያለች ቅድስት ቤተክርስቲያን ትጣራለች የቤተክርስቲያንን ጥሪ አክብረን ሥጋውን ደመ ክርስቶስን ተቀብለን ለእግዚአብሔር መንግሥት ዝግጁ ሆነን እንድንገኝ
      እግዚአብሔር በቸርነቱ ይርዳን አሜን።
      </p>
      <p className='text-gray-700  text-end'>
      <span className='text-red-700'>ወስብሐት ለእግዚአብሔር::</span>
      </p>
     <p className='font-semibold text-end'> መልአከ ጽዮን አባ ፍሥሐ መኮንን</p>
     </div>
    </div>
  </div>
</div>
 {/* Hero Box */}
 <div className="container mx-auto py-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Landscaped Box 1 */}
          <div className={`${theme === 'light' ? 'bg-gray-900 text-gray-400' : 'bg-gray-100 text-gray-800'} shadow-md p-4 rounded-lg mb-4`}>
            <div>
            <img src={sleshi} alt="Supervisor" className="rounded-full h-20 w-20 md:w-36 md:h-36  mx-auto mb-4" />

             <div className={` ${theme === 'light' ? 'bg-gray-900' : 'bg-gray-600'}  text-slate-400 p-1 rounded-lg text-center`}>
              <h2>የቤተክርስትያናችን ሰብሳቢ መልእክት</h2>
              <h2>Message from the Chairman</h2>
              </div>

              <div className='mt-2 p-2 border border-gray-500 rounded-lg'>
              <p className="indent-7 p-2 lg:text-lg">Welcome to the Ethiopian Orthodox Tewahedo Church of Utah! We are a vibrant and inclusive community dedicated to preserving the rich traditions and spiritual heritage of the Ethiopian Orthodox Tewahedo Church. Nestled in the heart of Utah, our church serves as a beacon of faith, unity, and cultural celebration for Ethiopians and all who seek a deeper connection with God. With a warm and inviting atmosphere, we embrace individuals from all walks of life, fostering a sense of belonging and spiritual growth.</p>
              <p className="indent-7 p-2 lg:text-lg"><span className='text-red-700'>I  am </span>blessed to serve Utah St. Mary and its congregation for many years, as founding member and chairman. My commitment is based on my belief that we fulfil the will of God and do good to all people when we carry each other’s burdens. </p>             
              <p className="indent-7 p-2 lg:text-lg">The Utah St. Mary Ethiopian Orthodox Tewahedo Church has been serving as the center of weekly religious services to our communities of faith for over 15 years. Utah St. Mary is the only Orthodox church of its kind providing uninterrupted services of worship, prayers, evangelism, christening, and fellowship. </p>
              <p className="indent-7 p-2 lg:text-lg">Through all these years, we congregate each week to worship together, to learn together, and to share our lives with one another. With psalms and hymns, we sing together and praise together. We look after one another. We renew our promise to serve. <span className='text-red-700'> As the apostle Paul teaches us in Galatians, 6:10, “Do good to all people, especially to those who belong to the family of believers እንግዲያስ ጊዜ ካገኘን ዘንድ ለሰው ሁሉ ይልቁንም ለሃይማኖት ቤተ ሰዎች መልካም እናድርግ”</span>. </p>
              <p className="indent-7 p-2 lg:text-lg">We assemble and unite with the family of worshippers regularly to ensure that we are mindful of their emotional, spiritual, and other needs.
               As time passes, and as our congregation grows, our church’s apostolic services are needed more than ever before. </p>
               <p className="indent-7 p-2 lg:text-lg">I extend my genuine invitation for you to join our congregation, and to come to the great privilege of worshiping the LORD and serving your fellow humankind. 
                <span className='text-red-700'>እናንተ ደካሞች ሸክማችሁ የከበደ ሁሉ፥ ወደ እኔ ኑ፥እኔም አሳርፋችኋለሁ። Matthew 11:28</span></p>
                <p className='font-semibold text-end'>God bless you all!</p>
              <p className='font-semibold text-end'>ስለሽ ታደሰ</p>
                <p className='font-semibold text-end'>Sleshi Tadesse, Chairman</p>
              </div>
            </div>
          </div>

          {/* Landscaped Box 2 */}
          <div className={`${theme === 'light' ? 'bg-gray-900 text-gray-400' : 'bg-gray-100 text-gray-800'} shadow-md p-4 rounded-lg mb-4`}>
            <div>
            <img src={michael} alt="Supervisor" className="rounded-full h-20 md:w-36 md:h-36 w-20 mx-auto mb-4" />
            
            <div className={` ${theme === 'light' ? 'bg-gray-900' : 'bg-gray-600'}  text-slate-400 p-1 rounded-lg text-center`}>
              <h2>የቤተክርስትያናችን ፀሃፊ መልእክት</h2>
              <h2>Message from Vice Chairman and Secretary</h2>
              </div>
             
             <div className='mt-2 p-2 border border-gray-500 rounded-lg '>
              <p className="indent-7 p-2 lg:text-lg">I welcome you to the pages of the Utah St. Mary Ethiopian Orthodox Tewahedo Church. I serve Utah St. Mary as Secretary and Vice Chair. I often extend my roles to advocacy work, public relations, and serving as a Spokesperson for the church. Integrating my academic life with spiritual growth was immensely rewarding to me personally and to my family. I am blessed to serve, for this has strengthened my emotional, physical, and intellectual well-being, as well as my sense of charity, dignity, and humility.</p>
              <p className="indent-7 p-2 lg:text-lg">Utah St. Mary is our precious place for worship, the place to seek peace and tranquility. Despite the many challenges we face, we continue to affirm our commitment to Utah St. Mary. Today more than ever we support, advocate for, and defend our church. Christ teaches us not to give up meeting together, as some are in the habit of doing, but to let us encourage one another and all the more as we see the Day approaching, <span className='text-red-700'>(Hebrews 10:25, በአንዳንዶችም ዘንድ ልማድ እንደ ሆነው፥ መሰብሰባችንን አንተው እርስ በርሳችን እንመካከር እንጂ፤ ይልቁንም ቀኑ ሲቀርብ እያያችሁ አብልጣችሁ ይህን አድርጉ).</span> Utah St. Mary is our way of life; it is our way of expressing our love for God, of supporting our fellow communities, strengthening our spirituality, and our way of building and living better lives.</p>
              <p className="indent-7 p-2 lg:text-lg">Our website is built around the needs of our church and our communities. Utah St. Mary needs new members, donors, sponsors, and volunteers. We appeal to you to come together and join us in fellowship and service. Bring your families, socialize with friends and other families, and grow and learn with us. Extend your support to others in need and to the less fortunate; encourage young people to attend regular services. Remember that engagement with the church and increased religiosity leads especially young people to avoid reckless behaviors, help them to connect to more adults, and to provide them with more leadership opportunities.</p>
              <p className='font-semibold text-end'>God bless you all!</p>
              <p className='font-semibold text-end'>ሚካኤል ማሞ</p>
              <p className='font-semibold text-end'>Michael (Mike) Mamo, Ph.D, Professor, Westminster University</p>
              <p className='font-semibold text-end'>Vice Chairman and Secretary</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Home;
