import react from 'react';

const PrivacyPolicy = () => {
    return (
        <div className='container mx-auto p-4 text-sm'>
            <h1 className='text-3xl semi-bold underline mb-4'>Privacy Policy</h1>
<p>Last updated: March 20, 2024</p>
<p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
<h2>Interpretation and Definitions</h2>
<strong><h3>Interpretation</h3></strong>
<p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
<strong><h3>Definitions</h3></strong>
<p>For the purposes of this Privacy Policy:</p>
<ul>
<li>
<p><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p>
</li>
<li>
<p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
</li>
<li>
<p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to St. Mary EOTC Utah, 688 North main street, Layton, UT 84041, USA.</p>
</li>
<li>
<p><strong>Cookies</strong> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</p>
</li>
<li>
<p><strong>Country</strong> refers to: Utah,  United States</p>
</li>
<li>
<p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
</li>
<li>
<p><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</p>
</li>
<li>
<p><strong>Service</strong> refers to the Website.</p>
</li>
<li>
<p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
</li>
<li>
<p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
</li>
<li>
<p><strong>Website</strong> refers to St Mary's EOTC in Utah, accessible from <a href="https://www.stmaryutah-eotc.org" rel="external nofollow noopener" target="_blank">https://www.stmaryutah-eotc.org</a></p>
</li>
<li>
<p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
</li>
</ul>
<strong><h3>Use of Your Personal Data</h3></strong>
<p>Information Collection: We may collect personal information such as names, email addresses, and phone numbers when voluntarily submitted by our website visitors. This information is used solely for communication purposes and will not be shared with third parties without consent. </p>
<p><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</p>
<strong><h3>Security of Your Personal Data</h3></strong>
<p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
<strong><h2>Links to Other Websites</h2></strong>
<p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
<p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
<strong><h2>Changes to this Privacy Policy</h2></strong>
<p>We may update Our Privacy Policy from time to time.</p>
<p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
<strong><h2>Contact Us</h2></strong>
<p>If you have any questions about this Privacy Policy,</p>
<ul>
<li>please feel free to contact us by email at: <strong><a href="mailto:<stmaryueotc@gmail.com>">stmaryueotc@gmail.com</a></strong></li>
</ul>
        </div>
    );
};  

export default PrivacyPolicy;