import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_WAKE_SERVICE_REQUEST } from '../utils/mutations'; // Import your mutation query
import DOMPurify from 'dompurify'; // Import DOMPurify
import { useTheme } from '../components/ThemeContext';

import crossIcon from '../images/crossIcon.png';

const WakeServiceRequestForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    baptismalName: '',
    dateOfServiceRequested: '',
    dateOfService: '',
    filledBy: '',
    address: '',
    phoneNumber: '',
    date: '',
  });

  const [createWakeServiceRequest, {  error }] = useMutation(CREATE_WAKE_SERVICE_REQUEST);
  const [successMessage, setSuccessMessage] = useState('');
  const { theme } = useTheme();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: sanitizeInput(value) });
  };

  const sanitizeInput = (input) => {
    return DOMPurify.sanitize(input);
  };

   const formatPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await createWakeServiceRequest({
        variables: {
          input: formData,
        },
      });
      console.log('Wake Service request created:', data);
  
      // Send email
      const emailResponse = await sendEmail(
        formData.SenderEmail,
        'stmaryueotc@gmail.com',
        'Wake Serice Request',
        formData
      );
      setSuccessMessage('the requested service sent successfully / የጠየቃችሁት አገልግሎት ተልኳል!');
  
      if (emailResponse.success) {
        console.log('Email sent successfully:', emailResponse.message);
      } else {
        console.error('Failed to send email:', emailResponse.message);
      }
  
      // Optionally, you can reset the form state here
    } catch (error) {
      console.error('Error creating baptismal certification request:', error);
    }
  };
  
  // Function to send email
  const sendEmail = async (senderEmail, recipientEmail, subject, formData) => {
    try {
      const body = `
         ${formData.fullName} የፍትሃት አገልግሎት ጠይቋል:

        ሙሉ ስም:-- ${formData.fullName},
        ክርስትና ስም:-- ${formData.baptismalName},

        አገልግሎት የተጠየቀበት ቀን:--${formData.dateOfServiceRequested},
        የአገልግሎት ቀን:-- ${formData.dateOfService},

        ፎርሙን የሞላው ሰው:--${formData.filledBy},
        አድራሻ :--${formData.address},
        ስልክ ቁጥር:-- ${formData.phoneNumber},
        
        ፎርሙ የተሞላበት ቀን:--${formData.date},
        `;
        const response = await fetch('/send-email', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ senderEmail, recipientEmail, subject, body }),
          });
          return await response.json();
        } catch (error) {
          console.error('Error sending email:', error);
          return { success: false, message: 'Failed to send email' };
        }
      };



  return (
    <div className={`bg-gradient-to-b ${theme === 'light' ? 'from-blue-100 to-blue-300' : 'from-gray-800 to-gray-900'} text-${theme === 'light' ? 'slate-900' : 'white'} w-full overflow-hidden py-8 px-2`}>
    <img src = {crossIcon} alt = "crossIcon" className = "w-20 h-20 mx-auto" />
    <div className="max-w-md mx-auto m-8 border b border-slate-200 rounded-lg p-4">
      <h2 className='text-2xl text-center font-semibold mb-4 bg-gray-900 text-slate-300 p-4 rounded-lg'>Wake Service Request Form / የፍትሃት አገልግሎት መጠየቂያ ቅፅ</h2>
      <form onSubmit={handleSubmit}>
        <label className='font-semibold'>
          Full Name: ሙሉ ስም
          <input type="text" name="fullName" value={formData.fullName} onChange={handleChange} required 
          className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"/>
        </label>
        <label className='font-semibold'>
          Baptismal Name: ክርስትና ስም
          <input type="text" name="baptismalName" value={formData.baptismalName} onChange={handleChange} required 
          className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"/>
        </label>
        <label className='font-semibold'>
          Date of Service Requested: የተጠየቀበት ቀን 
          <input type="date" name="dateOfServiceRequested" value={formData.dateOfServiceRequested} onChange={handleChange} required 
          className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"/>
        </label><br></br>
        <label className='font-semibold'>
          Date of Service: የአገልግሎት ቀን 
          <input type="date" name="dateOfService" value={formData.dateOfService} onChange={handleChange} required 
          className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"/>
        </label><br></br>
        <label className='font-semibold'>
          Address: አድራሻ
          <input type="text" name="address" value={formData.address} onChange={handleChange} required 
          className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"/>
        </label>
        
        <label className='font-semibold'>
          Filled By: ፎርሙን የሞላው ሰው
          <input type="text" name="filledBy" value={formData.filledBy} onChange={handleChange} required 
          className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"/>
        </label>
        <label className='font-semibold'>
          Phone Number: ስልክ ቁጥር
          <input type="tel" name="phoneNumber"  value={formatPhoneNumber(formData.phoneNumber)} maxLength={12} onChange={handleChange} required 
          className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"/>
        </label>
        <label className='font-semibold'>
          Date: ፎርሙ የተሞላበት ቀን
          <input type="date" name="date" value={formData.date} onChange={handleChange} required 
          className="w-full px-3 py-2 mb-4 border border-gray-300 rounded focus:outline-none focus:border-blue-500"/>
        </label>
        {successMessage && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
              {successMessage}
            </div>
          )}
        <button 
          type="submit" 
          className="w-full bg-violet-700 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4"
          onClick={(e) => {
            e.preventDefault(); // Prevent the default form submission behavior
            handleSubmit(e); // Pass the event object to handleSubmit
            setFormData({
              fullName: '',
              baptismalName: '',
              dateOfServiceRequested: '',
              dateOfService: '',
              filledBy: '',
              address: '',
              phoneNumber: '',
              date: '',
            });
          }
        }
      >
        Submit
      </button>
      </form>
      {error && <p>Error: {error.message}</p>}
    </div>
    </div>
  );
};

export default WakeServiceRequestForm;
        
