import React, { useState, useEffect } from 'react';

const Popup = () => {
  const [isActive, setIsActive] = useState(true);

  // Determine greeting based on current time
  const getGreeting = () => {
    const currentTime = new Date().getHours();
    if (currentTime < 10) return 'Good morning ፣ እንደምን አደሩ';
    if (currentTime < 18) return 'Good afternoon ፣ እንደምን ዋሉ';
    return 'Good evening ፣ እንደምን አመሹ';
  };

  // Toggle slider
  const toggleSlider = () => {
    setIsActive(!isActive);
  };

  // Automatically slide up after 10 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsActive(false);
    }, 10000); // Slide up after 10 seconds
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`fixed bottom-0 left-0 right-0 z-50 transition-transform duration-500 ease-in-out ${isActive ? 'translate-y-0' : 'translate-y-full'}`}>
      <div className="bg-gray-900 text-white p-4">
        <div className="flex justify-between items-center">
          <h3>{getGreeting()}:</h3>
          <button onClick={toggleSlider} className="focus:outline-none">
            <svg className="w-6 h-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M19 9.4L17.6 8 12 13.6 6.4 8 5 9.4l7 7 7-7z"/>
            </svg>
          </button>
        </div>
        <p className="mt-2 text-yellow-600">የአገልግሎት ሰአት የሚጀምረው ዘወትር እሁድ ከጧቱ 7፡00 ሲሆን እስከ ከሰአት 12:00 ድረስ ይቆያል</p>
        <p className="mt-2 text-yellow-600">The church service is held every Sunday from 7:00 AM to 12:00 PM</p>
      </div>
    </div>
  );
};

export default Popup;
