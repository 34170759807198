import React from "react";
import { FaFacebook, FaYoutube, FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="bg-gray-900 text-white py-8 px-8 border border-slate-700">
      <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center">

        {/* Contact Information */}
        <div className="mb-6 lg:mb-0 lg:w-1/3">
          <h2 className="text-lg lg:text-xl font-bold text-center md:text-left underline mb-4">Contact Us</h2>
          <ul className="text-sm text-center md:text-left lg:text-base text-gray-400">
            <li className="mb-2"><FaEnvelope className="inline-block mr-2 text-yellow-600" size={18} /><a href="mailto:stmaryueotc@gmail.com" className='text-gray-400 hover:text-slate-500'>stmaryueotc@gmail.com</a></li>
            <li className="mb-2"><FaPhone className="inline-block mr-2 text-yellow-600" size={18} /> +1 (385) 399-0908</li>
            <li><FaPhone className="inline-block mr-2 text-yellow-600" size={14} /> +1 (801) 928-3811</li>
            <li className="mb-2"><FaMapMarkerAlt className="inline-block mr-2 text-yellow-600" size={18} /><a href="https://www.google.com/maps/dir//688+N+Main+St+Layton,+UT+84041+USA" className='text-gray-400 hover:text-slate-500'> 688 North Main Street, Layton, UT 84041, USA</a></li>
          </ul>
        </div>

        {/* Social Media Icons */}
        <div className="lg:w-1/3 flex justify-center mb-6 lg:mb-0">
          <a href="https://www.facebook.com/UtahStMary?mibextid=uzlsIk" className="mr-4"><FaFacebook className="text-blue-500 hover:text-blue-600" size={28} /></a>
          <a href="http://www.youtube.com/@StMaryutah-eotc"><FaYoutube className="text-red-500 hover:text-red-600" size={28} /></a>
        </div>

        {/* Church Information & Service Times */}
        <div className="lg:w-1/3">
          <h2 className="text-lg lg:text-xl font-bold text-center md:text-right underline mb-4">Church Information & Service Times</h2>
          <p className="text-sm text-gray-400 text-center md:text-right">Utah, St. Mary's Ethiopian Orthodox Tewahedo Church</p>
          <p className="text-sm text-gray-400 text-center md:text-right">ዩታ ቅድስት ማርያም የኢትዮጵያ ኦርቶዶክስ ተዋህዶ ቤተክርስቲያን</p>
          <p className="text-sm text-gray-400 text-center md:text-right mb-2">የአገልግሎት ሰአት: Sunday: 7:00 AM - 12:00 PM</p>
        </div>

      </div>

      {/* Footer Bottom */}
      <hr className="border-t border-gray-800 mt-6 mb-6" />
      <div className="container mx-auto text-center text-gray-500 text-sm">
        <p>&copy; 2024 St. Mary's EOTC in Utah. All rights reserved.</p>
        <Link to="/privacyPolicy" className="text-yellow-500 hover:text-yellow-600">Privacy Policy</Link>
        <Link to="/TermsAndConditions" className="text-yellow-500 hover:text-yellow-600 ml-2">Terms of Service</Link>
        <p>Designed by <a href="https://github.com/solowon27" className="text-yellow-500 hover:text-yellow-600">QuadSite</a></p>
      </div>
    </footer>
  );
}

export default Footer;
