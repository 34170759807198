import React from "react";
import { useTheme } from './ThemeContext';
import { Link } from 'react-router-dom';
import BackButton from '../images/BackButton.png'; // Import the back button image

function MainMessageModal (){
    const { theme } = (useTheme) ;
  return (
    <div className={`bg-gradient-to-b ${theme === 'light' ? 'from-gray-800 to-gray-900' : 'from-blue-100 to-blue-300'} text-${theme === 'light' ? 'slate-100' : 'slate-800'} w-full overflow-hidden py-8 px-2`}>
    <div className="container mx-auto py-8 text-justify">
    <div className="border bg-slate-200 p-8 mb-4 space-y-4">
      
    <Link to="/" className="flex text-xl md:text-2xl items-center text-blue-500">
    <img src={BackButton} alt="Back" className="w-6 h-6 mr-2" />
    Back to Home
     </Link>

    <p className='md:text-lg lg:text-xl indent-7'>እንኳን ወደ ዩታ ቅድስት ማርያም የኢትዮጵያ ኦርቶዶክስ ተዋህዶ ቤተ ክርስቲያን ድህረ ገጽ
        በሰላም መጡ። የጌታችንና የመድኃኒታችን የኢየሱስ ክርስቶስ ፍቅርና ሰላም፤ የእናታችን የቅድስት
        ድንግል ማርያም በረከትና ረድኤት ይደርብዎ። በቆይታዎ ስለ ኢትዮጵያ ኦርቶዶክስ ተዋህዶ
        ሃይማኖት ሥርዓት እና ታሪክ ፤ እንዲሁም በሰሜን አሜሪካ ዩታ ስቴት ስለምትገኘው ቅድስት
        ማርያም ቤተ ክርስቲያን አመሰራረት ፡ ታሪክና በአሁኑ ሰአት የምታቀርበውን አገልግሎት ይረዳሉ</p>
    <p className='md:text-lg lg:text-xl indent-7'>የዚህ ድህረ ገጽ መኖር ለቤተክርስቲያናችን ትልቅ እመርታ ነው። በተለይም ውድ አባታችን
        ቆሞስ መልከአ ጽዮን አባ ፍስሀ መኮነን የደብሩ አስተዳዳሪ፣ ላዕከነ ምስጢር ዲያቆናት፣ ምዕምናን ፣
        የቤተክርስቲያኒቱ የቦርድ ሊቀመንበርና አባላት በዚህ እድገት ልትኮሩ ይገባል። </p>
    <p className='md:text-lg lg:text-xl indent-7'>ይህ ሁሉ ሊሆን የቻለው በስቴቱ ውስጥ በሚኖሩ ምዕምናን ልባዊ ጸሎት፣ ፍቅር፣ መተሳሰብና
          በተለያዩ መንገዶች በሃሳብ፣ በምክር፣ በጉልበት፣ በዕውቀት፣ በማቴሪያልና በገንዘብ በሚያደርጉት ልገሳ
          ነው።
    </p>
    <p className='md:text-lg lg:text-xl indent-7'>ምዕምናን በየሳምንቱ ዕሁድ ከሰሜናዊ ዩታ ሎጋን ከተማ ከአንድ ሰዓት ያላነሰ መንገድ
        እንዲሁም ከሶልት ሌክ ሲቲና አካባቢዋ ከተሞች ከ30-40 ደቂቃ ሌይተን ከተማ ድረስ በመንዳት
        የዕመቤታችን የኪዳነ ምህረት፣ የጌታችንና የመድኃኒታችን የኢየሱስ ክርስቶስ ረድኤትና በረከት ተካፋይ
        ናቸው። ይህን በረከት የሚያገኙት ምዕመናን ብዙ ድካም እና ፈተና አልፈው ነው በተለይም በዊንተር
        ወቅት በበረዶ ውስጥ በመንዳት።
        </p>
      <p className='md:text-lg lg:text-xl indent-7'>ዛሬ ቤተክርስቲያኗ ህዝበ ክርስቲያን፤ ህጻናት፥ወጣቶች እና አዋቂዎች ሞልተውባት መንፈሳዊ
          አገልግሎት ሲካሄድባት ማዬት እጅግ ያስደስታል። ከጅምሩ ያቋቋሟትንና እንድትስፋፋ የረዷትን
          የቤተክርስቲያኗ አባላትና አመራር፣ ሊቃነ ጳጳሳት፣ መነኮሳት፣ ቀሳውስት፣ ዲያቆናት እና ምዕመናን
          በቅርብም በሩቅም ያሉትን፤ በአካል የተለይዋትን፤ በዐጸደ ነፍስ ያሉትን ሳይቀር ታመሰግናቸዋለች።
      </p>
        <p className='md:text-lg lg:text-xl indent-7'>በመዋቅር ደረጃ ብጹዕ አቡነ ናትናኤል የዴንቨር ኮሎራዶና አካባቢው ሀገረስብከት ሊቀ ጳጳስ
       አስፈላጊውን ቡራኬና መመሪያ በመስጠት ያስተዳድሯታል።
       </p>
       <p className='md:text-lg lg:text-xl indent-7'>ንግባዕኬ ሀበ ጥንቱ ነገር እንዲል መጽሀፍ ከጥንት ይህቺን ቤተክርስቲያን ያቋቋሟትና ያስፋፏት
            ብጹዕ አቡነ ቴዎፍሎስ የሰሜን ካሊፎርኒያ ነቫዳና አሪዞና ሀገረ ስብከት ሊቀ ጳጳስ ናቸው። ቀደም ባለው
            መዋቅር ያስተዳድሯትና ካህናትን ለአገልግሎት በመላክ ጭምር የረዷት ብጹዕ አቡነ ዮሴፍ የሲዳማ
            ጌዲኦ አማሮና ቡርጂ ልዩ ወረዳዎች ሀገረ ስብከት ሊቀ ጳጳስ ናቸው። ቤተክርስቲያኗ እነዚህን ሶስት
            ታላላቅ ሊቃነ ጳጳሳት የተለየ ምስጋና ትቸራቸዋለች።
        </p>
        <p className='md:text-lg lg:text-xl indent-7'>በተለያዩ የሃይማኖታችን በዓላት ላይ በልደት፣ በየካቲት ኪዳነ ምህረት፣ በትንሳኤና በነሃሴ ኪዳነ
            ምህረት በደስታ በአካል ተገኝተው ቅዳሴ በመቀደስ፣ በማህሌትና በስብከተ ወንጌል ያገለገሏትን ከዚህ
            ቀጥሎ ስማቸው የተዘረዘሩትን አባቶችና ወንድሞች ቤተክርስቲያኗ ከልብ ታመሰግናቸዋለች።
        </p>
        </div>

        <div className="lists text-center bg-slate-200 p-8 space-y-4">
        <ul className='md:text-lg lg:text-xl list-disc list-inside text-start'>
            <li>መልአከ ፅዮን አባ ላዕከ ማርያም አስማረ ከድንግል ማርያም ቤተክርስቲያን ሎስ አንጀለስ</li>
            <li>መልአከ ብሥራት አባ ወልደየሱስ ከላስ ቬጋስ ደብረ ብሥራት ቅዱስ ገብርኤል ቤተክርስቲያን</li>
            <li>መልአከ ሣህል አባ ሃብተ ማርያም ከሳንዲያጎ ቅዱስ ገብርኤል ቤተክርስቲያን</li>
            <li>ሊቀ ካህናት ቀሲስ ተስፋዬ በላይ ከላስቬጋስ ቅዱስ ሚካኤል ቤተክርስቲያን</li>
            <li>ቀሲስ ዮናስ ገብሩ ከድንግል ማርያም ቤተክርስቲያን ሎስ አንጀለስ</li>
            <li>ዲያቆን አንዳርጋቸው ከድንግል ማርያም ቤተክርስቲያን ሎስ አንጀለስ</li>
            <li>ዲያቆን ይልቃል ከድንግል ማርያም ቤተክርስቲያን ሎስ አንጀለስ</li>
            <li>ዲያቆን አለማየሁ ባህታ ደብረ ምህረት ቅዱስ ሚካኤል ቤተክርስቲያን</li>
            <li>ዲያቆን ዘገብርኤል ደብረ ምህረት ቅዱስ ሚካኤል ቤተክርስቲያን</li>
            <li>ዘማሪ ቸርነት ሰናይ ከቅድስት ማርያም ቤተክርስቲያን ዋሺንግተን ዲሲ በተጨማሪም
            ሌሎች ስማቸውን ያልጠቀስናቸው ካህናትና ዲያቆናት እንዲሁም ከላስቬጋስና ሎስ አንጀለስ
            አብያተ ክርስቲያናት በቡድን መጥተው ያገለገሉ ዘማርያንን ጭምር ነው።
            </li>
            </ul>    
            <p className='md:text-xl lg:text-2xl indent-7 underline font-semibold'>እንዲሁም በእንግድነትና በቋሚነት ያገለገሉ አባቶችን ቤተክርስቲያናችን በታላቅ አክብሮት ታመሰግናቸዋልች።</p>
            <ul className='md:text-lg lg:text-xl list-disc list-inside text-start'>
            <li>ሊቀ ትጉሃን ታጋይ ተድላ የኢትዮጵያ የሃይማኖት ተቋማት ጉባኤ ጠቅላይ ጸሐፊ (በእንግድነት ከኢትዮጵያ)</li>  
            <li>ሊቀ መዘምራን ተክለመድኅን መንግሥቱ (በእንግድነት ከኢትዮጵያ ለአምስት ወራት)</li>
            <li>አባ ገብረ ኪዳን መንበሩ (በቋሚነት ለሁለት ዓመታት)</li>
            <li>አባ ገብረስላሴ (በቋሚነት ለሁለት ዓመታት)</li>
            <li>ዲያቆን ቃለአብ (በቋሚነት ለሦስት ዓመታት)</li>
            <li>ዲያቆን ክፍሎም (በቋሚነት ለሦስት ዓመታት)</li>
            </ul>
    </div>

    <div className="lists bg-slate-200 p-8 mt-4 space-y-4">
            <p className='md:text-lg lg:text-xl indent-7'>በአሁኑ ወቅት በማገልግል ያሉትን የተባረኩ አባትና ዲያቆናት ቤተክርስቲያናችን ትመርቃቸዋለች ፤ የአገልግሎት ጊዜያቸውን እግዚአብሔር እንዲባርክላቸው ትፀልያለች።</p>
            <p className='md:text-lg lg:text-xl indent-7'>የዩታ ቅድስት ማርያም ቤተክርስቲያን ከሌሎች የኢትዮጵያ ኦርቶዶክስ ተዋህዶ ሃይማኖት አብያተ
            ክርስቲያናት ለየት ከሚያደርጋት ገፅታ አንዱ በአራት ቋንቋዎች አገልግሎት ስለሚካሄድባት ነው።
            ቅዳሴ፣ ማህሌትና መዝሙራት በግዕዝና በአማርኛ ይቀርባሉ። በተጨማሪም የስብከተ ወንጌል
            በትግሪኛና በእንግሊዝኛ ይሰጣል።
            </p>
            <p className='md:text-lg lg:text-xl indent-7'>ገቢን በተመለከተ የቤተክርስቲያኗ ዋና የገቢ ምንጭ ምዕምናን በየሳምንቱ ከሚለግሱት ሙዳየ
            ምጽዋትና በቋሚ አባልነት ከተመዘገቡ ጥቂት ክርስቲያኖች የሚሰበሰበው ወርሃዊ መዋጮ ነው። አንድ
            ክርስቲያን በአባልነት ከተመዘገበ በኋላ በየወሩ የሚያዋጣው $20 (ሃያ) ዶላር ብቻ ነው።
            </p>  
            <p className='md:text-lg lg:text-xl indent-7'>ቤተክርስቲያናችን ከምታገኘው ውስን ገቢ የተነሳ ለአንድ አባት ብቻ ደመወዝና የቤት ኪራይ
              ትከፍላለች። ቤተክርስቲያናችን ረዳት ካህን ቄስ ወይም መነኩሴ እንድትቀጥር ለዲያቆናትም ደመወዝ
              እንድትከፍልና የጎደሉ ንዋየ ቅድሳትን እንድታሟላ፣ የአጭርና የረጅም ዕቅዶቿን በማሳካት አገልግሎቷ
              እንዲስፋፋ ብዙ ዕገዛን ትሻለች።
              </p>
                <p className='md:text-lg lg:text-xl indent-7'>ከሚቀበል ይልቅ የሚሰጥ ብፁዕ ነው ተብሎ በመፅሐፍ ቅዱስ (ሐዋርያት 20፥35) እንደተነገረው
                  እባክዎን ይህ ድህረ ገፅ የእርስዎ ፣ የቤተሰብዎና የጓደኞችዎ ኩራት ነውና በሚችሉት መጠን
                  ለቤተክርስቲያኗ ይርዱ። ወርሃዊ መዋጮ በመክፈልም አባል ይሁኑ። በቅርብም በሩቅም ያሉትን
                  ቤተሰቦችዎን፣ ዘመዶችዎንና ጓደኞችዎን ሳይቀር እንዲረዱ ያበረታቱ።
                  </p>
                  <p className="md:text-lg lg:text-xl indent-7">ብልም ዝገትም በማያጠፉት ሌቦችም ቆፍረው በማይሠርቁት ዘንድ ለእናንተ በሰማይ መዝገብ ሰብስቡ፤
                  መዝግብህ ባለበት ልብህ ደግሞ በዚያ ይሆናልና። (ማቴዎስ 6፥20-21)
                  </p>
                  <p className="md:text-lg lg:text-xl indent-7 text-end">የዕመቤታችን የቅድስት ማርያም ረድኤትና በረከት ከእርስዎና ከቤተሰብዎ ጋር ይሁን።</p>
                <p className='md:text-lg lg:text-xl indent-7 text-end'>ወስብሐት ለእግዚአብሔር!</p>
        </div>
        </div>
      </div>
  );
};

export default MainMessageModal;