import React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from './ThemeContext';
import BackButton from '../images/BackButton.png'; // Import the back button image

function Bahirehasab() {
  const { theme } = useTheme();
  return (
    <div className={`bg-gradient-to-b ${theme === 'light' ? 'from-gray-800 to-gray-900' : 'from-blue-100 to-blue-300'} text-${theme === 'light' ? 'slate-100' : 'slate-800'} w-full overflow-hidden py-8 px-2`}>
    <div className="container mx-auto py-8">      
    <Link to="/SundaySchool" className="flex text-xl md:text-2xl items-center text-blue-500">
       <img src={BackButton} alt="Back" className="w-6 h-6 mr-2" />
       Back to Sunday School
        </Link>

        <div className={`${theme === 'light' ? 'bg-gray-900 text-gray-200' : 'bg-gray-100 text-gray-800'} text-center shadow-md p-4 rounded-lg mb-4`}>
        <h1 className="text-xl md:text-2xl lg:text-3xl font-bold mb-1">Welcome to Bahirehasab (ባህረ ሐሳብ)</h1>
        <p className="text-lg p-2  text-yellow-500">Explore the ancient Orthodox knowledge</p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4">
        <a href = "https://www.ethiopianorthodox.org/amharic/abeyetbealat/baherehasab.pdf" className={`${theme === 'light' ? 'bg-gray-600 hover:bg-slate-100' : 'bg-slate-200 hover:bg-gray-900'} hover:text-blue-600 p-6 rounded-lg shadow-lg shadow-white flex flex-col items-center`}>Bahirehasab / ባህረ ሐሳብ</a>
        <a href = "https://www.ethiopianorthodox.org/amharic/abeyetbealat/qemere%20azemanat.pdf" className={`${theme === 'light' ? 'bg-gray-600 hover:bg-slate-100' : 'bg-slate-200 hover:bg-gray-900'} hover:text-blue-600 p-6 rounded-lg shadow-lg shadow-white flex flex-col items-center`}>Centuries Formula / ቀመረ አዝማናት</a>
        <a href = "https://www.ethiopianorthodox.org/amharic/holybooks/tikekelegnawuyeethiocalenda.pdf" className={`${theme === 'light' ? 'bg-gray-600 hover:bg-slate-100' : 'bg-slate-200 hover:bg-gray-900'} hover:text-blue-600 p-6 rounded-lg shadow-lg shadow-white flex flex-col items-center`}>ትክክለኛው የኢትዮጵያ ዘመን አቆጣጠር / Ethiopian Calendar</a>
      </div>
    </div>
    </div>
  );

  // Function to open the PDF file in a new window
  function openPDFWindow(pdf) {
    window.open(pdf, '_blank');
  }
}

export default Bahirehasab;
