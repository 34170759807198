import React from 'react';

const EOTCmodal = ({ isOpen, closeModal }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-70 flex items-center justify-center bg-black bg-opacity-50 overflow-auto">
      <div className="relative w-80vw max-w-3xl mx-auto my-6">
        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
          {/* Header */}
          <div className="flex items-center justify-between p-4 border-b border-solid border-gray-200 rounded-t">
            <h3 className="text-2xl font-semibold">EOTC History</h3>
            <button
              className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={closeModal}
            >
              <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
            </button>
          </div>
          {/* Body */}
          <div className="py-4 px-6">
            <div className="text-gray-700">
              <ul className="list-disc pl-5">
                <p className="my-4 text-blueGray-500 text-lg leading-relaxed">Read from different sources for more information about Ethiopian Orthodox Tewahedo Church:</p>
                <li><a className='text-blue-500 hover:underline' href="https://www.ethiopianorthodox.org/amharic/churchhistory/ethiopianhistory.html">Read from EOTC faith and order</a></li>
                <li className="mt-2"><a className='text-blue-500 hover:underline' href="https://www.dgmedhanealem.org/our-church/tewahido-history">Read from Tewahido history</a></li>
                <li className="mt-2"><a className='text-blue-500 hover:underline' href="https://en.wikipedia.org/wiki/Ethiopian_Orthodox_Tewahedo_Church">Read from Wikipedia</a></li>
                <li className="mt-2"><a className='text-blue-500 hover:underline' href="https://orthodoxwiki.org/Ethiopian_Orthodox_Tewahedo_Church">Read from Orthodox Wiki</a></li>            
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EOTCmodal;
