import React from 'react';
import { Link } from 'react-router-dom';
import { FaChurch } from 'react-icons/fa'; // Import Church icon from react-icons library
import { useTheme } from './ThemeContext';

import MezmurPDF from '../files/Tselot-zezewotr.pdf';
import SeifeMelekotPDF from '../files/seifeMelekot.pdf';
import YemihlaTselot1PDF from '../files/yemihlaTselot1.pdf';
import YemihlaTselot2PDF from '../files/yemihlaTselot2.pdf';
import WudaseMariamGeezPDF from '../files/wudaseMariamGeez.pdf';
import YezewotrTselotAmharicVerPDF from '../files/yezewoterTselotAmharicVer.pdf';
import BackButton from '../images/BackButton.png';

function Tselot() {
  const { theme } = useTheme();
  const openPDFWindow = (pdf) => {
    window.open(pdf, '_blank');
  };

  return (
    <div className={`bg-gradient-to-b ${theme === 'light' ? 'from-gray-800 to-gray-900' : 'from-blue-100 to-blue-300'} text-${theme === 'light' ? 'slate-100' : 'slate-800'} w-full overflow-hidden py-8 px-2`}>
    <div className="container mx-auto py-8">
      <div className="flex justify-between items-center mb-4">
      <Link to="/SundaySchool" className="flex items-center text-lg md:text-xl text-blue-500">
          {/* Use the back button image icon */}
          <img src={BackButton} alt="Back" className="w-6 h-6 mr-2" />
          Back to Sunday School
        </Link>
      <h1 className="text-xl md:text-2xl lg:text-3xl font-bold">ፀሎት ዘዘወትር</h1>
      </div>
      <div className={`${theme === 'light' ? 'bg-gray-900 text-gray-200' : 'bg-gray-100 text-gray-800'} text-center shadow-md p-4 rounded-lg mb-4`}>
        <h1 className="text-xl md:text-2xl lg:text-3xl font-bold mb-1">Welcome to Prayer Page (የፀሎት ገፅ)</h1>
        <p className="text-yellow-600">ስትጸልዩም እንደ ግብዞች አትሁኑ፤ ለሰው ይታዩ ዘንድ በምኩራብና በመንገድ ማዕዘን ቆመው መጸለይን ይወዳሉና፤ እውነት እላችኋለሁ፥ ዋጋቸውን ተቀብለዋል።</p>
        <p className="text-yellow-600">አንተ ግን ስትጸልይ፥ ወደ እልፍኝህ ግባ መዝጊያህንም ዘግተህ በስውር ላለው አባትህ ጸልይ፤ በስውር የሚያይ አባትህም በግልጥ ይከፍልሃል።</p>
        <p className="text-yellow-600">አሕዛብም በመናገራቸው ብዛት እንዲሰሙ ይመስላቸዋልና ስትጸልዩ እንደ እነርሱ በከንቱ አትድገሙ።</p>
          <p className="text-yellow-600 p-2">የማቴዎስ ወንጌል  6: 5-7</p>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
        <div className={`${theme === 'light' ? 'bg-gray-600 hover:bg-slate-100 text-gray-200 hover:text-blue-600' : 'bg-slate-200 hover:bg-gray-900 text-gray-800 hover:text-blue-600'} p-6 rounded-lg shadow-lg shadow-white flex flex-col items-center`}>
          <FaChurch className="hover:text-blue-600 text-4xl mb-4" />
          <button onClick={() => openPDFWindow(MezmurPDF)} className="hover:text-blue-600 hover:underline">
            <p>ፀሎት ዘዘወትር በሶስት ቋንቋ</p>
            <p>Daily Prayer in Three Language</p>
        </button>
        </div>
        <div className={`${theme === 'light' ? 'bg-gray-600 hover:bg-slate-100 text-gray-200 hover:text-blue-600' : 'bg-slate-200 hover:bg-gray-900 text-gray-800 hover:text-blue-600'} p-6 rounded-lg shadow-lg shadow-white flex flex-col items-center`}>
          <FaChurch className="hover:text-blue-600 text-4xl mb-4" />
          <button onClick={() => openPDFWindow(YezewotrTselotAmharicVerPDF)} className="hover:text-blue-600 hover:underline">
            <p>የዘወትር ፀሎት አማርኛ</p>
            <p>Daily Prayer in Amharic</p>
          </button>
        </div>
        <div className={`${theme === 'light' ? 'bg-gray-600 hover:bg-slate-100 text-gray-200 hover:text-blue-600' : 'bg-slate-200 hover:bg-gray-900 text-gray-800 hover:text-blue-600'} p-6 rounded-lg shadow-lg shadow-white flex flex-col items-center`}>
          <FaChurch className="hover:text-blue-600 text-4xl mb-4" />
          <button onClick={() => openPDFWindow(SeifeMelekotPDF)} className="hover:text-blue-600 hover:underline">
            <p>ሰይፈ መለኮት</p>
            <p>Seife Melekot</p>
            </button>
        </div>
        <div className={`${theme === 'light' ? 'bg-gray-600 hover:bg-slate-100 text-gray-200 hover:text-blue-600' : 'bg-slate-200 hover:bg-gray-900 text-gray-800 hover:text-blue-600'} p-6 rounded-lg shadow-lg shadow-white flex flex-col items-center`}>
          <FaChurch className="hover:text-blue-600 text-4xl mb-4" />
          <button onClick={() => openPDFWindow(YemihlaTselot1PDF)} className="hover:text-blue-600 hover:underline">
            <p>የምህላ ፀሎት 1</p>
            <p>swearing Prayer 1</p>
            </button>
        </div>
        <div className={`${theme === 'light' ? 'bg-gray-600 hover:bg-slate-100 text-gray-200 hover:text-blue-600' : 'bg-slate-200 hover:bg-gray-900 text-gray-800 hover:text-blue-600'} p-6 rounded-lg shadow-lg shadow-white flex flex-col items-center`}>
          <FaChurch className="hover:text-blue-600 text-4xl mb-4" />
          <button onClick={() => openPDFWindow(YemihlaTselot2PDF)} className="hover:text-blue-600 hover:underline">
          <p>የምህላ ፀሎት 2</p>
            <p>swearing Prayer 2</p>
            </button>
        </div>
        <div className={`${theme === 'light' ? 'bg-gray-600 hover:bg-slate-100 text-gray-200 hover:text-blue-600' : 'bg-slate-200 hover:bg-gray-900 text-gray-800 hover:text-blue-600'} p-6 rounded-lg shadow-lg shadow-white flex flex-col items-center`}>
          <FaChurch className="hover:text-blue-600 text-4xl mb-4" />
          <button onClick={() => openPDFWindow(WudaseMariamGeezPDF)} className="hover:text-blue-600 hover:underline">
            <p>ውዳሴ ማርያም በግእዝ</p>
            <p>Wudase Mariam in Geez</p>
            </button>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Tselot;
