import React from 'react'; 
import { useTheme } from '../components/ThemeContext';

function Forms() {
  const { theme } = useTheme();
  return (
    <div className={`bg-gradient-to-b ${theme === 'light' ? 'from-gray-800 to-gray-900' : 'from-blue-100 to-blue-300'} text-${theme === 'light' ? 'slate-900' : 'white'} w-full overflow-hidden py-8 px-2`}>
      <div className="container mx-auto py-8">      
      <div className={` ${theme === 'light' ? 'bg-gray-900' : 'bg-gray-600'} text-slate-400 rounded-lg p-4 text-center mb-2`}>
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-2">Welcome to the Forms Page</h1>
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-2">የቅጽ ገበታ</h1>         
        </div>

        <div className={` ${theme === 'light' ? 'bg-gray-900' : 'bg-gray-600'} text-green-400 rounded-lg p-4 text-center mb-2`}>
        <p className="text-lg ">If you are not a member of the church, please fill out the New Member form.</p>
        <p className='text-lg  mt-2 mb-2'>የቤተ ክርስቲያን አባል ካልሆናችሁ እባካችሁ አዲሱን የአባልነት ፎርም በመሙላት አባል ይሁኑ።</p>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 text-center">
        <button className="bg-green-500 hover:bg-blue-400 text-gray-900 font-bold py-2 px-4 rounded mt-2 mb-2 shadow-lg shadow-slate-100"><a href="/NewMember">To be a member Register Here / አባል ለመሆን እዚህ ይመዝገቡ</a></button>
        <button className="bg-green-500 hover:bg-blue-400 text-gray-900 font-bold py-2 px-4 rounded mt-2 mb-2 shadow-lg shadow-slate-100"><a href="/BaptismalCertificationForm">Baptismal Certification Request/ የክርስትና ምስክር ወረቀት መጠየቂያ</a></button>
        <button className='bg-green-500 hover:bg-blue-400 text-gray-900 font-bold py-2 px-4 rounded mt-2 mb-2 shadow-lg shadow-slate-100'><a href="/WakeServiceRequestForm">Wake Service Request/ ፀሎተ ፍትሃት መጠየቂያ</a></button>
        <button className='bg-green-500 hover:bg-blue-400 text-gray-900 font-bold py-2 px-4 rounded mt-2 mb-2 shadow-lg shadow-slate-100'><a href="/WeddingCeremonyRequestForm">Wedding Certification Request / የጋብቻ ምስክር ወረቀት መጠየቂያ</a></button>
        </div>
      </div>
      

      <div className={`container mx-auto ${theme === 'light' ? 'bg-gray-900' : 'bg-gray-600'} text-yellow-400 rounded-lg p-4 text-center mb-2`}>
        <p className="text-lg">After filling out the form, please call to confirm your submission, especially if it's urgent. </p>
          <p className='text-lg  mt-2'>የሚፈልጉትን ቅጽ ሞልተው ሲጨርሱ እንዳስፈላጊነቱ ደውለው ያሳውቁ!</p>
        </div> 
    </div>
  );
}

function FormCard({ icon, title, file }) {
  return (
    <div className="bg-white p-4 md:p-6 rounded-lg shadow-md flex flex-col items-center">
      {icon}
      <h2 className="text-blue-500 text-lg md:text-xl font-bold my-2">{title}</h2>
      <div className="flex flex-col items-center">
        <a href={file} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline mb-2">View Form</a>
        <a href={file} download className="text-red-700 hover:underline">Download Form</a>
      </div>
    </div>
  );
}

export default Forms;
