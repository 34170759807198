import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_BAPTISMAL_CERTIFICATION } from '../utils/mutations'; // Import your mutation query
import DOMPurify from 'dompurify'; // Import DOMPurify
import { useTheme } from '../components/ThemeContext';

import crossIcon from '../images/crossIcon.png';

const BaptismalCertificationForm = () => {
  const [formData, setFormData] = useState({
    nameOfPersonBaptized: '',
    baptismalName: '',
    fatherFullName: '',
    motherFullName: '',
    godFather: '',
    godMother: '',
    dateOfBirth: '',
    dateOfBaptism: '',
    nameOfBaptistPriest: '',
    placeOfBirth: '',
    SenderEmail: '',
  });

  const [createBaptismalCertification, { loading, error }] = useMutation(CREATE_BAPTISMAL_CERTIFICATION);
  const [successMessage, setSuccessMessage] = useState('');
  const { theme } = useTheme();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: sanitizeInput(value) });
  };

  const sanitizeInput = (input) => {
    return DOMPurify.sanitize(input);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await createBaptismalCertification({
        variables: {
          input: formData,
        },
      });
      console.log('Baptismal certification request created:', data);
  
      // Send email
      const emailResponse = await sendEmail(
        formData.SenderEmail,
        'stmaryueotc@gmail.com',
        'Baptismal Certification Request',
        formData
      );
      setSuccessMessage('the requested service sent successfully / የጠየቃችሁት አገልግሎት ተልኳል!');
  
      if (emailResponse.success) {
        console.log('Email sent successfully:', emailResponse.message);
      } else {
        console.error('Failed to send email:', emailResponse.message);
      }
  
      // Optionally, you can reset the form state here
    } catch (error) {
      console.error('Error creating baptismal certification request:', error);
    }
  };
  
  // Function to send email
  const sendEmail = async (senderEmail, recipientEmail, subject, formData) => {
    try {
      const body = `
        ከዚህ ቀጥሎ የተጠቀሰው ኢሜል የክርስትና ምስክር ወረቀት ጠይቀዋል: ${formData.SenderEmail}

        ክርስትና የሚነሳው ስም:--${formData.nameOfPersonBaptized}        
        የክርስትና ስም:-- ${formData.baptismalName}
        የአባት ስም:-- ${formData.fatherFullName}
        የእናት ስም:-- ${formData.motherFullName}

        ክርስትና አባት:--${formData.godFather}
        ክርስትና እናት:--${formData.godMother}

        የተወለደበት ቀን:--${formData.dateOfBirth}
        የተጠመቀበት ቀን:--${formData.dateOfBaptism}
        
        ያጠመቀው ካህን:-- ${formData.nameOfBaptistPriest}
        የተወለደበት ቦታ:--${formData.placeOfBirth}
      `;
      const response = await fetch('/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ senderEmail, recipientEmail, subject, body }),
      });
      return await response.json();
    } catch (error) {
      console.error('Error sending email:', error);
      return { success: false, message: 'Failed to send email' };
    }
  };

  return (
    <div className={`bg-gradient-to-b ${theme === 'light' ? 'from-blue-100 to-blue-300' : 'from-gray-800 to-gray-900'} text-${theme === 'light' ? 'slate-900' : 'white'} w-full overflow-hidden py-8 px-2`}>
    <img src = {crossIcon} alt = "crossIcon" className = "w-20 h-20 mx-auto" />
      <div className="max-w-md mx-auto m-8 border b border-slate-200 rounded-lg p-4">
      <h2 className='text-2xl text-center font-semibold mb-4 bg-gray-900 text-slate-300 p-4 rounded-lg'>Baptismal Certification Request Form / የክርስትና ምስክር ወረቀት መጠየቂያ ቅፅ </h2>
    <form onSubmit={handleSubmit}>
      <label className='font-semibold'> 
        Name of Person Baptized: ክርስትና የሚነሳው ስም
        <input type="text" name="nameOfPersonBaptized" value={formData.nameOfPersonBaptized} onChange={handleChange} required 
        className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"/>
      </label>
        <label className='font-semibold'>
            Baptismal Name: የክርስትና ስም
            <input type="text" name="baptismalName" value={formData.baptismalName} onChange={handleChange} required 
            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500" 
            />
        </label>
        <label className='font-semibold'>
            Father's Full Name: የአባት ስም
            <input type="text" name="fatherFullName" value={formData.fatherFullName} onChange={handleChange} required 
            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"/>
        </label>
        <label className='font-semibold'>
            Mother's Full Name: የእናት ስም
            <input type="text" name="motherFullName" value={formData.motherFullName} onChange={handleChange} required 
            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"/>
        </label>
        <label className='font-semibold'>
            God Father: ክርስትና አባት
            <input type="text" name="godFather" value={formData.godFather} onChange={handleChange} 
            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"/>
        </label>
        <label className='font-semibold'>
            God Mother: ክርስትና እናት
            <input type="text" name="godMother" value={formData.godMother} onChange={handleChange} 
            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"/>
        </label>
        <label className='font-semibold'>
            Date of Birth: የተወለደበት ቀን
            <input type="date" name="dateOfBirth" value={formData.dateOfBirth} onChange={handleChange} required 
            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"/>
        </label><br></br>
        <label className='font-semibold'>
            Date of Baptism: የተጠመቀበት ቀን
            <input type="date" name="dateOfBaptism" value={formData.dateOfBaptism} onChange={handleChange} required 
            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"/>
        </label><br></br>
        <label className='font-semibold'>
            Name of Baptist Priest: ያጠመቀው ካህን
            <input type="text" name="nameOfBaptistPriest" value={formData.nameOfBaptistPriest} onChange={handleChange} required 
            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"/>
        </label>
        <label className='font-semibold'>
            Place of Birth: የተወለደበት ቦታ / አድራሻ
            <input type="text" name="placeOfBirth" value={formData.placeOfBirth} onChange={handleChange} required 
            className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"/>
        </label>
        <label className='font-semibold'>
            Sender Email: የላኪው ኢሜል
            <input type="email" name="SenderEmail" value={formData.SenderEmail} onChange={handleChange} required 
            className="w-full px-3 py-2 mb-4 border border-gray-300 rounded focus:outline-none focus:border-blue-500"/>
        </label>

        {successMessage && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
              {successMessage}
            </div>
          )}
      <button 
          type="submit" 
          className="w-full py-2 bg-violet-700 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4"
          onClick={(e) => {
            e.preventDefault(); // Prevent the default form submission behavior
            handleSubmit(e); // Pass the event object to handleSubmit
            setFormData({
              nameOfPersonBaptized: '',
              baptismalName: '',
              fatherFullName: '',
              motherFullName: '',
              godFather: '',
              godMother: '',
              dateOfBirth: '',
              dateOfBaptism: '',
              nameOfBaptistPriest: '',
              placeOfBirth: '',
              SenderEmail: '',
            });
          }
        }
      >
        Submit
      </button>
      {error && <p>Error: {error.message}</p>}
    </form>
    </div>
    </div>
  );
};

export default BaptismalCertificationForm;
