import React from 'react';
import { Link } from 'react-router-dom';
import { FaChurch, FaSpotify, FaYoutube, FaApple, FaGlobe } from 'react-icons/fa';
import BackButton from '../images/BackButton.png';
import { useTheme } from './ThemeContext';

// Import PDF files
import MezmurPDF from '../files/Mezmur-Tiraz.pdf';
import BegeteSemani from '../files/BegeteSemani.pdf';
import KidaneMihretMezmur from '../files/KidaneMihretMezmur.pdf';

function Mezmur() {
  const { theme } = useTheme();

  const pdfFiles = [
    { pdf: MezmurPDF, name: 'Mezmur Collection', description: 'መዝሙር ጥራዝ' },
    { pdf: BegeteSemani, name: 'Begete Semani', description: 'በጌቴሴማኒ' },
    { pdf: KidaneMihretMezmur, name: 'Kidane Mihret Mezmur', description: 'የኪዳነ-ምህረት መዝሙር' },
  ];

  return (
    <div className={`bg-gradient-to-b ${theme === 'light' ? 'from-gray-800 to-gray-900' : 'from-blue-100 to-blue-300'} text-${theme === 'light' ? 'slate-100' : 'slate-800'} w-full overflow-hidden py-8 px-2`}>
      <div className="container mx-auto py-8">
        <Link to="/SundaySchool" className="flex text-xl md:text-2xl items-center text-blue-500">
          <img src={BackButton} alt="Back" className="w-6 h-6 mr-2" />
          Back to Sunday School
        </Link>

        <div className={`${theme === 'light' ? 'bg-gray-900' : 'bg-gray-100'} text-center shadow-md p-4 rounded-lg mb-4`}>
          <h1 className="text-lg md:text-2xl lg-text-3xl font-bold mb-2">Welcome to Hymn page (መዝሙር)</h1>
          <p className="text-yellow-600 mb-4">ሃሌ፡ሉያ።የእግዚአብሔርን፡ስም፡አመስግኑ፤እናንተ፡የእግዚአብሔር፡ባሪያዎች፡ሆይ፥አመስግኑት፥</p>
          <p className="text-yellow-600 mb-4">በእግዚአብሔር፡ቤት፡ውስጥ፥በአምላካችን፡ቤት፡አደባባይ፡የምትቆሙ።</p>
          <p className="text-yellow-600 mb-4">እግዚአብሔር፡ቸር፡ነውና፥እግዚአብሔርን፡አመስግኑ፤ለስሙ፡ዘምሩ፥መልካም፡ነውና፤</p>
          <p className="text-yellow-600 mb-4">መዝሙረ፡ዳዊት 134: 1-3</p>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {pdfFiles.map((file, index) => (
            <div className={`${theme === 'light' ? 'bg-gray-600 hover:bg-slate-100 text-gray-200 hover:text-blue-600' : 'bg-slate-200 hover:bg-gray-900 text-gray-800 hover:text-blue-600'} p-6 rounded-lg shadow-lg shadow-white flex flex-col items-center`}>
              <FaChurch className="hover:text-blue-600 text-4xl mb-4" />
              <a href={file.pdf} target="_blank" rel="noopener noreferrer" className="hover:text-blue-600 font-semibold hover:underline">{file.name}</a>
              <p className="text-lime-600 font-semibold">{file.description}</p>
            </div>
          ))}
        </div>

        <p className="p-2 text-center border mt-4 bg-slate-400">Note: Please provide us mezmur collection if you have any in pdf Format. Thank you!</p>
      </div>

      <div className="container mx-auto py-8">
        <h2 className="text-2xl font-bold mb-4">Audio Link collection</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          <div className={`${theme === 'light' ? 'bg-blue-400' : 'bg-blue-800'} hover:bg-blue-500 p-6 rounded-lgflex flex-col items-center`}>
            <FaSpotify className="text-white text-4xl mb-4" />
            <a href="https://open.spotify.com/playlist/3P3knTJ4czitPlXtC6I4Rk" target="_blank" rel="noopener noreferrer" className="text-white font-semibold hover:underline">Spotify collection</a>
          </div>

          <div className={`${theme === 'light' ? 'bg-gray-400' : 'bg-gray-600'} hover:bg-gray-500 p-6 rounded-lg flex flex-col items-center`}>
            <FaApple className="text-white text-4xl mb-4" /> {/* Use FaApple icon */}
            <a href="https://music.apple.com/us/artist/orthodox-tewahedo/1175296314" target="_blank" rel="noopener noreferrer" className="text-white font-semibold hover:underline">Apple Music collection</a>
          </div>

          <div className={`${theme === 'light' ? 'bg-red-400' : 'bg-red-800'} hover:bg-red-500 p-6 rounded-lg flex flex-col items-center`}>
            <FaYoutube className="text-white text-4xl mb-4" />
            <a href="https://music.youtube.com/playlist?list=OLAK5uy_mxzlzXZJkhndC1Jx7VuvcU_r4xFCrSI-U" target="_blank" rel="noopener noreferrer" className="text-white font-semibold hover:underline">Youtube collection</a>
          </div>

          <div className={`${theme === 'light' ? 'bg-green-400' : 'bg-green-800'} hover:bg-green-600 p-6 rounded-lg  flex flex-col items-center`}>
            <FaYoutube className="text-white text-4xl mb-4" />
            <a href="https://www.youtube.com/watch?v=tmnOIkCM4BU&t=1s" target="_blank" rel="noopener noreferrer" className="text-white font-semibold hover:underline">የመድሃኒያለም መዝሙራት</a>
          </div>
          
          <div className={`${theme === 'light' ? 'bg-green-400' : 'bg-green-800'} hover:bg-green-600 p-6 rounded-lg flex flex-col items-center`}>
            <FaYoutube className="text-white text-4xl mb-4" />
            <a href="https://www.youtube.com/watch?v=rAW_IenK0tk" target="_blank" rel="noopener noreferrer" className="text-white font-semibold hover:underline">የእመቤታችን መዝሙራት</a>
          </div>

          <div className={`${theme === 'light' ? 'bg-yellow-400' : 'bg-yellow-600'} hover:bg-yellow-500 p-6 rounded-lg  flex flex-col items-center`}>
            <FaYoutube className="text-white text-4xl mb-4" />
            <a href="https://www.youtube.com/watch?v=41MllJekoMU" target="_blank" rel="noopener noreferrer" className="text-white font-semibold hover:underline">የበገና መዝሙራት</a>
          </div>

          <div className={`${theme === 'light' ? 'bg-yellow-400' : 'bg-yellow-600'} hover:bg-yellow-500 p-6 rounded-lg  flex flex-col items-center`}>
            <FaYoutube className="text-white text-4xl mb-4" />
            <a href="https://www.youtube.com/watch?v=k3DU-CT4vHU" target="_blank" rel="noopener noreferrer" className="text-white font-semibold hover:underline">የቅዱስ ገብርኤል በዓል መዝሙሮች</a>
          </div>

          <div className={`${theme === 'light' ? 'bg-yellow-400' : 'bg-yellow-600'} hover:bg-yellow-500 p-6 rounded-lg  flex flex-col items-center`}>
            <FaYoutube className="text-white text-4xl mb-4" />
            <a href="https://www.youtube.com/watch?v=nz6KNwHTJok" target="_blank" rel="noopener noreferrer" className="text-white font-semibold hover:underline">የቅዱስ ሚካኤል በዓል መዝሙሮች</a>
          </div>

          <div className={`${theme === 'light' ? 'bg-pink-400' : 'bg-pink-600'} hover:bg-pink-500 p-6 rounded-lg  flex flex-col items-center`}>
            <FaYoutube className="text-white text-4xl mb-4" />
            <a href="https://eritreantewahdo.org/%e1%88%98%e1%8b%9d%e1%88%99%e1%88%ad-%e1%8a%93%e1%8b%ad-%e1%89%a4%e1%89%b5-%e1%89%b5%e1%88%9d%e1%88%85%e1%88%ad%e1%89%b2-%e1%88%b0%e1%8a%95%e1%89%a0%e1%89%b5/" 
            target="_blank" rel="noopener noreferrer" className="text-white font-semibold hover:underline">ናይ ትግርኛ መዝሙር ጥርናፈ</a>
          </div>


          <div className={`${theme === 'light' ? 'bg-orange-400' : 'bg-orange-600'} hover:bg-orange-500 p-6 rounded-lg  flex flex-col items-center`}>
            <FaYoutube className="text-white text-4xl mb-4" />
            <a href="https://www.youtube.com/watch?v=peQye1JLVXs" target="_blank" rel="noopener noreferrer" className="text-white font-semibold hover:underline">Eritrean Mezmur on Youtube</a>
          </div>

          <div className={`${theme === 'light' ? 'bg-purple-400' : 'bg-purple-600'} hover:bg-purple-500 p-6 rounded-lg  flex flex-col items-center`}>
            <FaGlobe className="text-white text-4xl mb-4" />
            <a href="https://orthodoxmezmur.com/" target="_blank" rel="noopener noreferrer" className="text-white font-semibold hover:underline">የተለያዩ ዘማሪያን መዝሙሮች ስብስብ</a>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Mezmur;
