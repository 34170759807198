import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Auth from '../utils/auth';
import Logo1 from '../images/logo1.png'; 
import Logo2 from '../images/kidanemhret.png'; 
import { FaTimes } from 'react-icons/fa';
import { useTheme } from './ThemeContext';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const { toggleTheme, theme } = useTheme();
 
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const logout = () => {
    Auth.logout();
  };

  return (
    <nav className='bg-gray-900 text-slate-100 flex justify-between items-center border-b border-slate-700 p-4 '>
      <div className='flex items-center'>
      <a href="https://www.ethiopianorthodox.org/" target="_blank" rel="noopener noreferrer">
        <img src={Logo1} alt="Logo 1" className="w-12 h-auto mr-2" />
      </a>
      <Link to="/">
        <img src={Logo2} alt="Logo 2" className="rounded-full w-10 h-auto mr-4" />
      </Link>
      <button onClick={toggleTheme} className='rounded-lg border border-gray-700 opacity-75 p-2'>{theme === 'light' ? '🌞Day-mode' : '🌙Night-mode'}</button>
      </div>
      <div className='flex items-center md:hidden gap-4'>
        <button onClick={toggleMenu} className='text-2xl'>
          &#9776;
        </button>
      </div>
      {isOpen && (
        <div className='md:hidden fixed inset-0 bg-gray-800 z-10'>
          <div className='flex flex-col items-center pt-20'>
            <button onClick={closeMenu} className='absolute top-4 right-4 text-white text-2xl'>
              <FaTimes />
            </button>
            <Link to='/' onClick={closeMenu} className='mb-4'>Home</Link>
            <Link to='/About' onClick={closeMenu} className='mb-4'>About</Link>
            <Link to='/Services' onClick={closeMenu} className='mb-4'>Services</Link>         
            <Link to='/SundaySchool' onClick={closeMenu} className='mb-4'>Sunday School</Link>
            <Link to='/Events' onClick={closeMenu} className='mb-4'>Events</Link>     
            <Link to='/DonateButton' onClick={closeMenu} className='mb-4'>Donation</Link>
            <Link to='/Forms' onClick={closeMenu} className='mb-4'>Forms</Link>
            {Auth.loggedIn() ? (
              <>
                <Link to='Education' onClick={closeMenu} className='mb-4'>Add Lesson</Link>
                <Link to='/NewPost' onClick={closeMenu} className='mb-4'>New post</Link>
                <Link to='MembersTable' onClick={closeMenu} className='mb-4'>Members</Link>
                <button onClick={() => { logout(); closeMenu(); }}>Logout</button>
              </>
            ) : (
              <Link to='/login' onClick={closeMenu} className='mb-4 text-yellow-500'>Admin?</Link>
            )}
          </div>
        </div>
      )}
      <div className='hidden md:flex items-center gap-6'>
      
        <Link to='/' className={location.pathname === '/' ? 'text-yellow-500' : ''}>Home</Link>
        {Auth.loggedIn() ? (
          <>
            <Link to='/NewPost' className={location.pathname === '/create' ? 'text-yellow-500' : ''}>New post</Link>
            <Link to='/Education' className={location.pathname === '/Education' ? 'text-yellow-500' : ''}>Add Lesson</Link>
            <Link to='MembersTable' className={location.pathname === '/MembersTable' ? 'text-yellow-500' : ''}>Members</Link>
          </>
        ) : null}
        <Link to='/About' className={location.pathname === '/About' ? 'text-yellow-500' : ''}>About</Link>
        <Link to='/Services' className={location.pathname === '/Services' ? 'text-yellow-500' : ''}>Services</Link>
        <Link to='/SundaySchool' className={location.pathname === '/SundaySchool' ? 'text-yellow-500' : ''}>Sunday School</Link>
        <Link to='/Events' className={location.pathname === '/Events' ? 'text-yellow-500' : ''}>Events</Link>
        <Link to='/DonateButton' className={location.pathname === '/DonateButton' ? 'text-yellow-500' : ''}>Donation</Link>
        <Link to='Forms' className={location.pathname === '/Forms' ? 'text-yellow-500' : ''}>Forms</Link>
        {Auth.loggedIn() ? (
          <button onClick={logout} className='text-red-700'>Logout</button>
        ) : (
          <Link to='/login' className={location.pathname === '/login' ? 'text-yellow-500' : ''}>Admin?</Link>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
