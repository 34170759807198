import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_POST } from '../utils/mutations';
import { useTheme } from './ThemeContext';

import Breaking from './Breaking';

const NewPost = ({ onPostSubmit }) => {
  const [formData, setFormData] = useState({
    title: '',
    paragraphs: [''], // Initially one paragraph
  });
   
  const { theme } = useTheme(); // Use the custom hook to get the current theme and toggle function

  const [createPost] = useMutation(CREATE_POST);

  const handleChange = (e, index) => {
    const updatedParagraphs = [...formData.paragraphs];
    updatedParagraphs[index] = e.target.value;
    setFormData({ ...formData, paragraphs: updatedParagraphs });
  };

  const handleAddParagraph = () => {
    setFormData({ ...formData, paragraphs: [...formData.paragraphs, ''] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await createPost({
        variables: {
          title: formData.title,
          body: formData.paragraphs.join('\n'), // Concatenate paragraphs with newline
          Author: formData.Author,
        },
      });
      console.log('New post created:', data.createPost);
      onPostSubmit(data.createPost); // Pass the new post data to the parent component
    } catch (error) {
      console.error('Error creating post:', error);
    }
  };

  return (
    <div className={`bg-gradient-to-b ${theme === 'light' ? 'from-gray-800 to-gray-900' : 'from-blue-100 to-blue-300'} text-${theme === 'light' ? 'slate-900' : 'white'} w-full overflow-hidden py-8 px-2`}>
      <Breaking />
    <div className="flex justify-center items-center">
      <div className="w-full max-w-3xl bg-white shadow-md rounded mb-2 px-8 pt-6">
        <h2 className="text-2xl font-bold mb-4 text-center">Create a New Post</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="title" className="block text-gray-700 font-bold mb-2">Author: የፃፈው:</label>
            <input 
            type="text"
            id="Author"
            name="Author"
            value={formData.Author}
            onChange={(e) => setFormData({ ...formData, Author: e.target.value })}
            required
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            <label htmlFor="title" className="block text-gray-700 font-bold mb-2">Title: ርእስ</label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
              required
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          {formData.paragraphs.map((paragraph, index) => (
            <div key={index} className="mb-6">
              <label htmlFor={`body${index}`} className="block text-gray-700 font-bold mb-2">Body: የሃተታ ክፍል</label>
              <textarea
                id={`body${index}`}
                name={`body${index}`}
                value={paragraph}
                onChange={(e) => handleChange(e, index)}
                required
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32 resize-none"
              ></textarea>
            </div>
          ))}
          <div className="flex justify-end">
            <button type="button" onClick={handleAddParagraph} className="text-blue-500 hover:text-blue-700 focus:outline-none">
              + Add Paragraph
            </button>
          </div>
          <button 
          type="submit" 
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4"
          onClick={(e) => {
            e.preventDefault(); // Prevent the default form submission behavior
            handleSubmit(e); // Pass the event object to handleSubmit
            setFormData({
              Author: '',
              title: '',
              paragraphs: [''],
            });
          }}
        >
          Submit
        </button>

        </form>
      </div>
    </div>
    </div>
  );
};

export default NewPost;
