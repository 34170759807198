import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_MEMBERS } from '../utils/queries';
import ScrollToTopButton from "../components/ScrollToTopButton";

function MembersTable() {
  const { loading, error, data } = useQuery(GET_MEMBERS);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="overflow-x-auto">
      <ScrollToTopButton />
      <h2 className="text-2xl text-slate-100 text-center  font-bold m-4 bg-gray-900 rounded-lg p-4 ">Members List / የአባልት ዝርዝር</h2>
      <table className="min-w-full table-auto m-2 border border-gray-900">
        <thead>
          <tr className="bg-green-200 border border-gray-900">
            <th className="px-4 py-2 border border-gray-900">ተ.ቁ</th>
            <th className="px-4 py-2 border border-gray-900">ሙሉ ስም</th>
            <th className="px-4 py-2 border border-gray-900">የክርስትና ስም</th>
            <th className="px-4 py-2 border border-gray-900">የትውልድ ቀን</th>
            <th className="px-4 py-2 border border-gray-900">የቤተስብ አባላት</th>
            <th className="px-4 py-2 border border-gray-900">አድራሻ</th>
            <th className="px-4 py-2 border border-gray-900">ከተማ</th>
            <th className="px-4 py-2 border border-gray-900">ስቴት</th>
            <th className="px-4 py-2 border border-gray-900">ዚፕ ኮድ</th>
            <th className="px-4 py-2 border border-gray-900">ኢሜል</th>
            <th className="px-4 py-2 border border-gray-900">ስልክ ቁጥር</th>
            <th className="px-4 py-2 border border-gray-900">የንስሃ አባት</th>
            <th className="px-4 py-2 border border-gray-900">ወርሃዊ ክፍያ</th>
          </tr>
        </thead>
        <tbody>
          {data.members.map((member, index) => (
            <tr key={index} className="border-b border border-gray-900">
              <td className="px-4 py-2 border border-gray-900">{index + 1}</td>
              <td className="px-4 py-2 border border-gray-900">{member.fullName}</td>
              <td className="px-4 py-2 border border-gray-900">{member.BaptismName}</td>
              <td className="px-4 py-2 border border-gray-900">{new Date(parseInt(member.dateOfBirth)).toLocaleDateString()}</td>
              <td className="px-4 py-2 border border-gray-900">{member.FamilyMembers.join(', ')}</td>
              <td className="px-4 py-2 border border-gray-900">{member.StreetAddress}</td>
              <td className="px-4 py-2 border border-gray-900">{member.City}</td>
              <td className="px-4 py-2 border border-gray-900">{member.State}</td>
              <td className="px-4 py-2 border border-gray-900">{member.ZipCode}</td>
              <td className="px-4 py-2 border border-gray-900">{member.email}</td>
              <td className="px-4 py-2 border border-gray-900">{member.phoneNumber}</td>
              <td className="px-4 py-2 border border-gray-900">{member.ConfessionFather}</td>
              <td className="px-4 py-2 border border-gray-900">${member.Donation}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default MembersTable;
