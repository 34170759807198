import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcVisa, faCcMastercard, faCcAmex, faCcDiscover } from '@fortawesome/free-brands-svg-icons';
import ScrollToTopButton from "../components/ScrollToTopButton";
import { useTheme } from '../components/ThemeContext';

import holypic from '../images/emebete.png';


const DonateButton = () => {
  const { theme } = useTheme();
  const copyPaymentLink = (profileLink) => {
    // Create a temporary input element
    const input = document.createElement('input');
    input.style.position = 'fixed';
    input.style.opacity = 0;
    input.value = profileLink;
  
    // Append the input element to the DOM
    document.body.appendChild(input);
  
    // Select the input value
    input.select();
    input.setSelectionRange(0, 99999); // For mobile devices
  
    // Copy the selected text to clipboard
    document.execCommand('copy');
  
    // Remove the temporary input element
    document.body.removeChild(input);
  
    // Inform the user that the link has been copied
    alert('Link copied to clipboard!');
  };
  
  
  return (
    <div className={`bg-gradient-to-b ${theme === 'light' ? 'from-gray-800 to-gray-900' : 'from-blue-100 to-blue-300'} text-${theme === 'light' ? 'slate-900' : 'white'} w-full overflow-hidden py-8 px-2`}>
      <ScrollToTopButton />
    <div className="container mx-auto py-8 px-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Box 1 */}
        <div className={`${theme === 'light' ? 'bg-gray-900 text-gray-200' : 'bg-gray-100 text-gray-800'} shadow-md p-4 rounded-lg mb-4`}>
          <h2 className="text-2xl p-2 font-semibold mb-4 text-center border rounded-lg ">የድንግል ማርያም በረከት ይድረሳቹህ</h2>
          <h3 className="text-xl font-semibold mb-2 text-center border rounded-lg ">ኑ በረከታቹህን ውሰዱ!</h3>

          <img src={holypic} alt="Holy Pic" className="w-full h-auto" />
        </div>
        
        {/* Box 2 */}
        <div className={`${theme === 'light' ? 'bg-gray-900 text-gray-200' : 'bg-gray-100 text-gray-800'} shadow-md p-4 rounded-lg mb-4`}>
          <h2 className="text-2xl  p-2 font-semibold mb-8 text-center border rounded-lg">ሙዳየ በረከት</h2>
          <p className="text-blue-700 text-center text-lg border rounded-lg bg-gray-200">
            አንተ ግን ምጽዋት ስታደርግ ምጽዋትህ በስውር እንዲሆን ቀኝህ የምትሠራውን ግራህ አትወቅ፤ በስውር የሚያይ አባትህም በግልጥ ይከፍልሃል።
          </p>          
          <p className="text-red-700 text-center underline">ማቴ 6:4</p>
          <p className="text-blue-700 text-center text-lg border rounded-lg font-semibold bg-gray-200"> so that your giving may be in secret. Then your Father, who sees what is done in secret, will reward you.
          </p>
          <p className="text-red-700 text-center underline">Matt 6:4</p>        
          <div className="flex flex-col bg-gray-900 p-4 rounded-lg justify-around mb-4 mt-10 ">
      {/* Zelle */}
      <div className="flex flex-col items-center  border rounded-lg mb-4">
        <h2 className="text-violet-700 text-2xl p-2 font-semibold text-center">Zelle</h2>
        <span className="text-slate-200">Not Available yet</span>
      </div>
     {/* Venmo */}
     <div className="flex flex-col items-center border rounded-lg mb-4">
  <h2 className="text-2xl text-blue-500 p-2 font-semibold mb-4 text-center bg-gray-900">Venmo</h2>
  <a href="https://venmo.com/u/Stmaryutah" target="_blank" rel="noopener noreferrer" className="text-slate-200 mb-2">@Stmaryutah</a>
  <button onClick={() => copyPaymentLink('https://venmo.com/u/Stmaryutah')} className="mb-2 mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded">Copy Link</button>
</div>

{/* CashApp */}
<div className="flex flex-col items-center border rounded-lg mb-4">
  <h2 className="text-2xl text-green-500 p-2 font-semibold mb-4 text-center">CashApp</h2>
  <a href="https://cash.app/$Stmaryutah" target="_blank" rel="noopener noreferrer" className="text-slate-200 mb-2">$Stmaryutah</a>
  <button onClick={() => copyPaymentLink('https://cash.app/$Stmaryutah')} className="mb-2 mt-2 bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-4 rounded">Copy Link</button>
</div>
    </div>
          <p className="bg-slate-900 text-slate-100  mb-4 p-2 text-center border rounded-lg"><span className="text-violet-700">በዜል </span>፣ <span className="text-blue-500">ቬሞ</span> ወይንም <span className="text-green-500">በካሽ አፕ</span> መክፈል ለምትፈልጉ ከስራቸው ያለውን አካውንት ስም በመጫን መክፈል ትችላላሁ</p>
          {/* PayPal Donate Button */}
          <form action="https://www.paypal.com/donate" method="post" target="_top">
            <input type="hidden" name="hosted_button_id" value="EKPLLWFNUH6JJ" />
            <input type="hidden" name="no_recurring" value="0" />
            <input type="hidden" name="currency_code" value="USD" />
            <button
              type="submit"
              className="w-full py-3 bg-orange-600 hover:bg-orange-700 text-white font-semibold rounded-lg shadow-lg transition duration-300"
            >
              Donate with PayPal or Cards
            </button>
          </form>
          <div className="flex items-center justify-center">
          <FontAwesomeIcon icon={faCcVisa} size="3x" className="text-blue-500 mx-2" />
          <FontAwesomeIcon icon={faCcMastercard} size="3x" className="text-red-500 mx-2" />
          <FontAwesomeIcon icon={faCcAmex} size="3x" className="text-yellow-500 mx-2" />
          <FontAwesomeIcon icon={faCcDiscover} size="3x" className="text-green-500 mx-2" />
        </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default DonateButton;
