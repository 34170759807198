import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_EDUCATION } from '../utils/mutations';
import { useTheme } from '../components/ThemeContext';

const NewEducation = () => {
  const [formData, setFormData] = useState({
    title: '',
    body: '',
    Teacher: '',
    Language: '', 
    paragraphs: [''],
    questions: [],
    answers: [],
  });

  const { theme } = useTheme();

  const [createEducation] = useMutation(CREATE_EDUCATION);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    if (name.startsWith('body')) {
      const updatedParagraphs = [...formData.paragraphs];
      updatedParagraphs[index] = value;
      setFormData(prevState => ({
        ...prevState,
        body: updatedParagraphs.join('\n'),
        paragraphs: updatedParagraphs,
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleAddParagraph = () => {
    setFormData({ ...formData, paragraphs: [...formData.paragraphs, ''] });
  };

  const handleAddQuestion = () => {
    setFormData(prevState => ({
      ...prevState,
      questions: [...prevState.questions, ''],
      answers: [...prevState.answers, ''],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await createEducation({
        variables: {
          title: formData.title,
          body: formData.paragraphs.join('\n'),
          Teacher: formData.Teacher,
          Language: formData.Language, // Include Language field in variables
          questions: formData.questions,
          answers: formData.answers,
          createdAt: new Date().toISOString(),
        },
      });
      console.log('New education created:', data.createEducation);
      // Optionally, you can add logic to handle success or navigate to another page
    } catch (error) {
      console.error('Error creating education:', error);
      // Optionally, you can add logic to display an error message to the user
    }
  };

  return (
    <div className={`bg-gradient-to-b ${theme === 'light' ? 'from-gray-800 to-gray-900' : 'from-blue-100 to-blue-300'} text-${theme === 'light' ? 'slate-900' : 'slate-400'} w-full overflow-hidden py-8 px-2`}>
      <div className="flex justify-center items-center">
        <div className="w-full max-w-3xl bg-white shadow-md rounded mb-2 px-8 pt-6">
          <h2 className="text-2xl font-bold mb-4 text-center">Create a New Lesson</h2>
          <form onSubmit={handleSubmit}>
           
            <div className="mb-4">
              <label htmlFor="Language" className="block text-gray-700 font-bold mb-2">Language:</label>
              <select
                  id="Language"
                  name="Language"
                  value={formData.Language}
                  onChange={handleChange}
                  required
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value="">Select Language</option>
                  <option value="English">English</option>
                  <option value="Amharic">Amharic</option>
                  <option value="Tigrinya">Tigrinya</option> {/* Fixed typo here */}
                </select>
            </div>
            <div className="mb-4">
              <label htmlFor="title" className="block text-gray-700 font-bold mb-2">Title:</label>
              <input 
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                required
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>

            <div className="mb-6">
              {formData.paragraphs.map((paragraph, index) => (
                <div key={index} className="mb-6">
                  <label htmlFor={`body${index}`} className="block text-gray-700 font-bold mb-2">Body: የሃተታ ክፍል</label>
                  <textarea
                    id={`body${index}`}
                    name={`body${index}`}
                    value={paragraph}
                    onChange={(e) => handleChange(e, index)}
                    required
                    className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32 resize-none"
                  ></textarea>
                </div>
              ))}
              <div className="flex justify-end">
                <button type="button" onClick={handleAddParagraph} className="text-blue-500 hover:text-blue-700 focus:outline-none">
                  + Add Paragraph
                </button>
              </div>
            </div>
            {formData.questions.map((question, index) => (
              <div key={index} className="mb-6">
                <label htmlFor={`question${index}`} className="block text-gray-700 font-bold mb-2">Question {index + 1}:</label>
                <input 
                  type="text"
                  id={`question${index}`}
                  name={`question${index}`}
                  value={question}
                  onChange={(e) => {
                    const updatedQuestions = [...formData.questions];
                    updatedQuestions[index] = e.target.value;
                    setFormData({ ...formData, questions: updatedQuestions });
                  }}
                  required
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                <label htmlFor={`answer${index}`} className="block text-gray-700 font-bold mb-2 mt-2">Answer {index + 1}:</label>
                <input 
                  type="text"
                  id={`answer${index}`}
                  name={`answer${index}`}
                  value={formData.answers[index]}
                  onChange={(e) => {
                    const updatedAnswers = [...formData.answers];
                    updatedAnswers[index] = e.target.value;
                    setFormData({ ...formData, answers: updatedAnswers });
                  }}
                  required
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            ))}
            <div className="flex justify-end">
              <button 
                type="button" 
                onClick={handleAddQuestion} 
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Add Question
              </button>
            </div>
            <div className="mb-6">
              <label htmlFor="Teacher" className="block text-gray-700 font-bold mb-2">Teacher:</label>
              <input 
                type="text"
                id="Teacher"
                name="Teacher"
                value={formData.Teacher}
                onChange={handleChange}
                required
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="flex justify-end">
              <button 
                type="submit" 
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                  setFormData({
                    title: '',
                    body: '',
                    Teacher: '',
                    Language: '',
                    paragraphs: [''],
                    questions:[],
                    answers:[],
                  });
                }}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewEducation;
