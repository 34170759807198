import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


import sliding1 from '../images/zemarian.JPG';
import sliding2 from '../images/hisanat.JPG';
import sliding3 from '../images/priest.JPG';
import sliding4 from '../images/memen.JPG';
import sliding5 from '../images/chairman.JPG';
import sliding7 from '../images/homepic.jpg';
import sliding6 from '../images/preacher.JPG';

const imageUrls = [
  sliding1,
  sliding2,
  sliding3,
  sliding4,
  sliding5,
  sliding6,
  sliding7
];

function Sliding() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Set initial state based on screen width

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Update state on window resize
    };

    window.addEventListener('resize', handleResize); // Add event listener for window resize

    return () => {
      window.removeEventListener('resize', handleResize); // Remove event listener on component unmount
    };
  }, []);

  
  

  return (
    <div className="container mx-auto p-4">
      <div className="carousel-container">
        <Carousel
          showThumbs={false}
          showStatus={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={5000}
          className="slider"
        >
          {imageUrls.map((imageUrl, index) => (
            <div key={index}>
              <img
                src={imageUrl}
                alt={`Slide ${index + 1}`}
                className="slider-image object-cover"
              />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default Sliding;

