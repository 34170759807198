import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { REGISTER_MEMBER } from '../utils/mutations';
import DOMPurify from 'dompurify';
import { useTheme } from '../components/ThemeContext';

const NewMember = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    BaptismName: '',
    dateOfBirth: '',
    FamilyMembers: [],
    StreetAddress: '',
    City: '',
    State: '',
    ZipCode: '',
    email: '',
    phoneNumber: '',
    ConfessionFather: '',
    Donation: '',
  });

  const [registerMember, { loading, error }] = useMutation(REGISTER_MEMBER);
  const [successMessage, setSuccessMessage] = useState('');
  const { theme } = useTheme();

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;
    if (name === 'phoneNumber') {
        updatedValue = formatPhoneNumber(value);
    }
    updatedValue = sanitizeInput(updatedValue);
    setFormData({ ...formData, [name]: updatedValue });
};


  const sanitizeInput = (input) => {
    return DOMPurify.sanitize(input);
  };

  const formatPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  };

  const handleAddFamilyMember = () => {
    setFormData({ ...formData, FamilyMembers: [...formData.FamilyMembers, ''] });
  };

  const handleFamilyMemberChange = (index, value) => {
    const updatedFamilyMembers = [...formData.FamilyMembers];
    updatedFamilyMembers[index] = value;
    setFormData({ ...formData, FamilyMembers: updatedFamilyMembers });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await registerMember({
        variables: {
          input: formData,
        },
      });
      setSuccessMessage('Member registered successfully / የዩታ ቅድስት ማርያም ቤ/ክ አባል ስለሆናችሁ እናመሰግናለን፣ እግዚአብሄር ይስጥልን!');
      //reseting the form
      setFormData({
        ...formData,
        fullName: '',
        BaptismName: '',
        email: '',
        phoneNumber: '',
        ConfessionFather: '',
        Donation: '',
        dateOfBirth: '',
        FamilyMembers: [],
        StreetAddress: '',
        ZipCode: '',
      }); 
    } catch (error) {
      console.error('Error registering member:', error);
    }
  };

  return (
    <div className={`bg-gradient-to-b ${theme === 'light' ? 'from-blue-100 to-blue-300' : 'from-gray-800 to-gray-900'} text-${theme === 'light' ? 'slate-900' : 'white'} w-full overflow-hidden py-8 px-2`}>
      <div className="max-w-md mx-auto m-8 border b border-slate-200 rounded-lg p-4">
        <div className='bg-gray-900 text-slate-100 p-2 rounded-lg '>
          <h2 className="text-xl md:text-2xl lg:text-3xl text-center p-2">Register to be a Member</h2>
          <h2 className="text-xl md:text-2xl lg:text-3xl text-center p-2">የቤተክርስትያናችን ልጆች ለመሆን ይመዝገቡ</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="fullName" className="block font-semibold mb-1">Full Name: ሙሉ ስም </label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="BaptismName" className="block font-semibold mb-1">Baptism Name: የክርስትና ስም</label>
            <input
              type="text"
              id="BaptismName"
              name="BaptismName"
              value={formData.BaptismName}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="dateOfBirth" className="block font-semibold mb-1">Date of Birth:የትውልድ ቀን</label>
            <input
              type="date"
              id="dateOfBirth"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          {formData.FamilyMembers.map((member, index) => (
            <div key={index} className="mb-4">
              <label htmlFor={`FamilyMember-${index}`} className="block font-semibold mb-1">Family Member {index + 1}: የቤተሰብ አባላት</label>
              <input
                type="text"
                id={`FamilyMember-${index}`}
                name={`FamilyMember-${index}`}
                value={member}
                onChange={(e) => handleFamilyMemberChange(index, e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                required
              />
            </div>
          ))}
          <div className="mb-4">
            <button
              type="button"
              onClick={handleAddFamilyMember}
              className="w-full py-2 bg-violet-700 text-white font-semibold rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600 disabled:opacity-50"
            >
              Add Family Member
            </button>
          </div>
          <div className="mb-4">
            <label htmlFor="StreetAddress" className="block font-semibold mb-1">Street Address: አድራሻ</label>
            <input
              type="text"
              id="StreetAddress"
              name="StreetAddress"
              placeholder='Ex. 1234 Main St'
              value={formData.StreetAddress}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="City" className="block font-semibold mb-1">City: ከተማ</label>
            <input
              type="text"
              id="City"
              name="City"
              placeholder='Ex. Salt Lake City'
              value={formData.City}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="State" className="block font-semibold mb-1">State: ስቴት</label>
            <input
              type="text"
              id="State"
              name="State"
              value={formData.State}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="ZipCode" className="block font-semibold mb-1">Zip Code</label>
            <input
              type="text"
              id="ZipCode"
              name="ZipCode"
              value={formData.ZipCode}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block font-semibold mb-1">Email: ኢሜል</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder='Ex. someone@example.com'
              value={formData.email}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
      <label htmlFor="phoneNumber" className="block font-semibold mb-1">
        Phone Number: ስልክ ቁጥር
      </label>
      <input
        type="text"
        id="phoneNumber"
        name="phoneNumber"
        value={formatPhoneNumber(formData.phoneNumber)} // Format displayed value
        onChange={handleChange}
        maxLength={12} // Maximum length including dividers
        className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
        required
      />
    </div>
          <div className="mb-4">
            <label htmlFor="ConfessionFather" className="block font-semibold mb-1">Confession Father: የንስሃ አባት</label>
            <input
              type="text"
              id="ConfessionFather"
              name="ConfessionFather"
              placeholder='You can type "No" if you dont have one'
              value={formData.ConfessionFather}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="Donation" className="block font-semibold mb-1">Monthly payment: ወርሃዊ ክፍያ</label>
            <input
              type="text"
              id="Donation"
              name="Donation"
              placeholder='Ex. $20'
              value={formData.Donation}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          
          {successMessage && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
              {successMessage}
            </div>
          )}
          <button
            type="submit"
            className="w-full py-2 bg-violet-700 text-white font-semibold rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600 disabled:opacity-50"
            disabled={loading}
          >
            {loading ? 'Submitting...' : 'Register'}
          </button>
        </form>
        {error && <p className="text-red-500 mt-4">Error: {error.message}</p>}
      </div>
    </div>  
  );
};

export default NewMember;
