import React from 'react';
import gallery from '../images/gallery.jpg';
import zewotrtselot from '../images/emebete.png';
import zemari from '../images/hisanat.JPG';
import holypic from '../images/holypic (3).jpg';
import emebetachin from '../images/kids.jpg';
import bahrehasab from '../images/ethiopia.jpg';
import question from '../images/question.jpg';

import ScrollToTopButton from "../components/ScrollToTopButton";
import { useTheme } from '../components/ThemeContext';

import { Link } from 'react-router-dom';

function SundaySchool() {
  const { theme } = useTheme();

    return (
      <div className={`bg-gradient-to-b ${theme === 'light' ? 'from-gray-800 to-gray-900' : 'from-blue-100 to-blue-300'} text-${theme === 'light' ? 'slate-200' : 'gray-800'} w-full overflow-hidden py-8 px-2`}>
        <ScrollToTopButton />
      <div className="container mx-auto py-8">
      <div className={`${theme === 'light' ? 'bg-gray-900 text-gray-200' : 'bg-gray-100 text-gray-800'} shadow-md p-8 text-center rounded-lg mb-4`}>
        <h1 className="text-xl md:text-2xl lg-text-3xl">ሰንበት ትምህርት ቤት / Sunday School</h1>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <Link to="/tselot" className="border rounded-lg overflow-hidden shadow-lg shadow-slate-100">
            <img src={zewotrtselot} alt="Sunday School 1" className="w-full h-64 object-cover shadow-lg shadow-slate-100" />
            <div className="p-4">
              <h2 className="text-xl text-center font-bold mb-2">Prayer / ፀሎት</h2>
            </div>
          </Link>
          <Link to="/Mezmur" className="border rounded-lg overflow-hidden shadow-lg shadow-slate-100">
            <img src={zemari} alt="Sunday School 2" className="w-full h-64 object-cover shadow-lg shadow-slate-100" />
            <div className="p-4">
              <h2 className="text-xl text-center font-bold mb-2">Hymn / መዝሙር</h2>
            </div>
          </Link>
          <Link to="/Kids" className="border rounded-lg overflow-hidden shadow-lg shadow-slate-100">
            <img src={emebetachin} alt="Sunday School 3" className="w-full h-64 object-cover shadow-lg shadow-slate-100" />
            <div className="p-4">
              <h2 className="text-xl text-center font-bold mb-2">Kids / የልጆች ትምህርት</h2>
            </div>
          </Link>
          <Link to="/Yawakiwoch" className="border rounded-lg overflow-hidden shadow-lg shadow-slate-100">
            <img src={holypic} alt="Sunday School 4" className="w-full h-64 object-cover shadow-lg shadow-slate-100" />
            <div className="p-4">
              <h2 className="text-xl text-center font-bold mb-2">Elders/ የአዋቂዎች</h2>
            </div>
          </Link>
          <Link to="/AllEducation" className='border rounded-lg overflow-hidden shadow-lg shadow-slate-100'>
            <img src={question} alt="Sunday School 4" className="w-full h-64 object-cover shadow-lg shadow-slate-100" />
            <div className="p-4">
              <h2 className="text-xl text-center font-bold mb-2">Q&A?/ጥያቄና መልስ</h2>
            </div>
          </Link>
          <Link to="/Bahirehasab" className="border rounded-lg overflow-hidden shadow-lg shadow-slate-100">
            <img src={bahrehasab} alt="Sunday School 5" className="w-full h-64 object-cover shadow-lg shadow-slate-100" />
            <div className="p-4">
              <h2 className="text-xl text-center font-bold mb-2">Calendar History / ባህረ ሃሳብ</h2>
            </div>
          </Link>
          <Link to="/Mereja" className="border rounded-lg overflow-hidden shadow-lg shadow-slate-100">
            <img src={gallery} alt="Sunday School 6" className="w-full h-64 object-cover shadow-lg shadow-slate-100" />
            <div className="p-4">
              <h2 className="text-xl text-center font-bold mb-2">Gallery /የምስል ክምችት</h2>
            </div>
          </Link>
        </div>
        </div>
      </div>
    );
  }
  
  export default SundaySchool;
  