import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_EDUCATION, GET_EDUCATION_BY_ID } from '../utils/queries';
import { useTheme } from '../components/ThemeContext';
import BackButton from '../images/BackButton.png';
import ScrollToTopButton from '../components/ScrollToTopButton';

const AllEducation = () => {
  const { theme } = useTheme();
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedLesson, setSelectedLesson] = useState('');
  const [showAnswers, setShowAnswers] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const { loading, data } = useQuery(GET_EDUCATION, {
    variables: { language: selectedLanguage },
  });
  const education = data?.AllEducation || [];

  // Query to get selected lesson by ID
  const { loading: loadingSelectedLesson, data: selectedLessonData } = useQuery(GET_EDUCATION_BY_ID, {
    variables: { id: selectedLesson }
  });
  const selectedLessonContent = selectedLessonData?.getEducationById || null;

  const handleSelectLanguage = (language) => {
    setSelectedLanguage(language);
    setSelectedLesson('');
  };

  const handleSelectLesson = (id) => {
    setSelectedLesson(id);
    if (window.innerWidth <= 768) {
      setShowSidebar(false);
      localStorage.setItem('showSidebar', false);
    }
  };

  useEffect(() => {
    const storedShowSidebar = localStorage.getItem('showSidebar');
    if (storedShowSidebar) {
      setShowSidebar(JSON.parse(storedShowSidebar));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('showSidebar', showSidebar);
  }, [showSidebar]);

  return (
    <div
      className={`bg-gradient-to-b ${
        theme === 'light' ? 'from-gray-800 to-gray-900' : 'from-blue-100 to-blue-300'
      } text-${theme === 'light' ? 'slate-900' : 'white'} w-full overflow-hidden py-8 px-2`}
    >
      <ScrollToTopButton />
      <div className="max-w-6xl mx-auto">
        <Link to="/SundaySchool" className="flex items-center text-lg md:text-xl text-blue-600">
          <img src={BackButton} alt="Back" className="w-6 h-6 mr-2" />
          Back to Sunday School
        </Link>
        <div className={`${theme === 'light' ? 'bg-gray-900 text-gray-400' : 'bg-gray-100 text-gray-800'} shadow-md p-4 rounded-lg mb-4`}>
          <h2 className="text-3xl font-bold mb-4 text-center">Welcome to Lessons with Quiz page</h2>
          <p className="text-lg text-center">Note: Computer is a preferred device for the best experience</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="md:col-span-1">
            <div className={`${theme === 'light' ? 'bg-gray-700 text-gray-400' : 'bg-gray-100 text-gray-800'} shadow-md p-4 rounded-lg mb-4`}>
              <p className="text-lg font-semibold text-center text-slate-900 bg-yellow-500 p-2 rounded-lg">Select a language</p>
              <ul className="overflow-y-auto max-h-96">
                <li
                  className={`cursor-pointer p-2 mb-2 rounded-lg ${
                    selectedLanguage === 'English' ? 'bg-sky-500 text-white' : 'hover:bg-gray-300 hover:text-gray-800'
                  }`}
                  onClick={() => handleSelectLanguage('English')}
                >
                  English
                </li>
                <li
                  className={`cursor-pointer p-2 mb-2 rounded-lg ${
                    selectedLanguage === 'Amharic' ? 'bg-sky-500 text-white' : 'hover:bg-gray-300 hover:text-gray-800'
                  }`}
                  onClick={() => handleSelectLanguage('Amharic')}
                >
                  አማርኛ
                </li>
                <li
                  className={`cursor-pointer p-2 mb-2 rounded-lg ${
                    selectedLanguage === 'Tigrinya' ? 'bg-sky-500 text-white' : 'hover:bg-gray-300 hover:text-gray-800'
                  }`}
                  onClick={() => handleSelectLanguage('Tigrinya')}
                >
                  ትግርኛ
                </li>
              </ul>
              {selectedLanguage && (
                <div>
                  <h2 className="text-xl font-semibold text-slate-900 mb-4 bg-green-500 p-2 rounded-lg">Lessons in {selectedLanguage}</h2>
                  <ul className="overflow-y-auto max-h-96">
                      {education.map((lesson) => (
                        <li
                          key={lesson.id}
                          className={`cursor-pointer p-2 mb-2 rounded-lg ${selectedLesson === lesson.id ? 'bg-sky-600 text-white' : 'hover:bg-gray-300 hover:text-gray-800'}`}
                          onClick={() => handleSelectLesson(lesson.id)}
                        >
                          {lesson.title}
                        </li>
                      ))}
                    </ul>
                </div>
              )}
            </div>
          </div>
          <div className="md:col-span-2">
            {loadingSelectedLesson && <div>Loading selected lesson...</div>}
            {selectedLessonContent && (
              <div className={`${theme === 'light' ? 'bg-gray-900 text-gray-400' : 'bg-gray-100 text-gray-800'} space-y-4 shadow-md p-4 rounded-lg mb-4`}>
                <h3 className="text-lg bg-gray-600 p-2 rounded-lg text-center text-lime-500 font-semibold mb-2">{selectedLessonContent.title}</h3>
                {selectedLessonContent.body.split('\n').map((paragraph, index) => (
                  <p key={index} className="indent-7 space-y-4">
                    {paragraph}
                  </p>
                ))}
                <div className="border border-blue-500 rounded-lg p-4 mt-4">
                  <h2 className="text-2xl font-bold mb-4 text-center text-green-600">Questions:</h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {selectedLessonContent.questions.map((question, index) => (
                      <div
                        key={index}
                        className={`${theme === 'light' ? 'bg-blue-100 text-blue-800' : 'bg-blue-300 text-blue-900'} shadow-md p-4 rounded-lg mb-4`}
                      >
                        <p className="font-bold">Question {index + 1}:</p>
                        <p>{question}</p>
                        {showAnswers && <p className="bg-yellow-200 p-2 rounded-lg mt-2">{selectedLessonContent.answers[index]}</p>}
                      </div>
                    ))}
                  </div>
                  <div className="text-center mt-4">
                    <button className="text-blue-600 hover:underline focus:outline-none" onClick={() => setShowAnswers(!showAnswers)}>
                      {showAnswers ? 'Hide Answers / መልሱን አጥፋ / መልሲ ሓብእ' : 'Show Answers / መልሱን አሳይ / መልሲ ኣርኢ'}
                    </button>
                    <p>Teacher/መምህር: {selectedLessonContent.Teacher}</p>
                    <p>Created At: {selectedLessonContent && new Date(selectedLessonContent.createdAt).toLocaleDateString()}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllEducation;
