import React from 'react';
import { Link } from 'react-router-dom';
import ScrollToTopButton from './ScrollToTopButton';
import { FaChurch } from 'react-icons/fa'; // Import Church icon from react-icons library
import { useTheme } from './ThemeContext';

import BackButton from '../images/BackButton.png';
import emebetachin from '../images/image2.jpg';
import kristos from '../images/Kirstos Berhan eyu.jpg';

// Import PDF files
import pdf1 from '../files/fidel.pdf';
import pdf2 from '../files/Tselot-zezewotr.pdf';
import pdf3 from '../files/yezewoterTselotAmharicVer.pdf';
import pdf4 from '../files/wudaseMariamGeez.pdf';
import pdf5 from '../files/book-1-lsane-geez.pdf';
import pdf6 from '../files/EMEBETACHIN-FOR-FILSETA.pdf';
import pdf7 from '../files/book-1-krstianawi-snemgbar.pdf';
import Pdf8 from '../files/book-1-yemetsaf-kidus-tinat.pdf';
import Pdf9 from '../files/book-1-meserete-haymanot.pdf';
import pdf10 from '../files/EnglishVersionForKG.pdf';
import Pdf11 from '../files/level1-3.pdf';
import Pdf12 from '../files/level4-6.pdf';
import Pdf13 from '../files/level-6.pdf';

function Kids() {  
  const { theme } = useTheme();
  // Array of PDF and button text pairs
  const pdfFiles = [
    { url: pdf1, name: 'ፊደል / Alphabet' },
    { url: pdf2, name: 'የዘወትር ፀሎት 3 ቋንቋ / Daily Prayer 3 Lng' },
    { url: pdf3, name: 'የአማርኛ ዘወትር ፀሎት / Daily Prayer in Amharic' },
    { url: pdf4, name: 'ውዳሴ ማርያም በግእዝ / Wudase Mariam Geez' },
    { url: pdf7, name: 'ክርስቲያናዊ ስነ-ምግባር / Christian ethics'},
    { url: Pdf8, name: 'የመፅሃፍ-ቅዱስ ጥናት / Bible Study'},
    { url: Pdf9, name: 'መሰረተ-ሃይማኖት / Foundamnetal of Faith'},
    { url: pdf5, name: 'ልሳነ ግእዝ / Geez' },
    { url: pdf6, name: 'ነገረ ማርያም ' },
    { url: pdf10, name: 'Bible lesson English Version (KG-Pre KG)'},
    { url: Pdf11, name: 'Bible lesson English Version (Grade 1-3)'},
    { url: Pdf12, name: 'Bible lesson English Version (Grade 4-6)'},
    { url: Pdf13, name: 'Bible lesson English Version (Above Grade 6)'},
  ];

  // Function to open the PDF file in a new window
  const openPDFWindow = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className={`bg-gradient-to-b ${theme === 'light' ? 'from-gray-800 to-gray-900' : 'from-blue-100 to-blue-300'} text-${theme === 'light' ? 'slate-100' : 'slate-800'} w-full overflow-hidden py-8 px-2`}>
      <ScrollToTopButton />
      <div className="container mx-auto py-8">
        <div className="flex justify-between items-center mb-4">
          <Link to="/SundaySchool" className="flex text-xl md:text-2xl items-center text-blue-500">
            {/* Use the back button image icon */}
            <img src={BackButton} alt="Back" className="w-6 h-6 mr-2" />
            Back to Sunday School
          </Link>
        </div>

        <div className={`${theme === 'light' ? 'bg-gray-900 text-gray-200' : 'bg-gray-100 text-gray-800'} text-center shadow-md p-4 rounded-lg mb-4`}>
        <h1 className="text-xl md:text-2xl lg:text-3xl font-bold mb-1">Welcome to Kids page (የልጆች ክፍል)</h1>
        <p className="text-yellow-600 p-2"> ነገር ግን ኢየሱስ፡ ሕፃናትን ተዉአቸው፥ ወደ እኔም ይመጡ ዘንድ አትከልክሉአቸው፤ መንግሥተ ሰማያት እንደነዚህ ላሉ ናትና አለ፤</p>
        <p className="text-yellow-600">የማቴዎስ ወንጌል 19፥14 </p>
      </div>

        <div className='imageContainer flex justify-evenly bg-slate-300 shadow-lg shadow-slate-100 p-2 rounded-lg mb-6'>
  <img src={emebetachin} alt="emebetachin" className="w-40 h-auto" />
  <img src={kristos} alt="kristos" className="w-40 h-auto" />
</div>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
          {pdfFiles.map((file, index) => (
            <div key={index} className={`${theme === 'light' ? 'bg-gray-600 hover:bg-slate-100 text-gray-200 hover:text-blue-600' : 'bg-slate-200 hover:bg-gray-900 text-gray-800 hover:text-blue-600'} p-6 rounded-lg shadow-lg shadow-slate-100 flex flex-col items-center`}>
              <FaChurch className="hover:text-blue-600 text-4xl mb-4" />
              <button onClick={() => openPDFWindow(file.url)} className="hover:text-blue-600 font-semibold hover:underline">{file.name}</button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Kids;
