import React from 'react';

const Modal = ({ isOpen, closeModal, service }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
      <div className="relative w-auto max-w-md mx-auto my-6 bg-white rounded-lg shadow-lg">
        <div className="flex items-center justify-between p-4 border-b border-solid border-gray-200 rounded-t">
          <h3 id="modal-title" className="text-2xl font-semibold text-blue-500">EOTC Holidays in a Month</h3>
          <button
            className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
            onClick={closeModal}
          >
            <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
          </button>
        </div>
        <div className="p-2">
          <p className="my-4 text-blue-500 text-lg leading-relaxed">
            የኢትዮጵያ ኦርቶዶክስ ተዋሕዶ ቤተ ክርስቲያን በወር ውስጥ ያሉትን እያንዳንዱን ቀናት በጌታችን፣ በእመቤታችን፣ በመላእክትና በቅዱሳን ሰይማቸዋለች። እነዚህ ቀናት በየወሩ ታስበው ቢውሉም በዓመት አንድ ወይም ሁለት ጊዜ ታቦተ ሕጉ ወጥቶ ይከበራል።
          </p>
          <div className="p-4 overflow-y-auto max-h-60">
          <ul className="list-disc pl-5 bg-slate-200 text-pink-800">
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፩/1= ልደታ፣ ራጉኤል፣ ኤልያስ </li>
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፪/2= ታዴዎስ ሐዋርያ፣ ኢዮብ ጻድቅ  </li>
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፫/3= በዓታ ማርያም፣ ዜና ማርቆስ፣ ነአኩቶ ለአብ  </li>
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፬/4= ዮሐንስ ወልደ ነጎድጓድ </li>
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፭/5= ጴጥሮስ ወጳውሎስ፣ አቡነ ገብረ መንፈስ  </li>
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፮/6= ኢየሱስ፣ ቁስቋም፣ አርሴማ ቅድስት  </li>
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፯/7= ሥላሴ፣ ፊሊሞን፣ አብላንዮስ </li>
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፰/8= ማቴዎስ፣ ዮልያኖስ፣ አባ ኪሮስ  </li>
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፱/9= ቶማስ ሐዋርያ፣ እንድርያስ ሐዋርያ፣ አውሳብዮስ፣ አርባ ሰማዕታት  </li>
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፲/10= በዓለ መስቀሉ ለእግዚእነ  </li>
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፲፩/11= ሃና ወኢያቄም፣ ቅዱስ ፋሲለደስ ሰማዕት </li>
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፲፪/12= ቅዱስ ሚካኤል፣ ክርስቶስ ሠምራ </li>
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፲፫/13= እግዚአብሔር አብ፣ ቅዱስ ሩፋኤል ሊቀ መላእክት  </li>
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፲፬/14= አባ አረጋዊ፣ አባ ገብረ ክርስቶስ፣ ድምጥያኖስ ሰማዕት</li>
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፲፭/15= ቂርቆስና ኢየሉጣ፣ ስልፋኮስ </li>
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፲፮/16= ኪዳነ ምሕረት፣ ሚካኤል ጳጳስ  </li>
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፲፯/17= ቅዱስ እስጢፋኖስ፣ ሉቃስ ዘዓምደ ብርሃን </li>
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፲፰/18= ፊልጶስ ሐዋርያ፣ ኤስድሮስ ሰማዕት፣ ኤዎስጣጤዎስ ሰማዕት </li>
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፲፱/19= ቅዱስ ገብርኤል፣ አርቃዲዎስ  </li>
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፳/20= ጽንሰታ ለማርያም፣ ነቢዩ ኤልሳ፣ ሐጌ ነቢይ፣ አባ ሰላማ መተርጉም </li>
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፳፩/21= በዓለ እግዝእትነ ማርያም </li>
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፳፪/22= ቅዱስ ዑራኤል፣ ያዕቆብ ምሥራቃዊ፣ ደቅስዮስ  </li>
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፳፫/23= ቅዱስ ጊዮርጊስ፣ ለጊኖስ ሰማዕት  </li>
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፳፬/24= አቡነ ተክለ ሃይማኖት </li>
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፳፭/25= መርቆሬዎስ፣ አኒፍኖስ </li>
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፳፮/26= ሆሴዕ ነቢይ፣ ሳዶቅ ሰማዕት </li>
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፳፯/27= መድኃኔዓለም፣ ሕዝቅያስ ነቢይ፣ አባ ዮሐንስ </li>
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፳፰/28= አማኑኤል </li>
          <li className="my-4 text-blueGray-500 text-lg leading-relaxed">፳፱/29= በአለ እግዚአብሔር </li>
          </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;