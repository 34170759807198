import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import BackButton from '../images/BackButton.png';
import { useTheme } from './ThemeContext';


function Yawakiwoch() {
  const { theme } = useTheme();

  return (
    <div className={`bg-gradient-to-b ${theme === 'light' ? 'from-gray-800 to-gray-900' : 'from-blue-100 to-blue-300'} text-${theme === 'light' ? 'slate-100' : 'slate-800'} w-full overflow-hidden py-8 px-2`}>
      <div className="container mx-auto py-8">
        <Link to="/SundaySchool" className="flex items-center text-xl md:text-2xl text-blue-500">
          <img src={BackButton} alt="Back" className="w-6 h-6 mr-2" />
          Back to Sunday School
        </Link>

        <div className={`${theme === 'light' ? 'bg-gray-900 text-gray-200' : 'bg-gray-100 text-gray-800'} text-center shadow-md p-4 rounded-lg mb-4`}>
        <h1 className="text-4xl font-bold mb-8">የአዋቂዎች ክፍል</h1>
        <p className="text-yellow-600">የጭንቀት ቀን ሳይመጣ በጕብዝናህ ወራት ፈጣሪህን አስብ፤ ደስ አያሰኙም የምትላቸውም ዓመታት ሳይደርሱ፤</p>
        <p className="text-yellow-600 text-center">መጽሐፈ መክብብ 12፥1 </p>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 text-center">
          <a href="https://www.ethiopianorthodox.org/amharic/yeqolotbet/addiskidantitle/addiskidan.html" target="_blank" rel="noopener noreferrer" className={`${theme === 'light' ? 'bg-gray-600 hover:bg-slate-100' : 'bg-slate-200 hover:bg-gray-900 '} hover:text-blue-600 text-lg font-bold py-10 px-4 rounded shadow-lg shadow-slate-100`}>
            የሃዲስ ኪዳን መፅሃፍት በግዕዝና በአማርኛ
            <FaExternalLinkAlt />
          </a>
          <a href="https://www.ethiopianorthodox.org/amharic/holybooks/haimanote/abewu.html" target="_blank" rel="noopener noreferrer" className={`${theme === 'light' ? 'bg-gray-600 hover:bg-slate-100' : 'bg-slate-200 hover:bg-gray-900 '} hover:text-blue-600 text-lg font-bold py-10 px-4 rounded shadow-lg shadow-slate-100`}>
            ሃይማኖተ አበው <FaExternalLinkAlt />
          </a>
          <a href="https://www.ethiopianorthodox.org/amharic/holybooks/wedasie%20mariam.pdf" target="_blank" rel="noopener noreferrer" className={`${theme === 'light' ? 'bg-gray-600 hover:bg-slate-100' : 'bg-slate-200 hover:bg-gray-900 '} hover:text-blue-600 text-lg font-bold py-10 px-4 rounded shadow-lg shadow-slate-100`}>
            ውዳሴ ማርያም አንድምታ
            <FaExternalLinkAlt />
          </a>
          <a href="https://www.ethiopianorthodox.org/amharic/holybooks/metsehafearganon.pdf" target="_blank" rel="noopener noreferrer" className={`${theme === 'light' ? 'bg-gray-600 hover:bg-slate-100' : 'bg-slate-200 hover:bg-gray-900 '} hover:text-blue-600 text-lg font-bold py-10 px-4 rounded shadow-lg shadow-slate-100`}>
            መፅሃፈ አርጋኖን
            <FaExternalLinkAlt />
          </a>
          <a href="https://www.ethiopianorthodox.org/amharic/holybooks/metsehafesenkesar.html" target="_blank" rel="noopener noreferrer" className={`${theme === 'light' ? 'bg-gray-600 hover:bg-slate-100' : 'bg-slate-200 hover:bg-gray-900 '} hover:text-blue-600  text-lg font-bold py-10 px-4 rounded shadow-lg shadow-slate-100`}>
            መፅሃፈ ስንከሳር
            <FaExternalLinkAlt />
            </a>
            <a href="https://www.ethiopianorthodox.org/amharic/tiyakenamelse/tiyakenmelse.html" target="_blank" rel="noopener noreferrer" className={`${theme === 'light' ? 'bg-gray-600 hover:bg-slate-100' : 'bg-slate-200 hover:bg-gray-900 '} hover:text-blue-600  text-lg font-bold py-10 px-4 rounded shadow-lg shadow-slate-100`}>
              ተደጋግመው የሚጠየቁ ጥያቄዎችና መልሶቻቸው
              <FaExternalLinkAlt />
            </a>
            <a href="https://zetewahedo.com/" target="_blank" rel="noopener noreferrer" className={`${theme === 'light' ? 'bg-gray-600 hover:bg-slate-100' : 'bg-slate-200 hover:bg-gray-900 '} hover:text-blue-600  text-lg font-bold py-10 px-4 rounded shadow-lg shadow-slate-100`}>
            ተጨማሪ ትምህርቶች ለማግኘት እባኮትን ይጫኑ፡፡
            <FaExternalLinkAlt />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Yawakiwoch;
