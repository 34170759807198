import { gql } from '@apollo/client';

export const LOGIN_USER = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        _id
        username
      }
    }
  }
`;

export const ADD_USER = gql`
  mutation addUser($username: String!, $email: String!, $password: String!) {
    addUser(username: $username, email: $email, password: $password) {
      token
      user {
        _id
        username
      }
    }
  }
`;

export const CREATE_POST = gql`
  mutation createPost($title: String!, $body: String!, $Author: String!, $createdAt: Date) {
    createPost(title: $title, body: $body, Author: $Author, createdAt: $createdAt) {
      id
      title
      body
      Author
    }
  }
`;

export const CREATE_EDUCATION = gql`
  mutation createEducation($title: String!, $body: String!, $Teacher: String!, $questions: [String]!, $answers: [String]!, $Language: String!, $createdAt: Date) {
    createEducation(title: $title, body: $body, Teacher: $Teacher, questions: $questions, answers: $answers, Language: $Language, createdAt: $createdAt) {
      id
      title
      body
      Teacher
      questions
      answers
      Language
    }
  }
`;

export const REGISTER_MEMBER = gql`
  mutation registerMember($input: MemberInput!) {
    registerMember(input: $input) {
      id
      fullName
      BaptismName
      dateOfBirth
      FamilyMembers
      StreetAddress
      City
      State
      ZipCode
      email
      phoneNumber
      ConfessionFather
      Donation
    }
  }
`;

export const CREATE_BAPTISMAL_CERTIFICATION = gql`
  mutation CreateBaptismalCertification($input: BaptismalCertificationInput!) {
    createBaptismalCertification(input: $input) {
      nameOfPersonBaptized
      baptismalName
      fatherFullName
      motherFullName
      godFather
      godMother
      dateOfBirth
      dateOfBaptism
      nameOfBaptistPriest
      placeOfBirth
      SenderEmail
    }
  }
`;

export const CREATE_WAKE_SERVICE_REQUEST = gql`
  mutation createWakeServiceRequest($input: WakeServiceRequestInput!) {
    createWakeServiceRequest(input: $input) {
      fullName
      baptismalName
      dateOfServiceRequested
      dateOfService
      filledBy
      address
      phoneNumber
      date
    }
  }
`;

export const CREATE_WEDDING_CEREMONY_REQUEST = gql`
  mutation createWeddingCeremonyRequest($input: WeddingCeremonyRequestInput!) {
    createWeddingCeremonyRequest(input: $input) {
      brideFullName
      groomFullName
      brideBaptismalName
      groomBaptismalName
      brideAddress
      groomAddress
      dateOfWedding
    }
  }
`;

export const CREATE_BREAKING_NEWS = gql`
  mutation CreateBreakingNews($body: String!) {
    createBreakingNews(body: $body) {
      id
      body
      createdAt
    }
  }
`;
