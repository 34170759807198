import { gql } from '@apollo/client';

export const GET_ME = gql`
  query GetMe {
    me {
      _id
      username
      email
    }
  }
`;

export const GET_USERS = gql`
  query GetUsers {
    users {
      _id
      username
      email
    }
  }
`;

export const GET_POST = gql`
  query GetPost {
    Posts {
      id
      title
      body
    }
  }
`;

export const GET_RECENT_POST = gql`
  query GetRecentPost {
    recentPost {
      id
      title
      body
      Author
      createdAt
    }
  }
`;

export const GET_EDUCATION = gql`
  query GetEducation($language: String!) {
    AllEducation(language: $language) { 
      id
      title
      body
      Teacher
      questions
      answers
      createdAt
    }
  }
`;

export const GET_RECENT_EDUCATION = gql`
  query GetRecentEducation($language: String!) {
    recentEducation {
      id
      title
      body
      Teacher
      questions
      answers
      createdAt
    }
  }
`;

export const GET_EDUCATION_BY_ID = gql`
  query GetEducationById($id: ID!) {
    getEducationById(id: $id) {
      id
      title
      body
      Teacher
      Language
      questions
      answers
      createdAt
    }
  }
`;

export const GET_MEMBERS = gql`
  query GetMembers {
    members {
      id
      fullName
      BaptismName
      dateOfBirth
      FamilyMembers
      StreetAddress
      City
      State
      ZipCode
      email
      phoneNumber
      ConfessionFather
      Donation
    }
  }
`;

export const GET_BREAKING_NEWS = gql`
query GetBreakingNews {
  breakingNews {
    id
    body
    createdAt
  }
}
`;