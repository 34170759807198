import React from 'react';
import { useQuery } from '@apollo/client';
import { useTheme } from './ThemeContext';

import { GET_BREAKING_NEWS } from '../utils/queries';

const BreakingComp = () => {
    const { loading, error, data } = useQuery(GET_BREAKING_NEWS);
    const { theme } = useTheme();

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error fetching Breaking News</div>;
    }

    const recentBreakingNews = data.breakingNews[data.breakingNews.length - 1]; // Get the last item

    return (
        <div className={`bg-${theme === 'light' ? 'slate-300' : 'gray-700'} text-${theme === 'light' ? 'slate-700' : 'blue-400'} p-2 rounded-lg`}>
            <div className="rounded-lg overflow-hidden breaking-news-container">
                <p className="breaking-news text-sm ">
                    {recentBreakingNews.body}
                </p>
            </div>
        </div>
    );
}

export default BreakingComp;

